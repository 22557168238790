export default {
  '2FA_30_days_valid': "Exiger l'A2F une fois tous les 30 jours sur les appareils de confiance.",
  '2FA_every_login': "Exiger l'A2F à chaque connexion.",
  '2FA_is_required_every_30_days': "L'A2F est requis une fois tous les 30 jours sur les appareils de confiance.",
  '2FA_is_required_every_login': "L'A2F est requis à chaque fois qu'un utilisateur se connecte.",
  '30_days_valid': '30 jours de validité',
  'color_light-grey': 'Gris clair',
  'delete_scale_sublabel_interview-guide': "Ce niveau n'apparaîtra plus dans les gabarits d'entrevue.",
  'scale_disabled_interview-guide': "Cette échelle a été désactivée et ne sera plus incluse dans les gabarits d'entrevue.",
  'turn_off_scale_sublabel_interview-guide': "L'échelle n'apparaîtra plus dans aucun gabarit d'entrevue.",
  a_review_for_name: 'Un examen pour {name}',
  a: 'A',
  accept_changes_continue_tracking: 'Accepter les modifications et continuer le suivi',
  accept_changes_description: "Cela permet d'accepter toutes les modifications apportées jusqu'à présent. Vous ne pouvez pas annuler cette action.",
  accept_changes_stop_tracking: 'Accepter les modifications et arrêter le suivi',
  access_removed: 'Accès retiré',
  access_summary: "Résumé d'accès",
  accesses: 'Accès',
  account_defaults_sub: "Ces paramètres par défaut seront appliqués à l'ensemble du compte.",
  account_settings: 'Paramètres du compte',
  account: 'Compte',
  accounts: 'Compte | Comptes',
  acknowledge_success_message: "L'évaluation a été confirmée avec succès.",
  acknowledge: 'Accuser réception',
  acknowledged_results: 'Résultats reconnus',
  acknowledged: 'Réception accusée',
  actions: 'Action | Actions',
  activate_users: 'Activer',
  activate: 'Activer',
  activated: 'Activé',
  active_candidates_only: 'Candidats actifs seulement',
  active_now: 'Actif maintenant',
  active_status: 'Statut actif',
  active_statuses: 'Statuts actifs',
  active: 'Actif',
  actual_review: 'Examen actuel',
  add_a_comment: 'Ajouter un commentaire',
  add_additional_information_group: 'Créer un groupe',
  add_additional_information: "Créer @.lower:{'singular_additional_information'}",
  add_additional_position: 'Ajouter un poste supplémentaire',
  add_all: 'Ajouter tous',
  add_candidate_statuses: 'Ajouter le statut du candidat',
  add_candidates_description: "Sélectionner ou ajouter des candidats à ce tour d'entrevue.",
  add_candidates: 'Ajouter des candidats',
  add_career_stream_competencies: "Ajouter des @.lower:{'plural_competencies'} de la filière professionnelle",
  add_certification_group: 'Créer un groupe',
  add_certifications: "Créer @.lower:{'singular_certifications'}",
  add_comment: 'Ajouter un commentaire',
  add_comments: 'Ajouter des commentaires',
  add_competencies: 'Ajouter des compétences',
  add_core_competencies: "Ajouter les @.lower:{'plural_competencies'} de base",
  add_department: 'Créer un département',
  add_details: 'Ajouter des détails',
  add_direct_reports: 'Ajouter des subordonnés directs',
  add_due_date: "Ajouter la date d'échéance",
  add_edit_details: 'Ajouter / modifier les détails',
  add_education_group: 'Créer un groupe',
  add_educations: "Créer @.lower:{'singular_educations'}",
  add_employees: 'Ajouter des employés',
  add_experiences: "Créer @.lower:{'singular_experiences'}",
  add_feedback: 'Ajouter un retour',
  add_filters: 'Ajouter des filtres',
  add_flags: 'Ajouter un marqueur',
  add_incumbent: 'Ajouter un titulaire',
  add_interview_rounds: "Ajouter un tour d'entrevue",
  add_interviewers: 'Ajouter un intervieweur',
  add_item_to_library: 'Ajouter {number} {library_type}',
  add_job_family_competencies: "Ajouter des @.lower:{'plural_competencies'} de la famille d'emplois",
  add_job_group_owners: "Ajouter des propriétaires de groupe d'emplois",
  add_job_level_name_competencies: "Ajouter des @.lower:{'plural_competencies'} de {name}",
  add_job_title_placeholder: "Saisir un titre d'emploi (par ex. Associé commercial)",
  add_job: 'Ajouter un emploi',
  add_jobs: "Ajouter l'emploi",
  add_languages: "Créer @.lower:{'singular_languages'}",
  add_level_after: 'Ajouter un niveau après',
  add_level_before: 'Ajouter un niveau avant',
  add_level: 'Ajouter un niveau',
  add_levels: 'Ajouter un niveau | Ajouter des niveaux',
  add_library_content_to_survey: 'Ajouter du contenu de bibliothèque à votre sondage.',
  add_links: 'Ajouter un lien',
  add_location_details: 'Ajouter des détails sur le lieu',
  add_location_name: 'Ajouter un nom de lieu',
  add_location: 'Ajouter un lieu',
  add_manager: 'Attribuer un gestionnaire',
  add_members: 'Ajouter un membre',
  add_min_max_item_to_library: 'Ajouter {min} à {max} {library_type}',
  add_multiple_line: 'Ajouter plusieurs éléments sur des lignes séparées.',
  add_multiple_question_line: 'Ajouter plusieurs questions sur des lignes séparées.',
  add_new_candidates: 'Ajouter un nouveau candidat | Ajouter de nouveaux candidats',
  add_notes: 'Ajouter des notes',
  add_options: 'Ajouter des options',
  add_past_positions: 'Ajouter des postes précédents',
  add_people_to_get_input: 'Ajouter des personnes pour obtenir leur contribution.',
  add_position_id: "Ajouter l'ID de poste",
  add_position: 'Ajouter un poste',
  add_ques_to_get_started: 'Ajouter des questions pour commencer',
  add_questions_from_interview_templates: "Ajouter des questions à partir du gabarit d'entrevue",
  add_questions: 'Ajouter des questions',
  add_ranges: 'Ajouter une fourchette',
  add_reply: 'Ajouter une réponse',
  add_responsibility_group: 'Créer un groupe',
  add_responsibility: "Créer @.lower:{'singular_responsibilities'}",
  add_skill_group: 'Créer un groupe',
  add_skill: "Créer @.lower:{'singular_skills'}",
  add_stars: 'Ajouter une étoile',
  add_subheadings: 'Ajouter un sous-titre',
  add_subjects: 'Ajouter des sujets',
  add_text: 'Ajouter du texte',
  add_to_guide: 'Ajouter au gabarit',
  add_to_library: 'Ajouter à la bibliothèque',
  add_to_site: 'Ajouter au site',
  add_types: 'Ajouter un type',
  add_users: 'Ajouter un utilisateur | Ajouter des utilisateurs',
  add_working_conditions: "Créer @.lower:{'singular_working_conditions'}",
  add: 'Ajouter',
  added_guide: 'Ajouté un guide',
  added_interview_guide_question: "Question de gabarit d'entrevue ajoutée",
  added_owners: 'Propriétaires ajoutés',
  added_reactions: 'Réaction ajoutée',
  added_survey_participant: 'Participant au sondage ajouté',
  added_tags: 'Balise ajoutée',
  added_to_guides: 'Ajouté au gabarit',
  added_to_site: 'Ajouté au site',
  added: 'Ajouté',
  additional_department: 'Département supplémentaire',
  additional_feedback_unnecessary: 'Vous ne pouvez plus fournir de retours. Il a été indiqué que des retours supplémentaires ne sont pas nécessaires.',
  additional_info: 'Information supplémentaire',
  additional_information_archive: "Archive : @:{'plural_additional_information'}",
  additional_information_archived: 'Élément archivé | Éléments archivés',
  additional_information_created: 'Élément créé | Éléments créés',
  additional_information_deleted: 'Élément supprimé | Éléments supprimés',
  additional_information_groups: "Groupes : @.capitalize:{'plural_additional_information'}",
  additional_information_moved: 'Élément déplacé | Éléments déplacés',
  additional_information_published: 'Élément publié | Éléments publiés',
  additional_information_restored: 'Élément restauré | Éléments restaurés',
  additional_information_updated: "Mis à jour : @.lower:{'singular_additional_information'} | Mis à jour : @.lower:{'plural_additional_information'}",
  additional_information: "@:{'singular_additional_information'} | @:{'plural_additional_information'}",
  additional_position_text: "Utile pour les employés affectés à plusieurs postes.\n Choisir cette option pour associer l'employé à un poste supplémentaire.",
  additional_position: 'Poste supplémentaire',
  additional_review_requested: 'Révision supplémentaire demandée',
  admin_content_defaults_interview_questions_description: "Inclure les questions d'entrevue lors de l'importation des compétences de l'entrepôt. ",
  admin_home: 'Accueil administrateur',
  admin_option_description: 'Inclut les autorisations pour la gestion et la fonctionnalité administratives complètes de Quinto.',
  admin: 'Admin',
  administration_employee_profiles_description: 'Définir les capacités à afficher sur les pages de profil des employés et la méthode de calcul des correspondances d’emploi.',
  administration: 'Administration',
  administrator: 'Administrateur',
  advance_to_hire: "Avancer à l'embauche",
  advanced_options: 'Options avancées',
  advanced_search: 'Explorateur de contenu',
  ai_description: "Définir les paramètres de l'IA pour les emplois.",
  ai_server_error: "Désolé! Quelque chose s'est mal passé. Veuillez réessayer ultérieurement.",
  ai_suggestions_warning: "Les suggestions de l'IA peuvent être inexactes ou trompeuses. ",
  ai: 'IA',
  all_columns_must_have_headers: 'Toutes les colonnes doivent avoir des en-têtes',
  all_competencies: 'Toutes les compétences',
  all_completed_signoffs: 'Toutes les approbations complétées',
  all_content: 'Tout le contenu',
  all_names_required: 'Veuillez entrer le nom dans toutes les langues disponibles.',
  all_questions: 'Toutes les questions',
  all_responses: 'Toutes les réponses',
  all_scorecards_submitted: "Toutes les fiches d'évaluation soumises",
  all_templates: 'Tous les gabarits',
  all: 'Tout',
  alphabetical: 'Alphabétique',
  analytics: 'Analytique',
  analyzing_your_data: 'Analyse de vos données en cours',
  and: 'Et',
  anonymize_candidate_dialog_msg: 'Vous ne pouvez pas annuler cette action. Nous conserverons toutes les évaluations de ce candidat, mais son nom, son adresse courriel et ses liens personnels ne seront plus affichés.',
  anonymize_candidate: 'Anonymiser le candidat',
  anonymize_msg: "Vous ne pouvez pas annuler cette action. Nous conserverons toutes les contributions de cet utilisateur aux sondages et aux activités appartenant à d'autres utilisateurs, mais son nom ne sera plus affiché nulle part.",
  anonymize_results: 'Anonymiser les résultats',
  anonymize_user: "Anonymiser l'utilisateur",
  anonymize_users_msg: "Lorsqu'un utilisateur est supprimé, ses contributions sur votre site, par exemple les évaluations de sondage, sont conservées. Vous pouvez rendre un utilisateur anonyme pour supprimer définitivement toutes les informations personnellement identifiables de notre interface et de notre base de données.",
  anonymize_users: 'Gérer les utilisateurs supprimés',
  anonymize: 'Anonymiser',
  anonymized_results: 'Résultats anonymisés',
  anonymized: 'Anonymisé',
  anytime: 'À tout moment',
  api_key_copymodal_message: "Attention : Ceci est votre jeton d'API. Assurez-vous de sauvegarder ce jeton de manière sécurisée. ",
  api_keys: 'Clé API | Clés API',
  applications: 'Candidatures',
  apply_updates: 'Appliquer les mises à jour',
  apr: 'Avr',
  architecture_display_order: "Ordre d'affichage de l'architecture",
  architecture_layers: "Couches d'architecture",
  architecture_options: "Options d'architecture",
  architecture_updated: 'Architecture mise à jour',
  architecture: 'Architecture',
  archive_additional_information_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_additional_information: "Archiver @.lower:{'additional_information'}?",
  archive_certifications_confirmation: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_certifications_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_certifications: "Archiver @.lower:{'certifications'}?",
  archive_competencies_confirmation: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_competencies_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_competencies: "Archiver @.lower:{'competencies'}?",
  archive_education_confirmation: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_educations_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_educations: "Archiver @.lower:{'educations'}?",
  archive_experiences_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_experiences: "Archiver @.lower:{'experiences'}?",
  archive_jobs_message: 'Cet emploi sera archivé. | Ces emplois seront archivés.',
  archive_jobs: "Archiver l'emploi | Archiver les emplois",
  archive_languages_msg: 'Cet élément sera archivé et retiré de {count_published_jobs}. | Ces éléments seront archivés et retirés de {count_published_jobs}.',
  archive_languages: "Archiver @.lower:{'languages'}?",
  archive_responsibilities_confirmation: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_responsibilities: "Archiver @.lower:{'responsibilities'}?",
  archive_responsibility_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_responsibility: "Archiver @.lower:{'responsibilities'}?",
  archive_skill_confirmation: 'Cet élément sera archivé et retiré de {count_published_jobs}. | Ces éléments seront archivés et retirés de {count_published_jobs}.',
  archive_skill_msg: 'Cet élément sera archivé et retiré de {count_published_jobs}. | Ces éléments seront archivés et retirés de {count_published_jobs}.',
  archive_skill: "Archiver @.lower:{'skills'}?",
  archive_working_conditions_msg: 'Cet élément sera archivé. | Ces éléments seront archivés.',
  archive_working_conditions: "Archiver @.lower:{'working_conditions'}?",
  archive: 'Archiver',
  archived: 'Archivé',
  are_you_still_there: 'Êtes-vous toujours là?',
  ask_ai: "Demander à l'IA",
  assessment_has_no_questions_tooltip: "L'évaluation de {subjectName} ne comporte aucune question.",
  assessment_not_based_on_job_text: "Une sélection de compétences et d'aptitudes",
  assessment_questionnaire_reopen_msg: "Le questionnaire d'évaluation a été rouvert.",
  assessment_result: "Résultats de l'évaluation",
  assessments_completed: 'Évaluations terminées',
  assessments_dispatched: 'Évaluation envoyée',
  assessments_manage_alerts_text: 'Les alertes sont ici...',
  assessments: 'Évaluations',
  assign_department: 'Attribuer un département',
  assign_levels: 'Attribuer un niveau',
  assign_manager: 'Attribuer un gestionnaire',
  assign_permission_sets: 'Attribuer des ensembles de permissions',
  assign_position_children_new_parent: 'Que souhaitez-vous faire avec les subordonnés directs?',
  assign_property: 'Attribuer {property}',
  assign_this_question_to: 'Assigner cette question à :',
  assign_types: 'Attribuer des types',
  assign: 'Attribuer',
  assigned_licenses: 'Licence attribuée | Licences attribuées',
  assigned_manager: 'Gestionnaire attribué',
  assigned_permission_sets: 'Ensemble de permissions attribué | Ensembles de permissions attribués',
  assigned: 'Assigné',
  associated_job_post_template_description: "Le gabarit associé utilisé pour définir l'ordre et le contenu des annonces d'emploi. ",
  associated_job_post_templates: "Gabarit d'annonce d'emploi associé ",
  associated_post_template_tooltip: "Le gabarit associé utilisé pour définir l'ordre et le contenu des annonces d'emploi. ",
  associated_post_template: "Gabarit d'annonce d'emploi associé ",
  at_least_8_characters_long: 'Au moins 8 caractères',
  at_or_above: 'à ou au-dessus',
  ats_status_code: "Code d'état ATS",
  attributed_results: 'Résultats attribués',
  audit_logs_additional_information_groups: "Groupes de {'singular_additional_information'}",
  audit_logs_certification_groups: "Groupes de {'singular_certifications'}",
  audit_logs_competency_groups: "Groupes de {'singular_competencies'}",
  audit_logs_education_groups: "Groupes de {'singular_educations'}",
  audit_logs_general: "Journaux d'audit - Général",
  audit_logs_interviews: "Journaux d'audit - Entrevues",
  audit_logs_jobs: "Journaux d'audit - Emplois",
  audit_logs_responsibility_groups: "Groupes de {'singular_responsibilities'}",
  audit_logs_reviews: 'Journaux d’audit – Examens',
  audit_logs_signoffs: "Journaux d'audit - Approbations",
  audit_logs_skill_groups: "Groupes de {'singular_skills'}",
  audit_logs: "Journal d'audit | Journaux d'audit",
  aug: 'Août',
  authorize: 'Autoriser',
  auto_reminders: 'Rappels automatiques',
  automated_reminders: 'Rappels automatiques',
  available_languages: 'Langues disponibles',
  average_job_age_tooltip: 'Le nombre moyen de jours depuis la dernière publication de chaque emploi.',
  average_job_age: 'Âge moyen des emplois',
  average_job_consistency_by_month_tooltip: 'La moyenne mensuelle du score de cohérence (0 à 100) pour les emplois publiés.',
  average_job_consistency_by_month: 'Cohérence moyenne des emplois',
  average_job_consistency_tooltip: 'Le score de cohérence moyen actuel (0 à 100) pour les emplois publiés.',
  average_job_consistency: 'Cohérence moyenne des emplois',
  average_of_scores: 'Moyenne des notes soumises',
  average: 'Moyenne',
  avg_score: 'Note moyenne',
  b: 'B',
  back_to_login: 'Retour au login',
  back: 'Retour',
  behavioral_indicators: 'Indicateur comportemental',
  below_requirements: 'Exigences ci-dessous',
  below: 'ci-dessous',
  between_xy: 'entre {x} et {y}',
  bulk_cancel_assessments_dialog_message: "Vous ne pouvez pas annuler cette action. L'annulation des évaluations supprimera toutes les données soumises. Les participants concernés en seront informés.",
  bulk_sharing_results_dialog_message: 'Partager les résultats avec les sujets des évaluations sélectionnées.',
  c: 'C',
  cancel_assessment_dialog_message: "Vous ne pouvez pas annuler cette action. L'annulation de cette évaluation supprimera toutes les données soumises. Les participants concernés seront informés.",
  cancel_assessment: "Annuler l'évaluation",
  cancel_assessments: "Annuler l'évaluation | Annuler les évaluations",
  cancel_interviews_confirmation: 'Nous informerons les intervieweurs que cette entrevue a été annulée.',
  cancel_interviews: "Annuler l'entrevue",
  cancel_requests: 'Annuler la demande',
  cancel_signoff_message: "Une annulation d'approbation sera envoyée à {name}. | Une annulation d'approbation sera envoyée aux employés sélectionnés.",
  cancel_signoff_title: "Annuler l'approbation?",
  cancel: 'Annuler',
  cancelled: 'Annulé',
  candidacies: 'Candidature',
  candidacy_comments: 'Commentaire sur la candidature',
  candidate_email_taken: "L'adresse courriel que vous avez saisie est déjà utilisée.",
  candidate_info: 'Informations sur le candidat',
  candidate_moved: 'Candidat déplacé!',
  candidate_statuses_description: 'Définir les statuts des candidats. Remarque : changer les noms de statuts mettra à jour tous les candidats dans toutes les ouvertures.',
  candidate_statuses: 'Statuts des candidats',
  candidates: 'Candidat | Candidats',
  capabilities: 'Capacité | Capacités',
  capability_comparison: 'Comparaison des capacités',
  capability_match: 'Correspondance des capacités',
  capability_no_data_msg: 'Nous n’avons pas encore assez d’informations pour afficher vos capacités.',
  capability: 'Capacité',
  career_stream_competencies_description: "Ces éléments sont ajoutés à tous les emplois dans chaque groupe d'emplois. Pour ajouter des @.lower:{'plural_competencies'} de filière professionnelle, allez dans {jobGroups}.",
  career_stream_competencies: "@.capitalize:{'plural_competencies'} de la filière professionnelle",
  career_stream: 'Filière professionnelle',
  category: 'Catégorie',
  certification_groups: "Groupes : @.capitalize:{'plural_certifications'}",
  certification_levels: 'Niveau de certification',
  certifications_archive: "Archive : @:{'plural_certifications'}",
  certifications_archived: 'Élément archivé | Éléments archivés',
  certifications_created: 'Élément créé | Éléments créés',
  certifications_deleted: 'Élément supprimé | Éléments supprimés',
  certifications_edited: 'Élément modifié | Éléments modifiés',
  certifications_groups: "Groupes : @.capitalize:{'plural_certifications'}",
  certifications_guideline_description: "Les certifications sont des attestations désignées délivrées par des organismes tiers chargés de définir des normes, qui vérifient qu'un individu a satisfait à certains critères liés à une compétence, à une aptitude ou à un emploi.<p class=\"mt-2\"> Il ne devrait pas y avoir plus de 5 certifications en tout.</p>",
  certifications_moved: 'Élément déplacé | Éléments déplacés',
  certifications_published: 'Élément publié | Éléments publiés',
  certifications_restored: 'Élément restauré | Éléments restaurés',
  certifications_updated: "Mis à jour : @.lower:{'singular_certifications'} | Mis à jour : @.lower:{'plural_certifications'}",
  certifications: "@:{'singular_certifications'} | @:{'plural_certifications'}",
  change_color: 'Changer de couleur',
  change_department: 'Changer le département',
  change_filters: 'Essayer de changer vos filtres',
  change_incumbent: 'Changer le titulaire',
  change_job: "Changer l'emploi",
  change_log: 'Journal des modifications',
  change_manager: 'Changer le gestionnaire',
  change_position_text: "Utile pour les changements de poste qui affectent les relations hiérarchiques.\n Choisir cette option pour déplacer l'employé vers un poste déjà existant.",
  change_position: 'Changer le poste',
  change_role: 'Changer le rôle',
  change_security_level: 'Paramètres',
  change_status: 'Changer le statut',
  change_x_job_in_review: "Modifier l'emploi de {name} dans cet examen.",
  change_x_manager_in_review: 'Modifier le gestionnaire de {name} dans cet examen.',
  change: 'Changer',
  changed_role: 'Rôle modifié',
  changes_are_not_retroactive: "Les changements ne sont pas rétroactifs - ils n'affectent que les emplois publiés à l'avenir. Ce sont des paramètres par défaut que vous pouvez modifier pour chaque emploi individuel.",
  changes_not_tracked: 'Les modifications ne sont pas suivies.',
  chart: 'Graphique',
  check_box_update_jobs: 'Cocher cette case permettra de signaler les emplois mis à jour dans les activités de contenu afin que vous puissiez prévenir les titulaires lorsque vous serez prêt.',
  check_for_updates: 'Vérifier les mises à jour',
  check_setup_modal_text: 'Nous préparons tout pour vous. Veuillez patienter...',
  check_setup_modal_title: 'Un instant...',
  check_setup: 'Vérifier la configuration',
  check_the_setup: 'vérifier la configuration',
  check_updates_job_changes_were_found_alert_text_part_1: "Nous avons modifié l'emploi de {name}",
  check_updates_job_changes_were_found_alert_text_part_2: 'dans cet examen pour {newJob}.',
  check_updates_job_changes_were_found_live_review_alert_text_1: 'Toutes les données soumises seront définitivement supprimées.',
  check_updates_job_changes_were_found_live_review_alert_text_2: 'Tous les participants qui évaluent {subjectName} recevront une nouvelle invitation et une nouvelle tâche.',
  check_updates_job_changes_were_found_live_review_alert_text: "Si vous modifiez l'emploi de {name} dans cet examen pour {newJob}",
  check_updates_manager_changes_were_found_alert_text_part_1: 'Nous avons remplacé le gestionnaire de {name}',
  check_updates_manager_changes_were_found_alert_text_part_2: 'dans cet examen pour {newManager}.',
  check_updates_manager_changes_were_found_live_review_alert_text_1: "Toutes les données soumises par un gestionnaire qui sont retirées de l'examen seront définitivement supprimées.",
  check_updates_manager_changes_were_found_live_review_alert_text_2: 'Un nouveau gestionnaire recevra une invitation et une tâche.',
  check_updates_manager_were_found_live_review_alert_text: 'Si vous changez le gestionnaire de {name} dans cet examen en {newManager}',
  check_updates_x_job_not_update: "l'emploi de {name} n'a pas été mis à jour.",
  check_updates_x_job_updated_to_new_job: "L'emploi de {name} a été mis à jour pour {newJob}.",
  check_updates_x_manager_not_update: "Le gestionnaire de {name} n'a pas été mis à jour.",
  check_updates_x_manager_updated_to_new_manager: 'Le gestionnaire de {name} a été mis à jour pour {newManager}.',
  check_your_email: 'Vérifiez votre courriel',
  checked_setup: 'Configuration vérifiée',
  choose_content_for_job_post_template: "Choisir le contenu à afficher dans l'annonce d'emploi",
  choose_security_level: 'Choisir le niveau de sécurité',
  clear: 'Effacer',
  click_edit_click_hold_move: 'Cliquer pour modifier. Cliquer et maintenir enfoncé pour déplacer.',
  click_here_to_add_one: 'Cliquer ici pour en ajouter un.',
  click_here: 'Cliquer ici',
  click_to_add: 'Cliquer pour ajouter',
  click_to_hide_asterisk_guide_unused_ques: 'Un astérisque est affiché à côté des questions inutilisées pour cet emploi. Cliquer pour les masquer.',
  click_to_upload: 'Cliquer pour téléverser',
  close_feedback_message: 'Les éditeurs et les relecteurs auront un accès en lecture seule.',
  close_feedback: 'Fermer les retours',
  close_job_input_description: 'Limiter les éditeurs et les relecteurs à un accès en lecture seule pour cet emploi.',
  close_job_input_title: 'Fermer aux éditeurs et aux relecteurs',
  close_opening_confirmation: "Vous pouvez la rouvrir plus tard si vous changez d'avis.",
  close_opening: 'Fermer cette ouverture',
  close_reviews: "Fermer l'examen",
  close_survey_confirmation_message: "Les participants ne pourront plus accéder au sondage. Nous enverrons un courriel à ceux qui n'ont pas encore fourni leurs contributions pour les informer.",
  close_survey: 'Fermer le sondage',
  close: 'Fermer',
  closed_survey_message: 'Ce sondage a été fermé. {link}',
  closed: 'Fermé',
  collaboration_description: 'Inviter des personnes à fournir leurs contributions sur cet emploi.',
  collaboration: 'Collaborateurs',
  collaborators: 'Collaborateur | Collaborateurs',
  color_blue: 'Bleu',
  color_brown: 'Brun',
  color_cyan: 'Cyan',
  color_green: 'Vert',
  color_grey: 'Gris',
  color_magenta: 'Magenta',
  color_orange: 'Orange',
  color_pink: 'Rose',
  color_purple: 'Violet',
  color_red: 'Rouge',
  color_yellow: 'Jaune',
  colors: 'Couleur | Couleurs',
  commented: 'Commenté',
  comments_are_x: 'Les commentaires sont {x}',
  comments: 'Commentaire | Commentaires',
  communicated_deadlines: 'Date limite communiquée',
  communication_conclusion_description: "Cette section apparaît à la fin du gabarit (après les questions d'entrevue).",
  communication_introduction_description: "Cette section apparaît au début du gabarit (avant les questions d'entrevue).",
  communications_desc: 'Personnaliser le contenu des courriels et des introductions.',
  communications_description: 'Ce sondage comprend des communications par défaut.',
  communications_type_job_collaboration_invitation: "Définir l'objet et le contenu du courriel d'invitation à la collaboration sur les emplois.",
  communications_type_job_collaboration_thankyou: "Définir l'objet et le contenu du courriel de remerciement pour la collaboration sur les emplois.",
  communications_type_new_user_invitation: "Définir l'objet et le contenu du courriel d'invitation pour les nouveaux utilisateurs.",
  communications_type_review_invitation_manager: "Définissez le contenu par défaut des invitations par courriel envoyées aux gestionnaires d'examen. Vous pouvez personnaliser les messages pour chaque examen.",
  communications_type_review_invitation_subjects: "Définissez le contenu par défaut des invitations par courriel envoyées aux sujets d'examen. Vous pouvez personnaliser les messages pour chaque examen.",
  communications_type_review_onscreen_instruction_manager: "Définissez le contenu par défaut de l'introduction que les gestionnaires verront dans un examen. Vous pouvez personnaliser les messages pour chaque examen.",
  communications_type_review_onscreen_instruction_subjects: "Définissez le contenu par défaut de l'introduction que les sujets verront dans un examen. Vous pouvez personnaliser les messages pour chaque examen.",
  communications_type_review_sharing_acknowledgement_subjects: "Définissez le contenu par défaut des courriels envoyés aux employés pour confirmer qu'ils ont eu une discussion d'examen avec leur gestionnaire. Vous pouvez personnaliser les messages pour chaque examen.",
  communications_type_review_sharing_invitation_manager: "Définissez le contenu par défaut des courriels envoyés aux gestionnaires pour planifier les discussions d'examen. Vous pouvez personnaliser les messages pour chaque examen.",
  communications_type_survey_introduction: 'Définir le contenu par défaut des pages de bienvenue des sondages. Vous pouvez ajouter des messages spécifiques à chaque sondage.',
  communications_type_survey_invitation: 'Définir le contenu par défaut des invitations aux sondages. Vous pouvez ajouter des messages spécifiques à chaque sondage.',
  communications_updated: 'Communications mises à jour',
  communications: 'Communications',
  companies: 'Entreprise',
  company_name: "Nom de l'entreprise",
  compare_job_versions: "Comparer les versions de l'emploi",
  compare_jobs: 'Comparer les emplois',
  compare_opening_name: 'Comparer : {opening_name}',
  compare: 'Comparer',
  competencies_archive: "Archive: @:{'plural_competencies'}",
  competencies_archived: 'Élément archivé | Éléments archivés',
  competencies_deleted: 'Élément supprimé | Éléments supprimés',
  competencies_duplicated: 'Élément dupliqué | Éléments dupliqués',
  competencies_groups: "Groupes: @.capitalize:{'plural_competencies'}",
  competencies_guideline_description: "Les compétences sont les connaissances, les aptitudes ou les capacités définies en termes de comportements observables nécessaires pour accomplir une tâche ou un emploi.<p class=\"mt-2\"> Il devrait y avoir 8 compétences spécifiques à l'emploi en tout.</p>",
  competencies_moved: 'Élément déplacé | Éléments déplacés',
  competencies_published: 'Élément publié | Éléments publiés',
  competencies_restored: 'Élément restauré | Éléments restaurés',
  competencies_updated: "Mis à jour : @.lower:{'singular_competencies'} | Mis à jour : @.lower:{'plural_competencies'}",
  competencies: "@:{'singular_competencies'} | @:{'plural_competencies'}",
  competency_already_exists: 'Un élément avec ce nom existe déjà.',
  competency_based_questions_note_label: 'Notes : Situation, Action, Résultats.',
  competency_based_questions: 'Questions basées sur les compétences',
  competency_details: "Détails de la @.lower:{'singular_competencies'} ",
  competency_gaps: 'Lacunes en compétences',
  competency_groups: "Groupes : @.capitalize:{'plural_competencies'}",
  competency_levels: 'Niveaux de compétence',
  competency_matches: 'Correspondances de compétences',
  competency_name_and_levels: "Noms et niveaux de @.lower:{'singular_competencies'} uniquement",
  competency_not_translated: "Certaines parties de cette compétence n'ont pas été traduites. ",
  competency_published_in_jobs: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  competency_question_updated: "Question de @.lower:{'singular_competencies'} mise à jour",
  competency_questions_deleted: "Question de @.lower:{'singular_competencies'} supprimée | Questions de @.lower:{'singular_competencies'} supprimées",
  competency_questions_dulicated: "Question de @.lower:{'singular_competencies'} dupliquée | Questions de @.lower:{'singular_competencies'} dupliquées",
  competency_questions: "Question de @.lower:{'singular_competencies'} | Questions de @.lower:{'singular_competencies'}",
  complete_setup_modal_text_1: 'Une fois que vous avez terminé la configuration, cet examen sera programmé et certaines parties de la configuration ne seront plus modifiables. {learn_more_link}',
  complete_setup_modal_text_2: "Vous pouvez {check_the_setup} d'abord pour prévisualiser les évaluations et vérifier s'il y a des erreurs de configuration.",
  complete_setup: 'Configuration terminée',
  complete: 'Complète',
  completed_signoffs_by_date: 'Approbations complétées par date',
  completed_survey_message: 'Vous avez déjà complété ce sondage. {link}',
  completed: 'Complété',
  completion_notifications: "Notifications d'achèvement",
  completion_rate: "Taux d'achèvement",
  conclusion: 'Conclusion',
  condensed_list_of_questions: 'Liste condensée des questions (sans espace pour les notes)',
  confirm_delete_permission_description: 'Vous ne pouvez pas annuler cette action. Cet ensemble de permissions sera définitivement supprimé.',
  confirm_permission_set_delete: "Supprimer l'ensemble de permissions?",
  confirm_tag_deletion: 'Êtes-vous sûr de vouloir supprimer cette balise?',
  confirm: 'Confirmer',
  consistency_description: 'Définir la plage idéale de {library} pour chaque emploi.',
  consistency_related_guidelines_message: "Pour contribuer à créer des descriptions d'emplois cohérentes, veuillez {message} {fromOrTo} cet emploi.",
  consistency_saving_toast_message: "Les mises à jour peuvent prendre jusqu'à une heure pour être publiées",
  consistency_scores_tooltip: "Obtenir des descriptions d'emploi plus cohérentes en définissant des plages de contenu idéales.",
  consistency_scores: 'Score de cohérence | Scores de cohérence',
  consistency_setting_dialog_description: 'Les scores de cohérence seront désactivés pour les emplois utilisant ce gabarit.',
  consistency_setting_dialog_title: 'Désactiver la cohérence?',
  consistency_settings_dialog_description: "Définir la plage idéale de @.lower:{'plural_competencies'} propres à l'annonce pour chaque emploi. Nous encouragerons les collaborateurs à respecter cette plage pour garantir la cohérence.",
  consistency_settings: 'Paramètres de cohérence',
  consistency_turn_off_message: 'La cohérence sera désactivée pour {library_type}',
  consistency: 'Cohérence',
  contact_us: 'Contactez-nous',
  content_action_instruction: "Taper '/' pour voir les options",
  content_activities: 'Activités de contenu',
  content_defaults_jobs: 'Paramètres par défaut de contenu - Emplois',
  content_defaults_libraries: 'Paramètres par défaut de contenu - Bibliothèques',
  content_defaults_updated: 'Paramètres par défaut du contenu mis à jour',
  content_defaults: 'Paramètres par défaut du contenu',
  content_finder_competency_ai_suggestions_description: "Les suggestions sont basées sur le @.lower:{'summary'} de l'emploi et les @.lower:{'plural_responsibilities'}.",
  content_finder: 'Recherche de contenu',
  content_language: 'Langue du contenu',
  content_type: 'Type de contenu',
  content_types: 'Types de contenu',
  content_update: 'Mise à jour du contenu',
  content_usage: 'Utilisation du contenu',
  content_warehouse: 'Entrepôt de contenu',
  content: 'Contenu',
  contents: 'Contenu',
  continue_editing: 'Continuer à modifier',
  contributor_added_to_job: "Contributeur ajouté à l'emploi",
  convert_to_plain_text: 'Convertir en texte brut',
  copied: 'Copié',
  copy_failed: 'Échec de la copie',
  copy_post: "Copier l'annonce",
  copy_scorecard_links: "Copier le lien de la fiche d'évaluation",
  copy_to_clipboard: 'Copier dans le presse-papiers',
  copy_url: 'Copier le lien vers la page',
  copy: 'Copier',
  core_competencies_description: "Les @.lower:{'plural_competencies'} de base sont ajoutées à tous les emplois dans votre organisation.",
  core_competencies_homepage_description: "Communiquer vos @.lower:{'plural_competencies'} de base.",
  core_competencies_will_appear_here: "Les @.lower:{'plural_competencies'} de base apparaîtront ici.",
  core_competencies: "@.capitalize:{'competencies'} de base",
  core_competency_widget: "Widget de @.lower:{'singular_competencies'} de base",
  core: 'De base',
  count_added: '{count} ajouté.',
  count_additional_participants: '{count} participants supplémentaires',
  count_days: '{count} jour | {count} jours',
  count_draft_jobs: '{count} emploi en brouillon | {count} emplois en brouillon',
  count_jobs: '{count} emploi | {count} emplois',
  count_minutes: '{count} minutes',
  count_openings: '{count} ouvertures',
  count_participants: '{count} participant | {count} participants',
  count_published_jobs: '{count} emploi publié | {count} emplois publiés',
  count_questions: '{count} question | {count} questions',
  count_responses: '{count} response | {count} responses',
  count_subject_added: '<span class="text-link cursor-pointer">{count} sujets</span> ajoutés.',
  count_subjects: '{count} sujets',
  count_to_do: '{count} à faire',
  create_additional_information_group: 'Créer un groupe',
  create_certifications_group: 'Créer un groupe',
  create_certifications: "Créer @.lower:{'singular_certifications'}",
  create_communications_materials: 'Créer des supports de communication',
  create_competencies_group: 'Créer un groupe',
  create_competencies: "Créer @.lower:{'singular_competencies'}",
  create_competency_questions: "Créer une question de @.lower:{'singular_competencies'}",
  create_department: 'Créer un département',
  create_education: "Créer @.lower:{'singular_educations'}",
  create_educations_group: 'Créer un groupe',
  create_group: 'Créer un groupe',
  create_guides: 'Créer un gabarit | Créer des gabarits',
  create_interview_templates: "Créer un guide d'entrevue",
  create_job_property: "Créer une propriété d'emploi",
  create_job_template: "Créer un gabarit d'emploi",
  create_job: 'Create job',
  create_library_group: 'Créer un groupe',
  create_new_templates: 'Créer un nouveau gabarit',
  create_openings_limit_link: 'Veuillez contacter {link} pour augmenter votre limite.',
  create_openings_limit: 'Vous avez atteint la limite de votre licence pour les ouvertures.',
  create_openings: 'Créer une ouverture',
  create_password: 'Créer un mot de passe',
  create_permission_set: 'Créer un ensemble de permissions',
  create_position: 'Create position',
  create_responsibilities: "Créer @.lower:{'singular_responsibilities'}",
  create_responsibility_group: 'Créer un groupe',
  create_reviews: 'Créer un examen',
  create_skill_group: 'Créer un groupe',
  create_skill: "Créer @.lower:{'singular_skills'}",
  create_survey: 'Créer un sondage',
  create_templates: 'Créer un gabarit',
  create_topic_question: 'Créer une question de sujet',
  create_topic: 'Créer un sujet',
  create: 'Créer',
  created_by_name: 'Créé par {name}',
  created_by_system: 'Créé par le système',
  created_by: 'Créé par',
  created_date: 'Créé date: {date}',
  created_draft_copy: 'Brouillon créé',
  created_on: 'Créé le : {date}',
  created_with_date: 'Créé : {date}',
  created: 'Créé',
  creates_new_draft_versions_of_published_jobs_for_review: 'Crée de nouvelles versions de brouillon des emplois publiés pour révision.',
  creates_new_published_versions_of_published_jobs: 'Crée de nouvelles versions publiées des emplois déjà publiés.',
  current_description: "L'employé a donné son approbation pour la dernière version de son emploi.",
  current_draft_version: 'Version actuelle du brouillon',
  current_due_date: "Date d'échéance actuelle",
  current_job_match: 'Correspondance d’emploi actuelle',
  current_jobs: 'Emploi actuel',
  current_of_total: '{current} de {total}',
  current_published_version: 'Version actuelle publiée',
  current_round: 'Tour actuel',
  current: 'Actuel',
  custom: 'Personnalisé',
  d: 'D',
  daily: 'Quotidien',
  dashboard: 'Tableau de bord',
  date_and_time: 'Date et heure',
  date_format_international_dash: 'JJ-MM-AAAA - International',
  date_format_international_slash: 'JJ/MM/AAAA - International',
  date_format_iso_dash: 'AAAA-MM-JJ - Norme ISO',
  date_format_iso_slash: 'AAAA/MM/JJ - Norme ISO',
  date_format_us_standard_dash: 'MM-JJ-AAAA - Format américain',
  date_format_us_standard_slash: 'MM/JJ/AAAA - Format américain',
  date: 'Date',
  deactivate_users_selection: "Cet utilisateur n'aura plus accès au logiciel. Nous conserverons ses contributions. | Ces utilisateurs n'auront plus accès au logiciel. Nous conserverons leurs contributions.",
  deactivate_users: 'Désactiver',
  deactivate: 'Désactiver',
  deactivated_users: 'Utilisateur désactivé',
  deactivated: 'Désactivé',
  dec: 'Déc',
  decent: 'Décent',
  decide: 'Décider',
  default_collaborator_invite_date: "Veuillez fournir votre contribution d'ici le : {date}.",
  default_collaborator_invite: "{name} aimerait vos contributions sur la description d'emploi pour {job}",
  default_due_date: "Date d'échéance par défaut",
  default_interview_rounds: "Tour d'entrevue par défaut",
  default_language: 'Langue par défaut',
  default_location: 'Lieu par défaut',
  default_permission_cannot_be_removed: "Cet ensemble de permissions ne peut pas être supprimé. Il est obligatoire d'avoir au moins un ensemble de permissions par défaut.",
  default_permission_sets: 'Ensembles de permissions par défaut',
  default_template_can_not_delete: 'Ce gabarit ne peut pas être supprimé car il est le paramètre par défaut.',
  default: 'Par défaut',
  define_archicture_order_description: "Afficher les @.lower:{'plural_competencies'} dans un ordre spécifique, selon le type d'architecture. Veuillez noter que cela s'appliquera uniquement aux emplois publiés.",
  define_archicture_order: "Définir l'ordre de l'architecture",
  define_full_description: "Accorde un accès en tant qu'utilisateur complet à Quinto.",
  define_full_option_description: "Fournit un accès complet à la gestion et aux fonctionnalités administratives de Quinto. Les utilisateurs ajoutés à cet ensemble de permissions bénéficient d'une licence d'utilisateur complète pour Quinto.",
  define_full: 'Complet',
  define_import_settings: 'Définir vos paramètres.',
  define_light_description: 'Fournit un accès limité aux fonctionnalités de Quinto.',
  define_light_option_description: 'Fournit un accès limité aux fonctionnalités de Quinto.',
  define_light: 'Léger',
  definitions: 'Définition',
  delete_additional_information_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_additional_information_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_additional_information: "Supprimer @.lower:{'additional_informations'}?",
  delete_api_key_confirmation: 'Êtes-vous sûr de vouloir supprimer cette clé?',
  delete_api_key: 'Supprimer la clé API?',
  delete_assessment_modal_description: 'Vous ne pouvez pas annuler cette action. Cette évaluation sera définitivement supprimée.',
  delete_assessment: 'Supprimer l’évaluation',
  delete_behavior_msg: "Supprimer le comportement s'il n'est plus nécessaire.",
  delete_behavioral_indicator_msg: 'Ce comportement sera supprimé.',
  delete_behavioral_indicator: "Supprimer l'indicateur comportemental?",
  delete_candidate_dialog_msg: 'Êtes-vous sûr? Vous ne pouvez pas annuler cette action. Nous supprimerons ce candidat et toutes les données associées de toutes les ouvertures auxquelles il participe.',
  delete_candidate: 'Supprimer le candidat?',
  delete_certifications_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_certifications_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_certifications: "Supprimer @.lower:{'certifications'}?",
  delete_comment_confirmation: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
  delete_comments_confirmation_message: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
  delete_comments: 'Supprimer le commentaire?',
  delete_communications_message: 'Vous ne pouvez pas annuler cette action. Ces documents de communication seront définitivement supprimés et retirés de tous les gabarits auxquels ils ont été ajoutés.',
  delete_communications: 'Supprimer la communication? | Supprimer les communications?',
  delete_competencies_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_competencies_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_competencies: "Supprimer @.lower:{'competencies'}?",
  delete_competency_questions_msg: 'Vous ne pouvez pas annuler cette action. Cette question sera définitivement supprimée. | Vous ne pouvez pas annuler cette action. Ces questions seront définitivement supprimées.',
  delete_competency_questions: "Supprimer la question de @.lower:{'singular_competencies'}? | Supprimer les questions de @.lower:{'singular_competencies'}?",
  delete_department_confirmation: 'Vous ne pouvez pas annuler cette action. Ce département sera définitivement supprimé. (Les postes ne seront pas affectés.)',
  delete_department: 'Supprimer le département?',
  delete_draft_copy_job_modal_mag: 'Cette action ne peut pas être annulée. Ce brouillon sera définitivement supprimé de votre site.',
  delete_draft_copy_job_modal_title: 'Supprimer le brouillon?',
  delete_draft_copy_msg: 'Vous ne pouvez pas annuler cette action. Ce brouillon sera définitivement supprimé.',
  delete_draft_copy: 'Supprimer le brouillon',
  delete_draft: 'Supprimer le brouillon',
  delete_educations_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_educations_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_educations: "Supprimer @.lower:{'educations'}?",
  delete_experiences_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_experiences_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_experiences: "Supprimer @.lower:{'experiences'}?",
  delete_group_confirmation: 'Vous ne pouvez pas annuler cette action. Ce groupe sera définitivement supprimé.',
  delete_group: 'Supprimer le groupe | Supprimer les groupes',
  delete_job_family_competencies: "Supprimer les @.lower:{'plural_competencies'} de la famille d'emplois?",
  delete_job: "Supprimer l'emploi",
  delete_jobs_message: 'Vous ne pouvez pas annuler cette action. Cet emploi sera définitivement supprimé de votre site. | Vous ne pouvez pas annuler cette action. Ces emplois seront définitivement supprimés de votre site.',
  delete_jobs_msg: 'Vous ne pouvez pas annuler cette action. Cet emploi sera définitivement supprimé de votre site.',
  delete_jobs: "Supprimer l'emploi? | Supprimer les emplois?",
  delete_languages_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_languages_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_languages: "Supprimer @.lower:{'languages'}?",
  delete_level_and_data: 'Supprimer le niveau et les données',
  delete_level_data: 'Supprimer les données du niveau',
  delete_level_from: 'Supprimer ce niveau de :',
  delete_level_message: 'Ce niveau sera supprimé.',
  delete_level_title: 'Supprimer le niveau?',
  delete_level_update_property_msg: 'Ce niveau ne sera plus disponible. Le contenu et les données existants ne seront pas affectés.',
  delete_level_update_property: 'Supprimer ce niveau et mettre à jour la propriété',
  delete_level: 'Supprimer le niveau',
  delete_library_group: 'Supprimer le groupe de bibliothèque?',
  delete_library_item_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_link_message: 'Vous ne pouvez pas annuler cette action. Ce lien utile sera définitivement supprimé.',
  delete_links: 'Supprimer le lien',
  delete_location_msg: 'Vous ne pouvez pas annuler cette action. Ce lieu sera définitivement supprimé des lieux, des utilisateurs et retiré de {count_published_jobs}.',
  delete_location: 'Supprimer le lieu?',
  delete_name: 'Supprimer {name}?',
  delete_openings_confirmation_message: 'Êtes-vous sûr? Vous ne pouvez pas annuler cette action. Cette ouverture sera définitivement supprimée de votre site. | Êtes-vous sûr? Vous ne pouvez pas annuler cette action. Ces ouvertures seront définitivement supprimées de votre site.',
  delete_openings_confirmation_title: "Supprimer l'ouverture? | Supprimer les ouvertures?",
  delete_option_and_data: "Supprimer l'option et les données",
  delete_option_data_msg: "Les données seront supprimées des descriptions d'emploi concernées. Si installées, les activités de développement peuvent également être affectées. En savoir plus.",
  delete_option_data: "Supprimer les données de l'option",
  delete_option_update_property_msg: 'Cette option ne sera plus disponible. Le contenu et les données existants ne seront pas affectés.',
  delete_option_update_property: 'Supprimer cette option et mettre à jour la propriété',
  delete_option: "Supprimer l'option",
  delete_position_msg: 'Vous ne pouvez pas annuler cette action. Ce poste sera définitivement supprimé.',
  delete_positions_selection: 'Vous ne pouvez pas annuler cette action. Ce poste sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces postes seront définitivement supprimés.',
  delete_positions: 'Supprimer le poste? | Supprimer les postes?',
  delete_properties_confirmation_message: "Vous ne pouvez pas annuler cette action. Cette propriété sera définitivement supprimée. Il n'y a pas d'emplois publiés qui incluent cette propriété.",
  delete_properties_with_jobs_confirmation_message: 'Vous ne pouvez pas annuler cette action. Cette propriété sera définitivement supprimée et retirée de {count_published_jobs}.',
  delete_property_data_msg: "Les données seront supprimées des descriptions d'emploi concernées. Si installées, les activités de développement peuvent également être affectées. En savoir plus.",
  delete_property_data: 'Supprimer les données de propriété',
  delete_property_levels_confirmation_message: "Vous ne pouvez pas annuler cette action. Ce niveau sera retiré de la propriété. Il n'y a pas d'emplois qui incluent ce niveau.",
  delete_property_levels_with_jobs_confirmation_message: 'Vous ne pouvez pas annuler cette action. Ce niveau sera retiré de la propriété. Les données de niveau et les compétences de direction seront définitivement supprimées de {count_published_jobs}.',
  delete_property_options_confirmation_message: "Vous ne pouvez pas annuler cette action. Cette option sera retirée de la propriété. Il n'y a pas d'emplois publiés qui incluent cette propriété.",
  delete_property_options_with_jobs_confirmation_message: "Vous ne pouvez pas annuler cette action. Cette option sera retirée de la propriété. Les données d'option seront définitivement supprimées de {count_published_jobs}.",
  delete_range_and_data: 'Supprimer la fourchette et les données',
  delete_range_data: 'Supprimer les données de la fourchette',
  delete_range_update_property_msg: 'Cette fourchette ne sera plus disponible. Le contenu et les données existants ne seront pas affectés.',
  delete_range_update_property: 'Supprimer cette fourchette et mettre à jour la propriété',
  delete_range: 'Supprimer la fourchette',
  delete_responsibility_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_responsibility_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_responsibility: "Supprimer @.lower:{'responsibilities'}?",
  delete_review_confirmation_message: 'Vous ne pouvez pas annuler cette action. Cet examen sera définitivement supprimé.',
  delete_reviews: 'Supprimer l’examen',
  delete_rounds_confirmation_message: "Vous ne pouvez pas annuler cette action. Les tours existants ne sont pas affectés mais les nouvelles ouvertures n'incluront pas ce tour.",
  delete_rounds_confirmation: 'Êtes-vous sûr? Ce tour - {name} - sera supprimé. Toutes les entrevues et évaluations de ce tour seront définitivement supprimées.',
  delete_rounds: 'Supprimer le tour?',
  delete_scale_sublabel_2: 'Ce niveau ne sera plus disponible et les données associées seront supprimées des emplois en brouillon et publiés.',
  delete_scales_confirmation_message: "Vous ne pouvez pas annuler cette action. Ce niveau sera retiré de l'échelle. Il n'y a pas d'emplois publiés qui incluent ce niveau.",
  delete_scales_with_jobs_confirmation_message: "Vous ne pouvez pas annuler cette action. Ce niveau sera supprimé de l'échelle. Les données de niveau seront définitivement supprimées de {count_published_jobs}.",
  delete_skill_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_skill_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_skill: "Supprimer @.lower:{'skills'}?",
  delete_skills_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_skills: "Supprimer @.lower:{'skills'}?",
  delete_statuses_confirmation: "La suppression de ce statut empêchera les utilisateurs de l'utiliser à l'avenir. Cela ne changera pas le statut des candidats existants.",
  delete_statuses: 'Supprimer le statut?',
  delete_subheadings_confirmation_message: "Vous ne pouvez pas annuler cette action. Ce sous-titre sera définitivement supprimé. Il n'y a pas d'emplois publiés qui incluent ce sous-titre.",
  delete_subheadings_with_jobs_confirmation_message: 'Vous ne pouvez pas annuler cette action. Ce sous-titre sera définitivement supprimé de {count_published_jobs}.',
  delete_surveys_confirmation_message: 'Cette action ne peut pas être annulée. Ce sondage sera définitivement supprimé. | Cette action ne peut pas être annulée. Ces sondages seront définitivement supprimés.',
  delete_surveys: 'Supprimer le sondage? | Supprimer les sondages?',
  delete_tag: 'Supprimer la balise?',
  delete_templates_msg: 'Vous ne pouvez pas annuler cette action. Ce guide sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces guides seront définitivement supprimés.',
  delete_templates: 'Supprimer le guide? | Supprimer les guides?',
  delete_topic_msg: 'Ce sujet sera supprimé des questions sur les sujets.',
  delete_topic_questions_msg: 'Vous ne pouvez pas annuler cette action. Cette question de sujet sera définitivement supprimée. | Vous ne pouvez pas annuler cette action. Ces questions de sujet seront définitivement supprimées.',
  delete_topic_questions: 'Supprimer la question de sujet? | Supprimer les questions de sujet?',
  delete_users_selection: 'Cet utilisateur sera supprimé du logiciel. Nous conserverons ses contributions. | Ces utilisateurs seront supprimés du logiciel. Nous conserverons leurs contributions.',
  delete_users: "Supprimer l'utilisateur? | Supprimer les utilisateurs?",
  delete_working_conditions_confirmation: 'Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé. | Vous ne pouvez pas annuler cette action. Ces éléments seront définitivement supprimés.',
  delete_working_conditions_msg: "Vous ne pouvez pas annuler cette action. Cet élément sera définitivement supprimé de l'archive.",
  delete_working_conditions: "Supprimer @.lower:{'working_conditions'}?",
  delete: 'Supprimer',
  deleted_job: 'Emploi supprimé',
  deleted_users: 'Utilisateurs supprimés',
  deleted: 'Supprimé',
  demoted: 'Rétrogradé',
  departed: 'Parti',
  department_assigned: 'Département attribué',
  departments_will_be_created: '{count} département sera créé | {count} départements seront créés',
  departments: 'Département | Départements',
  departure: 'Départ',
  deployed: 'Déployé',
  deprecated: 'Obsolète',
  description_communications: "Définir les sections d'ouverture et de clôture des gabarits d'entrevue.",
  description: 'Description',
  designated_users: 'Utilisateurs désignés',
  detailed_results: 'Résultats détaillés',
  details: 'Détail | Détails',
  development: 'Développement',
  differences: 'Différences',
  direct_reports: 'Subordonnés directs',
  direct: 'Direct',
  disable_review_cycles_message: 'Désactiver les cycles de révision annulera toutes les tâches de révision et supprimera toutes les dates de révision. Vous ne pouvez pas annuler cette action.',
  disable_review_cycles_title: 'Désactiver le cycle de révision?',
  disable: 'Désactiver',
  disabled: 'Désactivé',
  discard_comment_confirmation: 'Êtes-vous sûr de vouloir rejeter votre commentaire?',
  discard_comment: 'Rejeter le commentaire?',
  discard_edit_confirmation: 'Voulez-vous rejeter votre modification?',
  discard_edit: 'Rejeter la modification?',
  discard: 'Rejeter',
  disclaimer_section_remove_message: 'La section sera retirée de toutes les exportations en PDF / Word.',
  disclaimer_text: 'Texte de non-responsabilité',
  disclaimer: 'Avertissement',
  discussion_and_sharing_communicated_deadline: "C'est la date limite donnée aux gestionnaires pour planifier les discussions et partager les examens. Ils peuvent toujours effectuer ces actions après cette date.",
  discussion_and_sharing_description: "Les gestionnaires planifient un moment pour discuter et partager les résultats de l'examen avec leurs subordonnés directs. Vous pouvez laisser ce champ vide si les RH communiquent les résultats.",
  discussion_and_sharing_launch_date: 'Les gestionnaires recevront une notification et un courriel à cette date les invitant à planifier une discussion avec leurs subordonnés directs pour partager les résultats.',
  discussion_and_sharing_review_acknowledgements_description: "Exiger des sujets qu'ils confirment avoir reçu leurs résultats d'évaluation.",
  discussion_and_sharing_subtitle: 'Discuter et partager l’examen',
  discussion_and_sharing: 'Discussion et partage',
  dislikes: 'Défavorisation | Défavorisations',
  dispatched: 'Envoyé',
  do_not_hire: 'Ne pas embaucher',
  document_title_my_reviews: 'Examens - Mes examens',
  document_title_warehouse_competencies: 'Entrepôt : Compétence',
  does_not_have_ai_suggestion: "Nous n'avons aucune suggestion pour vous",
  does_not_provide_a_process_for_notifying_incumbents: 'Ne prévoit pas de processus pour informer les titulaires actuels.',
  done_editing: 'Modification terminée',
  done: 'Terminé',
  download_completed_signoffs: 'Télécharger les approbations complétées',
  download_excel: 'Télécharger Excel',
  download_guide: 'Télécharger le gabarit',
  download_interview_guides: "Télécharger le guide d'entrevue",
  download_interview_templates: "Télécharger le guide d'entrevue ",
  download_job_description: "Télécharger la description de l'emploi",
  download_link_expire_48_hours: 'Ce lien de téléchargement expirera dans 48 heures.',
  download_pdf: 'Télécharger le PDF',
  download_png: 'Télécharger le PNG',
  download_results: 'Télécharger les résultats',
  download_signoff_report_message: 'Télécharger un rapport des approbations complétées',
  download_started: 'Téléchargement en cours',
  download_table: 'Télécharger le tableau',
  download_word: 'Télécharger Word',
  download: 'Télécharger',
  draft_copy_with_published: 'Brouillon + Publié',
  draft_copy: 'Brouillon',
  draft_deleted: 'Brouillon supprimé',
  draft_job_no_guides_sub_message: "Publier cet emploi pour ajouter des gabarits d'entrevue. ",
  draft_plus_published: 'Brouillon + Publié',
  draft: 'Brouillon',
  drag_here: 'Glisser ici',
  due_date_included_in_survey_communications: "Les dates d'échéance sont incluses dans les communications relatives au sondage. Le sondage ne se fermera pas automatiquement à cette date - vous devrez toujours le fermer manuellement.",
  due_date: "Date d'échéance",
  due_x: 'Échéance : {date}',
  due: 'Pour',
  duplicate_job: "Dupliquer l'emploi",
  duplicate: 'Dupliquer',
  duplicated: 'Dupliqué',
  duration: 'Durée',
  e: 'E',
  edit_behavioral_indicator: "Modifier l'indicateur comportemental",
  edit_candidate_details: 'Modifier les détails du candidat',
  edit_candidate_statuses: 'Modifier le statut du candidat',
  edit_certification: "Modifier @.lower:{'singular_certifications'}",
  edit_definitions: 'Modifier la définition',
  edit_department: 'Modifier le département',
  edit_details: 'Modifier les détails',
  edit_due_date_reminders: 'Modifier les rappels de la date d’échéance',
  edit_education: "Modifier @.lower:{'singular_educations'}",
  edit_email: "Modifier l'adresse courriel",
  edit_employee_profiles_description: 'Définir les capacités à afficher sur les pages de profil des employés et la méthode de calcul des correspondances d’emploi.',
  edit_employee_profiles: 'Modifier les profils des employés',
  edit_follow_ups: 'Modifier les questions de suivi',
  edit_group_name: 'Modifier le nom du groupe',
  edit_guideline_description: "Fournir aux collaborateurs en emploi des directives personnalisées pour les aider à créer des descriptions d'emploi de haute qualité.",
  edit_guideline_dialog_title: 'Directives de {library}',
  edit_guideline: 'Modifier ces directives',
  edit_instructions: 'Modifier les instructions',
  edit_interview_rounds: "Modifier le tour d'entrevue",
  edit_jobs: "Modifier l'emploi",
  edit_level_title: 'Modifier le titre du niveau',
  edit_levels: 'Modifier le niveau',
  edit_links: 'Modifier le lien',
  edit_location_msg: 'Ce lieu sera mis à jour dans les lieux, les utilisateurs, et sur {count_published_jobs}.',
  edit_location: 'Modifier le lieu',
  edit_name_1: 'Modifier {name}',
  edit_name_and_definition: 'Modifier le nom et la définition',
  edit_name: 'Modifier le nom',
  edit_openings: "Modifier l'ouverture",
  edit_options: "Modifier l'option",
  edit_position: 'Modifier le poste',
  edit_questions: 'Modifier les questions',
  edit_ranges: 'Modifier la fourchette',
  edit_responsibilities: "Modifier @.lower:{'singular_responsibilities'}",
  edit_schedule: 'Modifier le programme',
  edit_setup: 'Modifier la configuration',
  edit_skill: "Modifier @.lower:{'singular_skills'}",
  edit_summary: 'Modifier le résumé',
  edit_tags: 'Modifier les balises',
  edit_templates: 'Modifier le guide',
  edit_this_statement: 'Modifier cette déclaration',
  edit_title_and_communications: 'Modifier le titre et les communications',
  edit_title: 'Modifier le titre',
  edit_topic: 'Modifier le sujet',
  edit_types: 'Modifier le type',
  edit: 'Modifier',
  edited_color: 'Couleur modifiée',
  edited_date: 'Modifié : {date} ',
  edited_interview_guide_question_positions: "Positions des questions du gabarit d'entrevue modifiées",
  edited_job_competency_positions: "Modifier les positions des @.lower:{'plural_competencies'} de l'emploi",
  edited_job_responsibility_positions: "Modifier les positions des @.lower:{'plural_responsibilities'} de l'emploi",
  edited_job_skill_positions: "Modifier les positions des @.lower:{'plural_skills'} de l'emploi",
  edited_levels: 'Niveaux modifiés',
  edited_questions: 'Questions modifiées',
  edited: 'Modifié',
  editors_description: 'Les éditeurs peuvent faire tout ce que les relecteurs peuvent faire. Ils peuvent également ajouter, supprimer et modifier le contenu des emplois.',
  editors: 'Éditeur | Éditeurs',
  education_archive: "Archive : @:{'plural_educations'}",
  education_edited: 'Élément modifié | Éléments modifiés',
  education_groups: "Groupes : @.capitalize:{'plural_educations'}",
  education_scales: "Échelle d'éducation",
  education: 'Éducation',
  educations_archived: 'Élément archivé | Éléments archivés',
  educations_created: 'Élément créé | Éléments créés',
  educations_deleted: 'Élément supprimé | Éléments supprimés',
  educations_guideline_description: "Les qualifications éducatives sont des qualifications que les individus acquièrent grâce à l'éducation formelle et à la formation. Ces qualifications fournissent une preuve, généralement sous forme de diplôme, confirmant la réussite de l'achèvement d'un programme éducatif dans un domaine d'étude ou une matière spécifique.<p class=\"mt-2\"> Il ne devrait pas y avoir plus de 4 exigences en matière d'éducation en tout.</p>",
  educations_moved: 'Élément déplacé | Éléments déplacés',
  educations_published: 'Élément publié | Éléments publiés',
  educations_restored: 'Élément restauré | Éléments restaurés',
  educations_updated: "Mis à jour : @.lower:{'singular_educations'} | Mis à jour : @.lower:{'plural_educations'}",
  educations: "@:{'singular_educations'} | @:{'plural_educations'}",
  effective_dates: "Date d'effet",
  email_content: 'Contenu du courriel',
  email_notifications_comments_description: "Recevoir une notification par courriel lorsque quelqu'un commente un commentaire que vous avez fait.",
  email_notifications_description: "Gérer vos notifications par courriel. Veuillez noter : Il n'est pas possible de se désabonner de certains courriels, tels que les invitations à collaborer sur un emploi ou à participer à un sondage.",
  email_notifications_feedback_description: 'Recevoir une notification par courriel lorsque des retours sont laissés sur un emploi sur lequel vous collaborez.',
  email_notifications_mentions_description: "Recevoir une notification par courriel lorsque quelqu'un vous mentionne dans une collaboration sur un emploi. ",
  email_notifications_review_cycles_description: "Recevoir des notifications pour les révisions d'emploi à venir.",
  email_notifications_updates_description: "Recevoir une notification par courriel lorsque l'emploi sur lequel vous collaborez est publié.",
  email_notifications_weekly_job_summary_description: "Recevoir un résumé hebdomadaire des mises à jour d'emploi.",
  email_notifications: 'Notification | Notifications',
  email_subject: 'Objet du courriel',
  email_taken: "L'adresse courriel que vous avez saisie est déjà utilisée. Si vous ne la trouvez pas, vérifiez la page de gestion des utilisateurs supprimés.",
  email: 'Courriel',
  employee_added_by: 'Employé ajouté par {name}',
  employee_id: "Identifiant de l'employé",
  employee_profile_update_weightings_toast_msg: 'Les mises à jour peuvent prendre jusqu’à 1 heure avant d’être publiées.',
  employee_profiles: "Profils d'employés",
  employees: 'Employé | Employés',
  empty_table: 'Rien à voir ici',
  en: 'Anglais',
  enable: 'Activer',
  enabled: 'Activé',
  end_date: 'Date de fin',
  end: 'Fin',
  enter_behavioral_indicator: "Saisir l'indicateur comportemental",
  enter_definition: 'Saisir la définition',
  enter_id: "Entrer l'ID",
  enter_name: 'Saisir le nom',
  enter_new_password: 'Saisir un nouveau mot de passe',
  enter_position_id: "Saisir l'ID du poste",
  enter_subheading: 'Saisir un sous-titre',
  enter_summary: 'Saisir le résumé',
  enter_url: "Entrer l'URL",
  equal_item_weighting_setting_tooltip: 'La pondération équitable des éléments attribue à chaque élément ajouté au profil d’un employé une contribution égale aux notes de correspondance. Par exemple, une certification serait équivalente à une compétence. Si vous désactivez ce paramètre, vous pouvez définir manuellement la contribution de chaque type de contenu. Par exemple, vous pourriez décider que les certifications valent 20 % des notes de correspondance.',
  equal_item: 'Élément égal',
  equivalent_skills_tooltip: "Les emplois dans l'entrepôt peuvent contenir des compétences et les aptitudes équivalentes. Pour inclure les compétences équivalentes lors de l'importation de contenu depuis l'entrepôt, cochez cette case. Nous vous recommandons de laisser cette option décochée pour éviter de dupliquer les compétences et les aptitudes.",
  equivalent_skills: 'Aptitudes équivalentes',
  evaluations: 'Évaluations',
  every_login: 'À chaque connexion',
  everything_looks_good: 'Tout semble bien',
  exceed_licenses_1: "Malheureusement, vous avez épuisé vos licences. Vous pouvez filtrer par type de licence ou ensemble de permissions pour confirmer les niveaux d'accès des utilisateurs. Veuillez contacter le service client si vous souhaitez obtenir des licences supplémentaires.",
  exceed_licenses_2: "Malheureusement, vous avez épuisé vos licences. Veuillez retirer des licences d'autres utilisateurs ou contacter le service client pour obtenir des licences supplémentaires.",
  exceed_licenses_3: 'Malheureusement, vous avez épuisé vos licences et nous ne pouvons pas effectuer cette action. Veuillez contacter le service client si vous souhaitez obtenir des licences supplémentaires.',
  exceed_requirements: 'Dépasse les exigences',
  excel: 'Excel',
  exceptions: 'Exceptions',
  exclude: 'Exclure',
  existing_job_descriptions: "Descriptions d'emploi existantes",
  exit_continue_later: 'Quitter et continuer plus tard',
  exit: 'Quitter',
  expand_all: 'Développer tout',
  experience: "@:{'singular_experiences'} | @:{'plural_experiences'}",
  experiences_archive: "Archive : @:{'plural_experiences'}",
  experiences_archived: 'Élément archivé | Éléments archivés',
  experiences_created: 'Élément créé | Éléments créés',
  experiences_deleted: 'Élément supprimé | Éléments supprimés',
  experiences_published: 'Élément publié | Éléments publiés',
  experiences_restored: 'Élément restauré | Éléments restaurés',
  experiences_updated: "Mis à jour : @.lower:{'singular_experiences'} | Mis à jour : @.lower:{'plural_experiences'}",
  experiences: "@:{'singular_experiences'} | @:{'plural_experiences'}",
  expired_description: "L'approbation n'est plus valide. (Cela est basé sur les paramètres du gabarit d'emploi.)",
  expired: 'Expiré',
  expiry_date: "Date d'expiration",
  expiry: 'Expiration',
  explanation_introduction_text: "Texte du lien d'explication",
  explanation_message: "Message d'explication",
  export_candidate_guide_pdf: 'Exporter le gabarit de candidat PDF',
  export_candidate_guide_word: 'Exporter le gabarit de candidat Word',
  export_data: 'Exporter les données',
  export_job: "Exporter l'emploi",
  export_org_chart: "Exporter l'organigramme",
  export_pdf: 'Exporter en PDF',
  export_ready_to_download: 'Votre export est prêt à être téléchargé',
  export_to_pdf: 'Exporter en PDF',
  export_to_word: 'Exporter en Word',
  export_word: 'Exporter en Word',
  export: 'Exporter',
  exports: 'Export | Exports',
  f: 'F',
  failed_login_attempts_msg: '{count} tentatives de connexion échouées depuis la connexion.',
  failed_to_create_job: "Échec de la création de l'emploi",
  features: 'Fonctionnalité',
  feb: 'Fév',
  feedback_deleted: 'Retours supprimés',
  feedback_description: "Apporter vos contributions sur cette description d'emploi.",
  feedback_post_template_tooltip: "Permettre aux collaborateurs en emploi de fournir des retours de haut niveau sur la description d'emploi.",
  feedback_provided: 'Retours fournis',
  feedback_updated: 'Retours mis à jour',
  feedback_visibility: 'Visibilité de la rétroaction',
  feedback: 'Retours',
  file_not_supported: 'Nous ne prenons pas en charge votre format de fichier.',
  file_same_columns_as_last_file: 'Ce fichier comporte les mêmes colonnes que le dernier fichier importé par {name} le {date}',
  file_too_large_mb: 'Votre fichier est trop volumineux. Veuillez téléverser un fichier de moins de {size} Mo.',
  filter_interviewers: 'Filtrer les intervieweurs',
  filter_results: 'Filtrer les résultats',
  filter: 'Filtrer',
  filtered_by: 'Filtré par',
  filters: 'Filtre | Filtres',
  find_competencies: "Trouver des @.lower:{'plural_competencies'}",
  find_departments: 'Trouver un département',
  find_job: 'Trouver un emploi',
  find_position_or_user: 'Trouver un poste ou un utilisateur',
  find_user_or_position: 'Trouver un utilisateur ou un poste',
  find_users: 'Trouver un utilisateur | Trouver des utilisateurs',
  fired: 'Congédié',
  first_name: 'Prénom',
  fit_for_role: 'Apte pour le poste',
  fit_for_the_role: 'Apte pour le poste',
  flag_jobs_for_later_notifications_msg: 'Les emplois marqués apparaîtront dans le tableau des activités de contenu afin que vous puissiez informer les titulaires des changements lorsque vous serez prêt.',
  flag_jobs_for_later_notifications: 'Marquer les emplois pour des notifications ultérieures',
  flagged_by: 'Marqué par {name}',
  flagged: 'Marqué',
  flip_toolbar: "Retourner la barre d'outils",
  follow_ups: 'Questions de suivi',
  for_for_role: 'Apte pour le poste',
  forgot_your_password: 'Mot de passe oublié?',
  format: 'Format',
  fr: 'Français',
  from_date_to: '{from} à {to}',
  from_hiring_team: "De l'équipe de recrutement",
  from_jobs: 'Des emplois',
  from_name_2: 'De {nom}',
  from_name: 'De : {name}',
  from_to: 'De {from} à {to}',
  from_warehouse: "De l'entrepôt",
  from: 'De',
  full_competencies: "@.capitalize:{'plural_competencies'} complètes",
  full_screen: 'Plein écran',
  gaps: 'Lacune|Lacunes',
  general_account_details: 'Détails généraux du compte',
  general_administration: 'Administration générale',
  general_comments: 'Commentaires généraux',
  general_error: "Désolé, une erreur s'est produite. ",
  general_notes: 'Notes générales',
  general: 'Général',
  give_feedback: 'Fournir des retours',
  go_live_date: 'Date de lancement',
  go_live_time: 'Heure de lancement',
  go_live: 'Lancer',
  go_to_home_page: "Aller à la page d'accueil",
  go_to_homepage: "Accéder à la page d'accueil",
  goals: 'Objectifs',
  good_to_go: 'Prêt à commencer',
  google: 'Google',
  got_it: 'Compris',
  grant_signoff_message_1: "{name} obtiendra automatiquement l'approbation pour la dernière version de son emploi. Aucune demande d'approbation ne sera envoyée. | Les employés sélectionnés obtiendront automatiquement l'approbation pour la dernière version de leur emploi. Aucune demande d'approbation ne sera envoyée.",
  grant_signoff_message_2: "Vous pouvez choisir d'accorder l'approbation si vous avez apporté des mises à jour mineures à un emploi publié et que vous n'avez pas besoin d'une nouvelle approbation des employés. ",
  grant_signoff_title: "Accorder l'approbation?",
  grant_signoffs_on_version: "Accorder l'approbation pour la version {version}",
  grant_signoffs: "Accorder l'approbation",
  group_created: 'Groupe créé',
  group_deleted: 'Groupe supprimé',
  group_name_updated: 'Mise à jour du nom du groupe',
  groups: 'Groupe | Groupes',
  grow_access: 'Grow',
  grow_description: "Accorde l'accès à Grow.",
  grow_option_description: "Offre l'accès aux fonctionnalités de développement incluses dans Grow. Les utilisateurs ajoutés à cet ensemble de permissions se voient accorder une licence utilisateur Grow.",
  grow: 'Grow',
  guide_communications: "Communications du gabarit d'entrevue",
  guide_job_related_questions_empty_msg: "Pas de questions liées à l'emploi ? <p class=\"mt-2\">Assurez-vous que la description de l'emploi contient des compétences et des niveaux de compétence.</p>",
  guide_not_translated: "Certaines parties de ce gabarit n'ont pas été traduites.",
  guideline_description: "Fournir aux collaborateurs en emploi des directives personnalisées pour les aider à créer des descriptions d'emploi de haute qualité.",
  guidelines: 'Directives',
  guides_deleted: 'Gabarit supprimé | Gabarits supprimés',
  guides: 'Gabarit | Gabarits',
  header_background: "Couleur de fond de l'en-tête du site",
  header_foreground: "Couleur de premier plan de l'en-tête du site",
  help_center: "Centre d'aide",
  hi_name: 'Bonjour {name}',
  hidden_columns: 'Colonnes masquées',
  hide_all_details: 'Masquer tous les détails',
  hide_changes: 'Masquer les modifications',
  hide_details: 'Masquer les détails',
  hide_property_data_msg: "Les données ne seront pas affichées dans les descriptions d'emploi.",
  hide_property_data: 'Masquer les données de propriété',
  hide_toolbar: "Masquer la barre d'outils",
  hide: 'Masquer',
  highlight: 'Surligner',
  highlights: 'Point fort | Points forts',
  hire_date_formats: "Format de la date d'embauche",
  hire_date: "Date d'embauche",
  hiring_manager_warning: "Si vous voulez que le responsable du recrutement choisisse les questions d'entrevue, contactez un administrateur Quinto et demandez que cet utilisateur obtienne l'autorisation d'accéder aux gabarits d'entrevue.",
  hiring_manager: 'Responsable du recrutement : {name}',
  hiring_managers: 'Responsable du recrutement',
  hiring_team_invitation_message: 'Une invitation sera envoyée aux nouveaux membres.',
  hiring_team_members: "Membre de l'équipe de recrutement",
  hiring_teams: 'Équipe de recrutement',
  history: 'Historique',
  hold_tight: 'Tenez-vous bien',
  home: 'Accueil',
  homepage_core_competencies: "Page d'accueil - Compétences de base",
  homepage_description: "Définir les informations et l'expérience de la page d'accueil pour les utilisateurs.",
  homepage_job_analytics: "Page d'accueil - Analyses d'emploi",
  homepage_useful_links: "Page d'accueil - Liens utiles",
  homepage_welcome_message: "Page d'accueil - Message de bienvenue",
  homepage: "Page d'accueil",
  hourly: 'Horaire',
  ideal_range: 'Plage idéale',
  identified_users_in_file: 'Nous avons identifié {number} utilisateurs dans votre fichier',
  if_import_file: 'Si vous importez votre fichier…',
  im_done: 'Terminé',
  im_still_here: 'Je suis toujours ici',
  import_active_status_tooltip: "Sélectionner les statuts dans votre fichier d'importation qui devraient être importés comme actifs.",
  import_departments_tooltip_2: 'Nous créerons de nouveaux départements si nous ne trouvons pas de départements déjà existants.',
  import_departments_tooltip: 'Nous placerons les postes dans les départements en fonction de ces données.',
  import_frequency: "Fréquence d'importation",
  import_job_title_tooltip_2: "De nouveaux postes peuvent encore être créés à partir des titres de la colonne de poste, mais ils seront associés à des emplois dont le titre correspond exactement aux données de la colonne de titre d'emploi.",
  import_job_title_tooltip: "Si votre fichier d'importation comporte des colonnes distinctes pour les titres d'emploi et les titres de poste, utilisez ce champ pour faire correspondre les postes aux emplois appropriés.",
  import_jobs: 'Importer des emplois',
  import_language_tooltip_2: 'Tout utilisateur sans langue spécifiée dans le fichier sera assigné à la langue par défaut.',
  import_language_tooltip_3: "Si vous ne sélectionnez pas de colonne, la langue par défaut sera attribuée à tous les utilisateurs inclus dans le fichier d'importation.",
  import_language_tooltip: "Nous définirons la langue de l'utilisateur en fonction de cette colonne si les langues correspondantes sont activées sur votre site.",
  import_languages: "Langue d'importation",
  import_location_tooltip_2: "Tous les utilisateurs sans lieu spécifié dans le fichier seront affectés au lieu par défaut s'il a été défini.",
  import_location_tooltip_3: "Pour affecter tous les utilisateurs à un lieu par défaut, laissez cette sélection vide et assurez-vous d'avoir défini un lieu par défaut dans les paramètres du compte.",
  import_location_tooltip: "Mapper une colonne pour créer et attribuer différents lieux à chaque utilisateur dans votre fichier d'importation.",
  import_manager_email_tooltip_2: "Notez que vous pouvez spécifier des gestionnaires qui ne sont pas inclus dans le fichier d'importation tant qu'ils existent déjà dans Quinto.",
  import_manager_email_tooltip: 'Nous attribuerons des gestionnaires en fonction de ces données pour construire la hiérarchie de votre organisation.',
  import_permission_sets_tooltip_2: "Tout utilisateur sans ensemble de permissions spécifié dans le fichier sera assigné à l'ensemble de permissions par défaut.",
  import_permission_sets_tooltip_3: "Si vous ne sélectionnez pas de colonne, l'ensemble de permissions par défaut sera attribué à tous les utilisateurs inclus dans le fichier d'importation.",
  import_permission_sets_tooltip: "Nous attribuerons aux utilisateurs des ensembles de permissions correspondants si nous trouvons une correspondance de texte exacte sur votre site. De nouveaux ensembles de permissions ne peuvent pas être créés via l'importation.",
  import_position_id_tooltip: 'Utiliser les ID de poste pour des mises à jour de poste plus fiables. Sans ID, nous utiliserons la correspondance de texte pour mettre à jour les postes.',
  import_position_tooltip_2: "Nous créerons de nouveaux postes qui n'existent pas encore sur votre site. Les descriptions d'emploi ne peuvent pas être créées via l'importation.",
  import_position_tooltip_3: "Nous associerons les postes aux emplois de votre site qui correspondent exactement. Alternativement, vous pouvez utiliser le champ de titre d'emploi (ci-dessous) si vous gérez les titres de poste et les titres d'emploi séparément.",
  import_position_tooltip: 'Les postes sont la colle organisationnelle dans Quinto. Mapper ce champ pour lier les utilisateurs aux emplois ou pour construire votre hiérarchie organisationnelle',
  import_started: 'Importation commencée',
  import_status_tooltip_2: "Si vous ne sélectionnez pas de colonne, tous les utilisateurs inclus dans le fichier d'importation seront importés comme actifs.",
  import_status_tooltip: "Pour importer certains utilisateurs comme actifs et d'autres comme inactifs, mapper une colonne de votre fichier.",
  import_users: 'Importer des utilisateurs',
  import: 'Importer',
  in_depth: 'Approfondi',
  in_person_discussion: 'Discussion en personne',
  in_progress: 'En cours',
  inactive: 'Inactif',
  include_content_as_per_template_settings: 'Inclut le contenu selon les paramètres du gabarit',
  include_reports: 'Inclure les subordonnés',
  include_subdepartments: 'Inclure les sous-départements',
  include_subheading_job_post: "Inclure les sous-titres dans les annonces d'emploi. ",
  include: 'Inclure',
  includes: 'Inclure | Inclut',
  incomplete: 'Incomplet',
  incorrect_password_or_bad_account_msg: "Votre mot de passe est incorrect ou ce compte n'existe pas. Veuillez réessayer ou <span id=\"reset-password-link\" class=\"text-link cursor-pointer\">réinitialisez votre mot de passe</span>.",
  incumbent_removed_position: '{name} sera retiré de son poste actuel.',
  incumbents: 'Titulaire',
  indepth_assessment_scale: 'Échelle d’évaluation approfondie',
  information_manage_properties: 'Information - gérer les propriétés',
  information_tooltray_description: "Sélectionner les propriétés de l'emploi à afficher dans les annonces d'emploi.",
  information: 'Information',
  initialize: 'Initialiser',
  initiated: 'Initié',
  input_closed: 'Fermé pour les retours',
  input_open: 'Ouvert pour les retours',
  insert_alternative: 'Insérer une alternative',
  insert_summary: 'Insérer un résumé',
  instruction_content: 'Contenu des instructions',
  instruction_heading: 'En-tête des instructions',
  instructions: 'Instruction | Instructions',
  integration_ats_for_interview_title: 'ATS pour les entrevues',
  integration_ats_for_interview: "Sélectionner l'ATS à intégrer.",
  integration_ats_for_jobs_title: 'ATS pour les emplois',
  integration_ats_for_jobs: "Sélectionner l'ATS à intégrer.",
  integration_Hris_tab_description: 'Sélectionner la HRIS à intégrer.',
  integrations: 'Intégration | Intégrations',
  internal: 'Interne',
  interview_conducted: 'Entrevue réalisée',
  interview_details: "Détails de l'entrevue",
  interview_feedbacks: "Retour d'entrevue | Retours d'entrevue",
  interview_guide_questions: "Question de gabarit d'entrevue | Questions de gabarit d'entrevue",
  interview_guide_scales: "Échelle de gabarit d'entrevue",
  interview_guides: "Gabarit d'entrevue | Gabarits d'entrevue",
  interview_plans: "Plan d'entrevue",
  interview_question_followups: "Suites de questions d'entrevue",
  interview_question_topics: "Sujet des questions d'entrevue",
  interview_questions_moved_to_library_message: "Les questions d'entrevue ont été déplacées vers les bibliothèques. {link}",
  interview_questions: "Question d'entrevue | Questions d'entrevue",
  interview_rounds_description: "Définir les tours d'entrevue par défaut. Les modifications n'affecteront que les tours créés à l'avenir. Les tours d'entrevue peuvent encore être modifiés pour chaque ouverture individuelle - ce ne sont que des valeurs par défaut.",
  interview_rounds: "Tour d'entrevue | Tours d'entrevue",
  interview_team: "Équipe d'entrevue",
  interview_template_communications: "Communication de guide d'entrevue | Communications de guide d'entrevue",
  interview_template_groups: "Groupes de gabarits d'entrevue",
  interview_templates: "Gabarit d'entrevue | Gabarits d'entrevue",
  interviewer_assign_question_tooltip: 'Cette question est attribuée à {name}',
  interviewers: 'Intervieweur | Intervieweurs',
  interviews: 'Entrevues',
  intro_page_details: "Détails de l'introduction du sondage",
  intro_page: 'Introduction du sondage',
  introduction: 'Introduction',
  invitation_email_details: "Détails du courriel d'invitation",
  invitation_email_to_managers: 'Courriel d’invitation aux gestionnaires',
  invitation_email_to_subjects: 'Courriel d’invitation aux sujets',
  invitation_email: "Courriel d'invitation",
  invitation_message: "Message d'invitation",
  invitations_resent: 'Invitation renvoyée',
  invitations_sent_go_live_date: "Les invitations seront envoyées à la date et à l'heure de lancement.",
  invitations_sent: 'Invitation envoyée',
  invitations: 'Invitations',
  invite_collaborators: 'Inviter des collaborateurs',
  invite_sent: 'Invitation envoyée',
  invite_x_to_quinto: 'Inviter {name} à Quinto.',
  invite: 'Inviter',
  invited_contributor: 'Contributeur invité',
  invited_interviewer: 'Intervieweur invité',
  invited: 'Invité',
  invites_sent: 'Invitation envoyée | Invites envoyées',
  ip_address: 'Adresse IP',
  item_not_translated: "Cet élément n'a pas été traduit. ",
  item_same_name_exists: 'Un élément portant le même nom existe déjà',
  item: 'Élément',
  items_added_items_not_added: "{added} élément ajouté. {not_added} élément n'a pas pu être ajouté. | {added} éléments ajoutés. {not_added} éléments n'ont pas pu être ajoutés.",
  items_added_to_site: "L'élément a été ajouté à votre site | Les éléments ont été ajoutés à votre site",
  items_added: '{count} élément ajouté. | {count} éléments ajoutés.',
  items_moved: 'Élément déplacé | Éléments déplacés',
  items_not_added: "{count} élément n'a pas pu être ajouté. | {count} éléments n'ont pas pu être ajoutés.",
  items_not_translated: "Il y a des éléments qui n'ont pas été traduits.",
  items: 'Élément | Éléments',
  jan: 'Jan',
  job_additional_informations_changes_were_found_live_review_text_1: 'Toutes les données soumises seront définitivement supprimées.',
  job_additional_informations: 'Emploi information supplémentaire',
  job_ai_recognition_sub: "Utiliser l'IA pour identifier les éléments de bibliothèque ajoutés directement aux emplois par votre organisation.",
  job_ai_recognition: "Reconnaissance d'IA",
  job_ai_suggestions_des: "Voir le contenu suggéré par l'IA lors de la création et de la modification des emplois.",
  job_ai_suggestions_sub: "Utiliser l'IA pour identifier le contenu lors de la création et de la modification des emplois.",
  job_ai_suggestions: "Suggestions d'IA",
  job_analytics_description: "Ce widget s'affiche uniquement pour les utilisateurs ayant la permission d'accéder aux analyses. ",
  job_analytics: "Analyse d'emploi | Analyses d'emploi",
  job_certifications: 'Emploi certification',
  job_closed_for_input: "L'emploi est fermé pour les contributions",
  job_codes: "Code d'emploi",
  job_comments: "Commentaire d'emploi",
  job_comparison_scoring_authority_x_1: 'Cela est basé sur vos notations.',
  job_comparison_scoring_authority_x_2: 'Cela est basé sur les notations de {subjectName}.',
  job_comparison_scoring_authority_x_3: 'Cela est basé sur les notations de {managerName}.',
  job_comparison_scoring_authority_x_4: 'Cela est basé sur les notations fournies par les responsables de {subjectName}.',
  job_comparison_scoring_authority_x_5: 'Cela est basé sur les notations fournies par les pairs de {subjectName}.',
  job_comparison: 'Comparaison des emplois',
  job_competencies_set_to_done: "@.capitalize:{'plural_competencies'} de l'emploi marquées comme terminées",
  job_competencies: "@.capitalize:{'singular_competencies'} de l'emploi | @.capitalize:{'plural_competencies'} de l'emploi",
  job_contributors: "Contributeur de l'emploi | Contributeurs de l'emploi",
  job_coverage_by_month_tooltip: 'Le pourcentage mensuel de postes ayant des emplois publiés.',
  job_coverage_by_month: 'Couverture des emplois',
  job_coverage_tooltip: 'Le pourcentage de postes ayant actuellement des emplois publiés.',
  job_coverage: 'Couverture des emplois',
  job_description_tab: 'Description',
  job_description_template_change_warning: "Les données de l'emploi peuvent être perdues si vous changez de gabarit.",
  job_description: "Description d'emploi",
  job_descriptions_import_complete: 'Vos descriptions d’emploi ont été importées.',
  job_descriptions_import_notification_msg: '{link} pour voir les emplois importés.',
  job_descriptions_import_notification_toast_msg: "Vous recevrez un courriel à {email} une fois le processus d'importation est terminé.",
  job_descriptions: "Description d'emploi |  Descriptions d'emploi",
  job_details: "Détails de l'emploi",
  job_draft_version_msg: "Il existe une <span class=\"border-b border-dashed\">version brouillon</span> de la description d'emploi. Publiez la description d'emploi pour mettre à jour cette page.",
  job_duplicate_message: 'Veuillez utiliser un titre unique - celui-ci existe déjà.',
  job_education_items: "Élément d'éducation d'emploi",
  job_educations: 'Emploi éducation',
  job_experience_items: "Élément d'expérience d'emploi",
  job_experiences: 'Emploi expérience',
  job_families: "Famille d'emploi",
  job_family_competencies_description: "Ces éléments sont ajoutés à tous les emplois dans chaque groupe d'emplois. Pour ajouter des @.lower:{'plural_competencies'} à une famille d'emplois, allez dans {jobGroups}.",
  job_family_competencies: "@.capitalize:{'plural_competencies'} de la famille d'emplois",
  job_family: 'Famille d’emplois',
  job_group_competencies_description: 'Ces éléments seront automatiquement ajoutés à tous les emplois dans ce groupe.',
  job_group_competencies: "@.capitalize:{'singular_competencies'} de groupe d'emploi",
  job_group_owners_description: 'Ces utilisateurs seront automatiquement ajoutés à tous les emplois de ce groupe.',
  job_groups: "Groupe d'emplois | Groupes d'emplois",
  job_has_been_updated: 'Cet emploi a été mis à jour.',
  job_health: "Santé d'emploi",
  job_information_set_to_done: "Informations sur l'emploi marquées comme terminées",
  job_input_close_message: 'Cet emploi est actuellement fermé aux contributions',
  job_language_items: "Élément de langue d'emploi",
  job_languages: 'Emploi langue',
  job_level: "Niveau d'emploi",
  job_levels: "Niveaux d'emploi",
  job_match_no_data_msg: 'Nous n’avons pas encore assez d’informations pour afficher vos données de correspondance d’emploi.',
  job_match_scoring_authority_x_1: 'Le pourcentage est basé sur vos contributions.',
  job_match_scoring_authority_x_2: 'Le pourcentage est basé sur les contributions de {subjectName}.',
  job_match_scoring_authority_x_3: 'Le pourcentage est basé sur les contributions de {managerName}.',
  job_match_scoring_authority_x_4: 'Le pourcentage est basé sur les contributions fournies par les gestionnaires de {subjectName}.',
  job_match_weighting: 'Pondération de la correspondance d’emploi',
  job_match: 'Correspondance des emplois',
  job_matching: "Correspondance d'emploi",
  job_multiple_export: 'Exportation commencée. Nous vous enverrons un courriel quand ce sera terminé.',
  job_no_guides_message: "C'est assez calme ici. ",
  job_no_guides_sub_message: "Pourquoi ne pas créer un gabarit d'entrevue pour cet emploi?",
  job_opened_for_input: "L'emploi est ouvert aux contributions",
  job_post_empty_msg_sub: "Publier cette description d'emploi pour créer une annonce d'emploi.",
  job_post_empty_msg: "Il n'y a rien à voir ici",
  job_post_template_library_content_desc: "Choisir le contenu à afficher dans les annonces d'emploi.",
  job_post_template_summary_description: "Activer cette section pour inclure le résumé de l'emploi dans les annonces utilisant ce gabarit. ",
  job_post_templates: "Annonce d'emploi",
  job_post_will_sent_to_ats: "Cette annonce d'emploi sera envoyée à {ats}. ",
  job_post: "Annonce d'emploi",
  job_posts: "Annonces d'emploi",
  job_properties_description: 'Les propriétés définissent les éléments de formulaire qui peuvent être ajoutés aux emplois.',
  job_properties_exports_description: 'Propriétés incluses dans les exports PDF / Word.',
  job_properties_general_description: "Propriétés incluses lors de l'affichage des emplois en dehors du module des emplois.",
  job_properties_jobs_description: 'Propriétés disponibles lors de la manipulation des emplois dans le module des emplois.',
  job_properties_updated: "Propriétés d'emploi mises à jour",
  job_properties: "Propriété d'emploi | Propriétés d'emploi",
  job_property_multi_select: "Il s'agit d'une propriété à sélection multiple. Lors de la modification d'un emploi, les utilisateurs peuvent choisir une ou plusieurs des options ci-dessous.",
  job_property_options: "Option de propriété d'emploi",
  job_property_renamed: "Propriété d'emploi renommée",
  job_property_select: "Il s'agit d'une propriété à sélection unique. Lors de la modification d'un emploi, les utilisateurs peuvent choisir l'une des options ci-dessous",
  job_property_textbox: "Il s'agit d'une propriété alphanumérique. Lors de la modification d'un emploi, les utilisateurs peuvent saisir n'importe quelle combinaison de chiffres et de lettres. Il n'y a pas d'options pour cette propriété.",
  job_publish_recommended_message: "Avant la publication, nous recommandons de mettre à jour l'emploi. ",
  job_publish_requirement_message: "Avant la publication, veuillez mettre à jour l'emploi. ",
  job_published_by: 'Emploi publié par {name}',
  job_ready_to_go: 'Cet emploi est prêt à être lancé.',
  job_related_questions: "Questions liées à l'emploi",
  job_republished_by: 'Emploi republié par {name}',
  job_requirements: "Exigences de l'emploi",
  job_responsibilities: "@.capitalize:{'singular_responsibilities'} de l'emploi | @.capitalize:{'plural_responsibilities'} de l'emploi",
  job_setting: "Paramètre de l'emploi | Paramètres de l'emploi",
  job_sign_off: "Approbation d'emploi",
  job_signoff_instructions: "Veuillez lire attentivement votre description d'emploi et cliquer sur Soumettre en bas de la page pour compléter l'approbation.",
  job_signoffs_description: "Gérer les approbations des descriptions d'emploi.",
  job_signoffs: "Approbation d'emploi | Approbations d'emploi",
  job_skill_items: "Élément d'aptitude d'emploi",
  job_skill: "@.capitalize:{'plural_skills'} de l'emploi",
  job_skills: "Aptitude d'emploi",
  job_specific: "Propre à l'emploi",
  job_subheadings: "Sous-titre de l'emploi | Sous-titres de l'emploi",
  job_summary_set_to_done: "Résumé de l'emploi marqué comme terminé",
  job_summary: "Résumé de l'emploi",
  job_tab_employee_empty_msg: "Pourquoi ne pas ajouter d'employés à cet emploi?",
  job_template_content_guidelines: "Directive de contenu de gabarit d'emploi",
  job_template_contents: "Contenu du gabarit d'emploi",
  job_template_properties: "Propriété de gabarit d'emploi",
  job_template_turn_on_disabled_message: "Ce gabarit ne peut pas être activé car il n'a pas de gabarit d'annonce d'emploi associé.",
  job_template_type: 'Type',
  job_template_updated: "Gabarit d'emploi mis à jour",
  job_templates_subheadings: "Sous-titre de gabarit d'emploi",
  job_templates: "Gabarit d'emploi | Gabarits d'emploi",
  job_title: "Titre d'emploi",
  job_types: "Types d'emploi",
  job_update_controlled_subtext: 'Utile pour les mises à jour nécessitant une révision ou une confirmation.',
  job_update_controlled_text: 'Mise à jour contrôlée',
  job_update_label: 'Veuillez choisir un processus de mise à jour.',
  job_update_no_update_text: 'Pas de mise à jour',
  job_update_silent_subtext: 'Utile pour les mises à jour qui ne nécessitent pas de révision ou de confirmation.',
  job_update_silent_text: 'Mise à jour silencieuse',
  job_updated: 'Emploi mis à jour',
  job_version_number: 'Version {version}',
  job_version: "Version de l'emploi",
  job_versions: "Versions d'emploi",
  job_working_conditions: 'Emploi condition de travail',
  jobs_archive: "Archive d'emploi",
  jobs_archived: 'Emploi archivé | Emplois archivés',
  jobs_deleted: 'Emploi supprimé | Emplois supprimés',
  jobs_digest: 'Résumé des emplois',
  jobs_duplicated: "Emploi dupliqué. {link} pour accéder à l'emploi en double.",
  jobs_moved: 'Emploi déplacé | Emplois déplacés',
  jobs_published: 'Emploi publié | Emplois publiés',
  jobs_restored: 'Emploi restauré | Emplois restaurés',
  jobs: 'Emploi | Emplois',
  jul: 'Juil',
  jun: 'Juin',
  key_stats: 'Statistiques clés',
  label_behavioral_indicator: "L'indicateur comportemental",
  language_archived: 'Élément archivé | Éléments archivés',
  language_deleted: 'Élément supprimé | Éléments supprimés',
  language_en: 'Ang',
  language_fr: 'Fra',
  language_published: 'Élément publié | Éléments publiés',
  language_scales: 'Échelle linguistique',
  languages_archive: "Archive : @:{'plural_languages'}",
  languages_archived: 'Élément archivé | Éléments archivés',
  languages_created: 'Élément créé | Éléments créés',
  languages_deleted: 'Élément supprimé | Éléments supprimés',
  languages_published: 'Élément publié | Éléments publiés',
  languages_restored: 'Élément restauré | Éléments restaurés',
  languages_updated: "Mis à jour: @.lower:{'singular_languages'} | Mis à jour: @.lower:{'plural_languages'}",
  languages: "@:{'singular_languages'} | @:{'plural_languages'}",
  last_12_months: 'Les 12 derniers mois',
  last_30_days: 'Les 30 derniers jours',
  last_6_months: 'Les 6 derniers mois',
  last_60_days: 'Les 60 derniers jours',
  last_7_days: 'Les 7 derniers jours',
  last_90_days: 'Les 90 derniers jours',
  last_imported_at: 'Dernière importation le',
  last_login: 'Dernière connexion',
  last_name: 'Nom de famille',
  last_published_version: 'Dernière version publiée.',
  last_published: 'Publié',
  last_pushed_at: 'Dernier envoi à',
  last_reset: 'Dernière réinitialisation',
  last_sent: 'Dernier envoi : {date}',
  last_signed_off_version: 'Dernière version approuvée.',
  last_signoff_date: 'Date de la dernière approbation',
  last_signoff_header_tooltip: "Affiche la dernière approbation sur la dernière version de l'emploi. ",
  last_signoff: 'Dernière approbation',
  last_sync_at: 'Dernière synchronisation à',
  last_updated_on: 'Dernière mise à jour le {date}',
  lateral_move: 'Déplacement latéral',
  latest_status: 'Dernier statut',
  launch_dates: 'Date de lancement | Dates de lancement',
  launch_now: 'Lancer maintenant',
  launch: 'Lancer',
  launched: 'Lancé',
  layout: 'Mise en page',
  leadership_competencies_description: "Ces éléments sont ajoutés à tous les emplois à chaque niveau d'emploi. Pour gérer les niveaux d'emploi, rendez-vous sur {jobProperties}.",
  leadership_competencies: "@.capitalize:{'plural_competencies'} de direction",
  leadership: 'Direction',
  learn_more: 'En savoir plus',
  learning_resources: "Ressource d'apprentissage | Ressources d'apprentissage",
  legal: 'Légal',
  less: 'Moins',
  let_participants_know_purpose_survey: "Informer les participants de l'objectif de ce sondage.",
  let_users_export_the_org_chart: "Autoriser les utilisateurs à exporter l'organigramme.",
  level_analysis_competencies: "Analyse de niveau - @:{'plural_competencies'}",
  level_analysis_skills: "Analyse de niveau - @:{'plural_skills'}",
  level_analysis: 'Analyse de niveau',
  level_comparison_title: 'Comparaisons de niveau de {library_name}',
  level_comparisons: 'Comparaisons de niveau',
  level_distribution_details: 'Les barres indiquent la répartition en pourcentage des notations pour chaque élément de l’évaluation par niveau. Survolez chaque barre pour voir le nombre exact de participants.',
  level_distribution_help: 'Ce graphique montre la répartition des notations pour chaque élément de l’évaluation. Il aide à identifier les points forts et les points faibles relatifs dans l’organisation.',
  level_distributions: 'Répartition des niveaux',
  level_name: 'Niveau',
  level_summary: 'Résumé de niveau',
  level_x_examples: 'Exemples de {level} :',
  level_x: 'Niveau {number}',
  levels_updated: 'Niveau mis à jour | Niveaux mis à jour',
  levels: 'Niveau | Niveaux',
  libraries_certifications: "Bibliothèques - @:{'plural_certifications'}",
  libraries_competencies: "Bibliothèques - @:{'plural_competencies'}",
  libraries_educations: "Bibliothèques - @:{'plural_educations'}",
  libraries_experiences: "Bibliothèques - @:{'plural_experiences'}",
  libraries_informations: "Bibliothèques - @:{'plural_additional_information'}",
  libraries_languages: "Bibliothèques - @:{'plural_languages'}",
  libraries_responsibilities: "Bibliothèques - @:{'plural_responsibilities'}",
  libraries_skills: "Bibliothèques - @:{'plural_skills'}",
  libraries_sub_msg: 'Définir les noms et la disponibilité des bibliothèques.',
  libraries_working_conditions: "Bibliothèques - @:{'plural_working_conditions'}",
  libraries: 'Bibliothèque | Bibliothèques',
  library_archive_certifications: "Archive de bibliothèque - @:{'plural_certifications'}",
  library_archive_competencies: "Archive de bibliothèque - @:{'plural_competencies'}",
  library_archive_educations: "Archive de bibliothèque - @:{'plural_educations'}",
  library_archive_experiences: "Archive de bibliothèque - @:{'plural_experiences'}",
  library_archive_informations: "Archive de bibliothèque - @:{'plural_additional_information'}",
  library_archive_languages: "Archive de bibliothèque - @:{'plural_languages'}",
  library_archive_responsibilities: "Archive de bibliothèque - @:{'plural_responsibilities'}",
  library_archive_skills: "Archive de bibliothèque - @:{'plural_skills'}",
  library_archive_working_conditions: "Archive de bibliothèque - @:{'plural_working_conditions'}",
  library_competency_questions: 'Bibliothèques - Question de compétence | Bibliothèques - Questions de compétence',
  library_group_certifications: "Groupe de bibliothèque - @:{'plural_certifications'}",
  library_group_competencies: "Groupe de bibliothèque - @:{'plural_competencies'}",
  library_group_educations: "Groupe de bibliothèque - @:{'plural_educations'}",
  library_group_informations: "Groupe de bibliothèque - @:{'plural_additional_information'}",
  library_group_responsibilities: "Groupe de bibliothèque - @:{'plural_responsibilities'}",
  library_group_skills: "Groupe de bibliothèque - @:{'plural_skills'}",
  library_highlighter: 'Surligneur de bibliothèque',
  library_items: 'Élément de bibliothèque',
  library_requirements: 'Exigences de {library}',
  library_topic_questions: 'Bibliothèques - Question de sujet | Bibliothèques - Questions de sujet',
  library_turn_off_msg: 'Cette bibliothèque ne sera plus disponible et les données seront supprimées des emplois concernés.',
  library_turn_on_msg: "Pour utiliser cette bibliothèque dans les descriptions d'emploi, ajoutez-la aux gabarits d'emploi.",
  license_type_administrator: 'admin',
  license_type_interviews: 'Entrevues {license_type}',
  license_type_job: "@:{'plural_jobs'} {license_type}",
  license_type_platform: 'Plateforme {license_type} ',
  license_type_regular: 'utilisateur',
  license_type_reviews: "Utilisateur d'examen",
  license_type: 'Type de licence',
  licenses_interviews: "Administrateurs d'entrevues",
  licenses_jobs: "Administrateurs d'emplois",
  licenses_platform: 'Utilisateurs de la plateforme ',
  licenses_reviews: 'Utilisateurs de révisions',
  licenses: 'Licences',
  likes: 'Favorisation | Favorisations',
  link_reset_password_sent_email: "Un lien pour réinitialiser votre mot de passe vous a été envoyé, à condition que l'adresse courriel corresponde à votre compte.",
  link_texts: 'Texte du lien',
  linked: 'Lié',
  linkedin_profile: 'Profil LinkedIn',
  linkedin: 'LinkedIn',
  live_review_add_participants_warning: 'Cet examen est en cours. La suppression des sujets effacera toutes les données associées aux résultats de l’examen.',
  live_review_modal_change_job_x_text_1: 'Toutes les données soumises seront définitivement supprimées.',
  live_review_modal_change_job_x_text_2: 'Tous les participants évaluant {subjectName} recevront une nouvelle invitation et une nouvelle tâche pour pouvoir évaluer {subjectName} sur le nouvel emploi.',
  live_review_modal_change_manager_x_text_1: "Toutes les données soumises par un gestionnaire retiré de l'examen seront définitivement supprimées.",
  live_review_modal_change_manager_x_text_2: 'Un nouveau gestionnaire recevra une invitation et une nouvelle tâche.',
  live_survey_contents_warning: 'Ce sondage est lancé. La suppression du contenu entraînera la suppression de toutes les données associées aux résultats du sondage.',
  live_survey_participants_warning: 'Ce sondage est lancé. La suppression des participants entraînera la suppression de toutes les données associées aux résultats du sondage.',
  live: 'Lancé',
  loading_matches: 'Chargement des correspondances…',
  location_added: 'Lieu ajouté',
  location_deleted: 'Lieu supprimé',
  location_table_desc: 'Les lieux peuvent être utilisés pour les utilisateurs et les emplois.',
  locations_will_be_created: '{count} lieu sera créé | {count} lieux seront créés',
  locations: 'Lieu | Lieux',
  log_in_with_sso: 'Se connecter avec SSO',
  log_in: 'Se connecter',
  logged_in: 'Connecté',
  login_account_suspended_message: "Votre compte a été suspendu en raison de tentatives excessives. Veuillez contacter l'administrateur de votre site pour accéder à nouveau.",
  login_as_user: "Se connecter en tant qu'utilisateur",
  login_attempt_message: 'En raison de plusieurs tentatives de connexion infructueuses, veuillez attendre <span class="text-link font-semibold">{time}</span> minutes avant de réessayer.',
  login_error_enforce_sso_login_msg: 'Vos identifiants de connexion sont incorrects ou vous devez vous connecter via SSO. Veuillez réessayer.',
  login_onetime_access_email_sent_msg_1: 'Nous avons envoyé un lien de connexion temporaire.',
  login_onetime_access_email_sent_msg_2: 'Veuillez vérifier votre boîte de réception à',
  login_statement: 'En vous connectant, vous reconnaissez avoir lu et compris, et acceptez nos {terms} et notre {privacy_policy}.',
  login_type_msg: "Désactivez toutes les options pour permettre l'accès par courriel et mot de passe sans A2F.",
  login_type_otp: 'Accès unique',
  login_type_sso: 'Authentification unique (SSO)',
  login_type_upw: 'Courriel et mot de passe',
  login_type: 'Type de connexion',
  logo: 'Logo',
  logout: 'Se déconnecter',
  low: 'Faible',
  lowest_first: 'Le plus bas en premier',
  made_hiring_manager: 'Devenu responsable du recrutement',
  made_interviewer: 'Devenu intervieweur',
  made_recruiter: 'Devenu recruteur',
  main_content: 'Contenu principal',
  make_default: 'Définir par défaut',
  make_equivalent: 'Rendre équivalent',
  make_longer: 'Rendre plus long',
  make_shorter: 'Rendre plus court',
  make_subheading: 'Transformer en sous-titre',
  make_this_range_recommendation_detail: "Les emplois peuvent toujours être publiés même si le nombre d'éléments est en dehors de la plage idéale",
  make_this_range_recommendation: 'Faites de cette plage une recommandation',
  make_this_range_requirement_detail: "Les emplois ne peuvent pas être publiés si le nombre d'éléments est en dehors de la plage idéale",
  make_this_range_requirement: 'Faites de cette plage une exigence',
  make_this_the_target: 'Faire de ceci l’objectif',
  manage_candidates: 'Gérer les candidats',
  manage_communications: 'Gérer les communications',
  manage_contents: 'Ajouter du contenu',
  manage_departments: 'Gérer les départements',
  manage_display: "Gérer l'affichage",
  manage_groups: 'Gérer les groupes',
  manage_interviewers: 'Gérer les intervieweurs',
  manage_jobs_apply_updates: "Vous avez besoin de l'autorisation d'administrateur de contenu pour appliquer certaines mises à jour.",
  manage_libraries_apply_updates: "Vous avez besoin de l'autorisation d'administrateur de contenu pour appliquer ces mises à jour.",
  manage_notification_recipients: 'Gérer les destinataires des notifications',
  manage_participants: 'Ajouter des participants',
  manage_properties_description: "Gérer les propriétés affichées dans la section des informations sur l'emploi et ce qui est affiché dans les exports PDF/Word.",
  manage_properties: 'Gérer les propriétés',
  manage_result_reviewers: 'Gérer les évaluateurs des résultats',
  manage_review_administrators: 'Gérer les administrateurs des examens',
  manage_reviews: 'Gérer les examens',
  manage_rounds: 'Gérer les tours',
  manage_setup: 'Gérer la configuration',
  manage_templates: 'Gérer les gabarits',
  manage_topics: 'Gérer les sujets',
  manage_user_groups: "Gérer les groupes d'utilisateurs",
  manage_your_quinto_account: 'Gérez votre compte Quinto.',
  manage: 'Gérer',
  manager_assigned: 'Gestionnaire attribué',
  manager_completion: 'Achèvement du gestionnaire',
  manager_email: 'Courriel du gestionnaire',
  manager_or_hr_capability_match_percentage_of_job: "{subject} remplit ou dépasse {pourcentage} des exigences d'un {emploi}.",
  manager_review_communicated_deadline: "C'est la date limite donnée aux gestionnaires, mais ils peuvent toujours fournir leurs évaluations après cette date.",
  manager_review_description: 'Les gestionnaires évaluent leurs subordonnés directs.',
  manager_review_feedback_visibility_description: "Les gestionnaires pourront voir la rétroaction et/ou les réflexions personnelles fournies par d'autres lorsqu'ils effectuent leur propre examen.",
  manager_review_launch_date: 'Les gestionnaires recevront une notification à cette date leur demandant de fournir leurs évaluations.',
  manager_review_subtitle: 'Fournir l’évaluation',
  manager_review: 'Examen du gestionnaire',
  manager_reviews: 'Examen du gestionnaire | Examens des gestionnaires',
  manager_scores: 'Notes des gestionnaires',
  manager_scoring_calculations: "Les notes {between} indiquent les gestionnaires qui, en moyenne, ont évalué les employés comme ayant satisfait {atAboveBelow} aux exigences de l'emploi.",
  manager_scoring_details: 'Pour des détails sur la manière dont les notes sont calculées, {seeHere}.',
  manager_scoring_help: 'Ce graphique montre la note moyenne soumise par chaque responsable dans l’évaluation. Cela aide à identifier les responsables qui pourraient être relativement cléments ou sévères lors de l’attribution des notations.',
  manager_scoring: 'Évaluation des gestionnaires',
  manager: 'Gestionnaire',
  managers: 'Gestionnaire | Gestionnaires',
  mandatory_comments: 'Commentaires obligatoires',
  mandatory_questions: 'Questions obligatoires',
  manifest_not_found_msg: 'Désolé, quelque chose ne va pas avec votre sous-domaine.',
  manifest_not_found: 'Manifeste introuvable.',
  manual_login: 'Connexion manuelle',
  manual: 'Manuel',
  map_data_import_file_quinto: "Mapper les données de votre fichier d'importation aux données dans Quinto",
  map_data_in_file: 'Nous devons mapper les données dans ce fichier.',
  map_data_using_positions: 'Mapper ces données si vous utilisez des postes.',
  map_data: 'Mapper les données',
  mar: 'Mars',
  mark_all_as_read_all: 'Marquer tout comme lu',
  mark_as_done: 'Marquer comme fait',
  mark_as_read: 'Mark as read',
  mark_as_reviewed_by: 'Marqué comme examiné par {name}',
  mark_as_reviewed_confirmation_message: "Cela marquera l'emploi comme révisé et fixera la prochaine date de révision à {date}. ",
  mark_as_reviewed_confirmation_title: 'Marquer comme révisé',
  mark_as_unread: 'Marquer comme non lu',
  matches: 'Correspondances',
  max: 'Max.',
  may: 'Mai',
  mediocre: 'Médiocre',
  meet_or_exceed_percentage_of_job: "Vous remplissez ou dépassez <span class=\"text-orange-2\">{percentage}%</span> des exigences d'un {job}",
  meet_requirements: 'Répond aux exigences',
  meeting_types: 'Type de réunion',
  mention_instructions: "Taper {'@'} pour mentionner et notifier quelqu'un.",
  mentions: 'Mention | Mentions',
  menu: 'Menu',
  messages: 'Message',
  microsoft: 'Microsoft',
  min: 'Min.',
  mobile_number: 'Numéro de téléphone mobile',
  modal_change_job_x_text_1: 'Cette évaluation utilisera le contenu du nouvel emploi.',
  modal_change_job_x_text_2: "Cette évaluation ne sera plus automatiquement mise à jour si des changements sont apportés à l'enregistrement de l'utilisateur {nom du sujet}.",
  modal_change_job_x_title: "Si vous modifiez l'emploi de {name} :",
  modal_change_manager_x_text_1: 'Seuls les gestionnaires sélectionnés ici évalueront {subjectName}.',
  modal_change_manager_x_text_2: 'Les participants évaluant {subjectName1} ne seront plus mis à jour automatiquement si des modifications sont apportées au dossier utilisateur de {subjectName2}.',
  modal_change_manger_x_title: 'Si vous modifiez le gestionnaire de {name}',
  modules: 'Module',
  more_than_12_months: 'Plus de 12 mois',
  more: 'Plus',
  most_senior_first: 'Le plus ancien en premier',
  move_additional_information: "Déplacer @.lower:{'additional_information'}",
  move_certifications: "Déplacer @.lower:{'certifications'}",
  move_competencies: "Déplacer @.lower:{'competencies'}",
  move_educations: "Déplacer @.lower:{'educations'}",
  move_experiences: "Déplacer @.lower:{'experiences'}",
  move_forward: "Passer à l'étape suivante",
  move_interview_templates: "Déplacer le gabarit d'entrevue | Déplacer les gabarits d'entrevue",
  move_items_to_another_group: 'Déplacer les éléments vers un autre groupe',
  move_jobs_message: "Si vous avez défini des propriétaires de groupes d'emplois ou des @.lower:{'plural_competencies'}, ils peuvent être mis à jour lorsque vous déplacez des emplois vers un nouveau groupe.",
  move_jobs_sub_message_1: "Les niveaux de compétences liés à la famille d'emplois seront supprimés",
  move_jobs_sub_message_2: "Les compétences liées à la famille d'emplois peuvent changer",
  move_jobs_sub_message_3: "Les propriétaires de groupe d'emplois peuvent changer",
  move_jobs: "Déplacer l'emploi | Déplacer les emplois",
  move_languages: "Déplacer @.lower:{'languages'}",
  move_move_certifications: "Déplacer @.lower:{'certifications'}",
  move_responsibilities: "Déplacer @.lower:{'responsibilities'}",
  move_responsibility: "Déplacer @.lower:{'responsibilities'}",
  move_skill: "Déplacer @.lower:{'skills'}",
  move_to_next_rounds: 'Passer au tour suivant',
  move_to_this_round: 'Déplacer à ce tour',
  move_types: 'Déplacer le type',
  move_working_conditions: "Déplacer @.lower:{'working_conditions'}",
  move: 'Déplacer',
  moved_forward: 'Avancé',
  moved_laterally: 'Déplacé latéralement',
  multi_select: 'Multisélection',
  my_competencies_and_skills: 'Mes compétences et aptitudes',
  my_job_match: 'Ma correspondance d’emploi',
  my_jobs: 'Mes emplois',
  my_profile_tooltip: "Préférences et détails de l'emploi",
  my_profile: 'Mon profil',
  my_reviews: 'Mes examens',
  my_team_empty_text_1: "Il n'y a encore personne ici.",
  my_team_empty_text_2: 'Nous travaillons fort.',
  my_team_empty_text_3: 'Veuillez revenir plus tard.',
  my_team: 'Mon équipe',
  na: 'N/A',
  name_teams: "L'équipe de {name}",
  name: 'Nom',
  needs_more_requirements: "Besoin de plus d'@.lower:{'plural_requirement'} de {library_type} ",
  needs_more: 'Besoin de plus de {library_type}',
  needs_work: 'Besoin de travail',
  never_published: 'Jamais publié',
  never: 'Jamais',
  new_department: 'Changer de département',
  new_level: 'Nouveau niveau',
  new_password: 'Nouveau mot de passe',
  new_user_status: 'Statut du nouvel utilisateur',
  new: 'Nouveau',
  next_30_days: '30 prochains jours',
  next_month: 'Mois prochain',
  next_week: 'Semaine prochaine',
  next: 'Suivant',
  no_assigned_job_tooltip: "{subjectName} n'a pas d'emploi assigné.",
  no_ats_integration: "Pas d'intégration ATS",
  no_candidates_yet: 'Aucun candidat pour le moment',
  no_data_deleted_associated_data_not_visible: 'Aucune donnée ne sera supprimée, mais les données associées ne seront pas visibles sur chaque emploi',
  no_date_set: 'Pas de date définie',
  no_details_to_show: 'Aucun détail à afficher',
  no_due_data_provided: "aucune date d'échéance n'a été fournie",
  no_due_date_has_been_provided: "aucune date d'échéance n'a été fournie. ",
  no_due_date_provided: "aucune date d'échéance fournie",
  no_follow_ups: 'Aucun suivi',
  no_gaps_msg: 'Boom ! Pas de lacunes ici.',
  no_guidelines_specified: "Aucune directive n'est spécifiée",
  no_hiring_team_member: "Aucun membre de l'équipe de recrutement",
  no_incumbent_jobs: "Cet emploi n'a actuellement aucun titulaire. | Ces emplois n'ont actuellement aucun titulaire.",
  no_interview_introduction: "Aucune introduction à l'entrevue n'a été définie.",
  no_interview_questions: "Pas de questions d'entrevue",
  no_interviewers: 'Aucun intervieweur',
  no_job_assigned: 'Aucun emploi assigné',
  no_jobs_have_been_published: "Aucun emploi n'a encore été publié.",
  no_jobs_published: "L'emploi n'a pas été publié car il ne répondait pas aux exigences de cohérence. | Aucun des emplois n'a été publié car ils ne répondaient pas aux exigences de cohérence.",
  no_levels: 'Pas de niveau',
  no_location_msg: "Aucun lieu n'a été défini. ",
  no_locations: 'Pas de lieu',
  no_manager_tooltip: "{subjectName} n'a pas de gestionnaire assigné.",
  no_manager: 'Pas de gestionnaire',
  no_match_found: 'Désolé! Aucune correspondance trouvée.',
  no_matches_yet: 'Aucune correspondance pour l’instant.',
  no_one_yet_msg: "Il n'y a personne ici pour le moment.",
  no_pending_job_updates_message: "Tout est à jour! Il n'y a aucune mise à jour en attente.",
  no_position_defined: 'Aucun poste défini',
  no_position: 'Aucun poste',
  no_positions: 'Pas de poste',
  no_questions_in_guide: "Il n'y a pas de questions dans ce gabarit",
  no_ranges: 'Pas de fourchette',
  no_results_yet: "Il n'y a pas encore de résultats.",
  no_reviews_available_yet: 'Aucun examen disponible pour le moment.',
  no_scorecards: "Pas de fiche d'évaluation",
  no_search_results: 'Aucun résultat de recherche',
  no_signoffs_for_this_job: "Il n'y a aucune approbation pour cet emploi.",
  no_trusted_devices_msg: "Vous n'avez aucun appareil de confiance. Vous pouvez marquer un appareil comme étant de confiance lorsque vous vous connectez. ",
  no_types: 'Pas de type',
  no_updates_were_found: 'Aucune mise à jour trouvée.',
  no: 'Non',
  none: 'Aucun',
  not_added: 'Non ajouté',
  not_answered: 'Non répondu',
  not_applicable: 'Non applicable',
  not_available_with_2fa: 'Non disponible avec A2F',
  not_available_with_otp: "Non disponible avec l'accès unique",
  not_available_with_sso: 'Non disponible avec SSO',
  not_completed: 'Non complété',
  not_flagged: 'Non marqué',
  not_invited: 'Non invité',
  not_moved_to_this_round: 'Non déplacé à ce tour',
  not_picked: 'Non sélectionné',
  not_selected_by_others: "Non sélectionné par d'autres",
  not_starred: 'Non étoilé ',
  not_translated: "Note : Tous les contenus n'ont pas été traduits.",
  note_changes_are_not_retroactive: "Note : Les changements ne sont pas rétroactifs. Ils n'affectent que les emplois approuvés à l'avenir.",
  note_you_can_override_default: "Note : Vous pouvez outrepasser les paramètres par défaut lors de l'envoi de demandes.",
  notes: 'Note | Notes',
  nothing_to_see_here: 'Rien à voir ici',
  nothing_to_show: 'Rien à afficher',
  nothing: 'Rien',
  notification_AllScorecardsSubmitted: "Tous les intervieweurs ont soumis leur fiche d'évaluation pour un candidat",
  notification_closed_a_job_to_feedback: '{name} a fermé un emploi aux retours',
  notification_empty_message: 'Vous êtes à jour',
  notification_InterviewCandidacyComment: '{nom} a commenté sur un candidat',
  notification_job_collaboration: "Collaboration sur l'emploi : {job}",
  notification_job_incumbents: 'Emploi : {job}',
  notification_JobArchived: '{name} a archivé votre emploi',
  notification_JobComment: '{name} a commenté sur un emploi',
  notification_JobCommentMention: '{name} vous a mentionné dans un commentaire',
  notification_JobContributorTypeChange: '{name} a modifié votre rôle',
  notification_JobDeleted: '{name} a supprimé votre emploi',
  notification_JobFeedbackProvidedToContributorInThread: '{name} a répondu à vos retours',
  notification_JobFeedbackProvidedToOwner: '{name}a fourni des retours sur un emploi',
  notification_JobPublished: '{name} a publié un emploi',
  notification_JobPublishedToIncumbent: '{name} a publié votre emploi',
  notification_re_opened_a_job_for_feedback: '{name} a rouvert un emploi aux retours',
  notification_recipients: 'destinataire de notification | destinataires de notification',
  notifications: 'Notifications',
  notify_incumbents: 'Notifier les titulaires',
  nov: 'Nov',
  nudge_interviewers: 'Inciter les intervieweurs',
  nudge: 'Inciter',
  nudged: 'Incité',
  oct: 'Oct',
  of: 'De',
  off: 'Désactivé',
  ok: 'Ok',
  okta: 'Okta',
  on_leave: 'En congé',
  on: 'Activé',
  onboarding: 'Onboarding',
  one_lowercase_character: 'Un caractère minuscule',
  one_number_or_special_character: 'Un chiffre ou un caractère spécial',
  one_number: 'Un chiffre',
  one_question_per_page: "Une question par page (avec de l'espace pour les notes)",
  one_special_character: 'Un caractère spécial',
  one_time_access_description: "Permettre aux utilisateurs d'accéder à Quinto avec des liens uniques sécurisés au lieu d'un mot de passe.",
  one_time_access_link_already_sent: 'Un lien de connexion temporaire a déjà été envoyé. Veuillez vérifier votre boîte de réception.',
  one_time_access_link_expired: "Désolé, ce lien n'est plus valide. Veuillez réessayer.",
  one_time_access: 'Accès unique',
  one_uppercase_character: 'Un caractère majuscule',
  only_available_for_login_type_upw: 'Disponible uniquement pour le type de connexion par courriel et mot de passe',
  only_draft_will_be_published: 'Seuls les éléments en brouillon seront publiés.',
  only_published_will_be_archived: 'Seuls les éléments publiés seront archivés.',
  only_users_with_position_action: "Cette action s'appliquera uniquement aux utilisateurs qui ont un poste.",
  onscreen_instructions_for_managers: "Instructions à l'écran pour les gestionnaires",
  onscreen_instructions_for_subjects: "Instructions à l'écran pour les sujets",
  open_feedback_message: 'Les éditeurs et les relecteurs pourront apporter leurs contributions.',
  open_feedback: 'Ouvrir les retours',
  open_scorecards: "Ouvrir la fiche d'évaluation",
  open: 'Ouvrir',
  opened_for_input: 'Ouvert aux contributions',
  opening_candidates: 'Openings - Candidates',
  openings_compare: 'Opening - Compare',
  openings: 'Ouverture | Ouvertures',
  optional_comments: 'Commentaires optionnels',
  optional_questions: 'Questions optionnelles',
  optional: 'Optionnel',
  options: 'Option | Options',
  or_drag_and_drop: 'ou glisser-déposer',
  or_login_with_email: 'Ou connectez-vous par courriel',
  or: 'or',
  org_chart_empty_message_sub: "Vous n'avez pas de poste attribué.\n Utilisez la recherche ci-dessus pour trouver d'autres postes.",
  org_chart_empty_message: 'Rien à afficher.',
  org_chart: 'Organigramme',
  organization: 'Organisation',
  other: 'Autre',
  others: 'Autre | Autres',
  outcomes: 'Résultat',
  outstanding: 'Exceptionnel',
  overall_feedback_question_one: "Recommandez-vous de passer à l'étape suivante avec ce candidat?",
  overall_feedback_question_two: 'Est-ce que ce candidat convient bien au poste?',
  overall_feedback: 'Retour global',
  overall: 'Global',
  overview: 'Aperçu',
  owners_description: "Les propriétaires peuvent faire tout ce que les éditeurs peuvent faire. Ils peuvent également inviter des collaborateurs, gérer les paramètres d'emploi et publier l'emploi.",
  owners: 'Propriétaire | Propriétaires',
  page_content: 'Contenu de la page',
  page_not_authorized: 'Page non autorisée.',
  page_not_found: 'Page non trouvée.',
  page_refresh: 'Veuillez rafraîchir la page',
  page_title: 'Titre de la page',
  pages: 'Pages',
  parent_department: 'Département parent',
  parent_group: 'Groupe parent',
  participants_completion: 'Achèvement du participant | Achèvements des participants',
  participants: 'Participants',
  password_change_msg: 'Un courriel a été envoyé à {email} si cette adresse correspond à votre compte.',
  password_leaked: 'Le mot de passe que vous avez saisi apparaît dans une fuite de données. Veuillez choisir un autre mot de passe.',
  password_not_allowed_characters: 'Les symboles inférieur à et supérieur à ensemble (<>) ne sont pas autorisés.',
  password_previously_used: 'Mot de passe déjà utilisé. Veuillez choisir un autre mot de passe.',
  password_reset_sent: 'Réinitialisation de mot de passe envoyée | Réinitialisations de mot de passe envoyées',
  password: 'Mot de passe',
  paths: 'Parcours',
  pdf: 'PDF',
  peer: 'Pair',
  pending_discussion: 'Discussion en attente',
  pending_job_updates_descripton: 'Lorsque vous êtes prêt, appliquez ces changements pour mettre à jour les emplois publiés qui ne sont pas en cours de modification.',
  pending_job_updates: "Mises à jour d'emploi en attente",
  pending_results: 'Résultats en attente',
  pending: 'En attente',
  people_activities: 'Activités du personnel',
  people_manager: 'Gestionnaire de personnel',
  people: 'Personnel',
  per_competency: 'Par compétence',
  per_page: 'par page',
  percent_complete: 'Pourcentage complété : {percent}%',
  performance_scores_calculations: "Les notes {between} indiquent les employés évalués comme ayant satisfait {atAboveBelow} aux exigences de l'emploi.",
  performance_scores_details: 'Pour des détails sur la manière dont les notes sont calculées, {seeHere}.',
  performance_scores_help: "Ce graphique montre la note de performance pour chaque sujet dans l'examen. Il aide à identifier la façon dont les employés se comparent aux exigences définies dans les descriptions de poste.",
  performance_scores: 'Note de performance | Notes de performance',
  performed_by: 'Effectué par',
  permision_sets_table_description: "Définir des ensembles de permissions pour contrôler l'accès de votre organisation à Quinto.",
  permission_set_access_org_chart_text: "Permettre aux utilisateurs de visualiser l'organigramme. ",
  permission_set_access_org_chart_title: "Accéder à l'organigramme",
  permission_set_can_not_delete_message: "Cet ensemble de permissions ne peut pas être supprimé car il s'agit de l'ensemble par défaut.",
  permission_set_group_access_org: 'Organigramme',
  permission_set_group_access_reviews: 'Examens',
  permission_set_group_people_administration_account: 'Compte',
  permission_set_group_people_administration_analytics: 'Analytique',
  permission_set_group_people_administration_features: 'Fonctionnalités',
  permission_set_group_people_administration_users: 'Utilisateurs',
  permission_set_group_people_manager: 'Gestionnaire de personnes',
  permission_set_groups_access_analytics_text: "Permettre aux utilisateurs de consulter les analyses d'emploi.",
  permission_set_groups_access_analytics_title: 'Accéder aux analyses',
  permission_set_groups_access_audit_logs_text: "Permettre aux utilisateurs d'accéder aux journaux d'audit.",
  permission_set_groups_access_audit_logs_title: "Accéder aux journaux d'audit.",
  permission_set_groups_access_interview_templates_text: "Permettre aux utilisateurs de voir et de télécharger les gabarits d'entrevue.",
  permission_set_groups_access_interview_templatess_title: "Accéder aux gabarits d'entrevue",
  permission_set_groups_access_jobs_text: "Permettre aux utilisateurs de consulter les descriptions d'emplois publiées dans le module Emplois.",
  permission_set_groups_access_jobs_title: 'Accéder aux emplois',
  permission_set_groups_access_libraries_text: 'Permettre aux utilisateurs de consulter les éléments de la bibliothèque publiés dans le module Bibliothèques.',
  permission_set_groups_access_libraries_title: 'Accéder aux bibliothèques',
  permission_set_groups_access_reviews_text: 'Permettre aux utilisateurs de participer aux examens.',
  permission_set_groups_access_reviews_title: 'Accéder aux examens',
  permission_set_groups_access_surveys_text: 'Permettre aux utilisateurs de participer aux sondages.',
  permission_set_groups_access_surveys_title: 'Accéder aux sondages',
  permission_set_groups_account_administration: 'Administration de compte',
  permission_set_groups_account_administrator_text: 'Permettre aux utilisateurs de configurer les paramètres de leur compte et le tableau de bord.',
  permission_set_groups_account_administrator_title: 'Gérer le compte',
  permission_set_groups_communications_administrator_text: "Permettre aux utilisateurs de modifier le contenu du système, tel que les introductions de documents et la clause de décharge de responsabilité pour l'approbation des emplois",
  permission_set_groups_communications_administrator_title: 'Gérer les communications',
  permission_set_groups_content_administration: 'Administration du contenu',
  permission_set_groups_content_administrator_text: "Permettre aux utilisateurs de gérer l'ensemble du contenu de votre site, y compris l'architecture, les paramètres par défaut du contenu, les propriétés des emplois, les gabarits d'emploi et les échelles.",
  permission_set_groups_content_administrator_title: 'Administrateur de contenu',
  permission_set_groups_delete_published_jobs_text: 'Permettre aux utilisateurs de supprimer les emplois publiés.',
  permission_set_groups_delete_published_jobs_title: 'Supprimer les emplois publiés',
  permission_set_groups_delete_published_library_items_text: 'Permettre aux utilisateurs de supprimer les éléments publiés de la bibliothèque',
  permission_set_groups_delete_published_library_items_title: 'Supprimer les éléments publiés de la bibliothèque',
  permission_set_groups_development_administration: 'Administration du développement',
  permission_set_groups_export_jobs_text: "Permettre aux utilisateurs d'exporter les emplois vers Word et PDF.",
  permission_set_groups_export_jobs_title: 'Exporter les emplois',
  permission_set_groups_export_libraries_text: "Permettre aux utilisateurs d'exporter les compétences vers Word et PDF.",
  permission_set_groups_export_libraries_title: 'Exporter les compétences',
  permission_set_groups_jobs_collaboration_text: "Permettre aux utilisateurs d'être invités à collaborer sur des emplois en tant qu'éditeur ou relecteur.",
  permission_set_groups_jobs_collaboration_title: 'Collaborer sur des emplois',
  permission_set_groups_manage_interview_guides_text: 'Permettre aux utilisateurs de gérer les entrevues.',
  permission_set_groups_manage_interview_guides_title: "Gérer les guides d'entrevue.",
  permission_set_groups_manage_interview_questions_text: "Permettre aux utilisateurs de créer et de gérer la banque de questions d'entrevue.",
  permission_set_groups_manage_interview_questions_title: "Gérer les questions d'entrevue",
  permission_set_groups_manage_job_validations_text: "Permettre aux utilisateurs d'envoyer des emplois à d'autres utilisateurs pour leurs contributions et leurs retours. (Accorde l'accès pour voir les validations d'emploi dans les activités de contenu)",
  permission_set_groups_manage_job_validations_title: "Gérer les validations d'emploi",
  permission_set_groups_manage_jobs_text: "Permettre aux utilisateurs de créer et de gérer les descriptions d'emploi.",
  permission_set_groups_manage_jobs_title: 'Gérer les emplois',
  permission_set_groups_manage_libraries_text: 'Permettre aux utilisateurs de créer et de gérer les éléments de la bibliothèque.',
  permission_set_groups_manage_libraries_title: 'Gérer les bibliothèques',
  permission_set_groups_manage_locations_text: 'Permettre aux utilisateurs de gérer les lieux des utilisateurs et des emplois',
  permission_set_groups_manage_locations_title: 'Gérer les lieux',
  permission_set_groups_manage_my_account_text: 'Permettre aux utilisateurs de gérer leurs propres paramètres de compte.',
  permission_set_groups_manage_my_account_title: 'Gérer mon compte',
  permission_set_groups_manage_people_text: "Permettre aux utilisateurs d'accéder à la page Mon Équipe pour voir les données relatives à leurs subordonnés directs et indirects.",
  permission_set_groups_manage_people_title: "Gestionnaire d'équipe",
  permission_set_groups_manage_reviews_text: 'Permettre aux utilisateurs de créer et gérer des examens.',
  permission_set_groups_manage_reviews_title: 'Gérer les examens',
  permission_set_groups_manage_signoffs_text: 'Permettre aux utilisateurs de gérer les approbations des emplois.',
  permission_set_groups_manage_signoffs_title: 'Gérer les approbations',
  permission_set_groups_manage_surveys_text: 'Permettre aux utilisateurs de créer et gérer des sondages.',
  permission_set_groups_manage_surveys_title: 'Gérer les sondages',
  permission_set_groups_organizational_development: 'Développement organisationnel',
  permission_set_groups_own_jobs_text: 'Permettre aux utilisateurs de collaborer sur des emplois en tant que propriétaire, éditeur ou relecteur.',
  permission_set_groups_own_jobs_title: 'Être propriétaire des emplois',
  permission_set_groups_people_access_current_job_match_text: "Permettre aux utilisateurs de voir leurs données actuelles de correspondance d'emploi.",
  permission_set_groups_people_access_current_job_match_title: "Accéder à la correspondance d'emploi",
  permission_set_groups_people_access_profile_text: "Permettre aux utilisateurs d'accéder à leur profil pour voir leurs capacités.",
  permission_set_groups_people_access_profile_title: 'Accéder au profil',
  permission_set_groups_permissions_administrator_text: "Permettre aux utilisateurs d'ajouter ou de modifier les ensembles de permissions disponibles sur votre site.",
  permission_set_groups_permissions_administrator_title: 'Administrateur des permissions',
  permission_set_groups_personal_development: 'Développement personnel',
  permission_set_groups_personify_text: "Permettre aux utilisateurs de se connecter en tant qu'autres utilisateurs (à des fins de support).",
  permission_set_groups_personify_title: "Se connecter en tant qu'autres utilisateurs",
  permission_set_groups_push_job_post_text: "Permettre aux utilisateurs d'envoyer des annonces d'emploi à un ATS sélectionné via une API.",
  permission_set_groups_push_job_post_title: "Envoyer les annonces d'emploi",
  permission_set_groups_team_development: "Développement d'équipe",
  permission_set_groups_users_administration: 'Administration des utilisateurs',
  permission_set_groups_users_administrator_text: "Permettre aux utilisateurs d'ajouter ou de modifier d'autres utilisateurs, postes et départements, ainsi que d'anonymiser les utilisateurs supprimés. ",
  permission_set_groups_users_administrator_title: 'Administrateur des utilisateurs',
  permission_set_groups_view_help_text: "Permettre aux utilisateurs de voir le widget d'aide. ",
  permission_set_groups_view_help_title: "Voir l'aide",
  permission_set_updated_by: 'Mis à jour par {name}',
  permission_sets_assigned: 'Ensembles de permissions attribués',
  permission_sets_created: 'Ensemble de permissions créé',
  permission_sets_deleted: 'Ensemble de permissions supprimé',
  permission_sets_unassigned: 'Ensembles de permissions retirés',
  permission_sets_updated: 'Ensemble de permissions mis à jour',
  permission_sets: 'Ensembles de permissions',
  personal_account: 'Compte personnel',
  personal_weblink: 'Lien personnel',
  personal: 'Personnel',
  personified: 'Personnalisé',
  phone_number: 'Numéro de téléphone',
  phone: 'Téléphone',
  pick_from_here: 'Choisir parmi ici',
  pipeline: 'Banque de candidatures',
  placeholders: 'Espace réservé | Espaces réservés',
  please_select_one: 'Veuillez sélectionner au moins une option',
  please_use_csv_or_xlsx: 'Veuillez utiliser .csv ou .xlsx',
  please_use_future_dates: 'Veuillez utiliser des dates futures',
  plural_additional_information: 'Information supplémentaire',
  plural_certifications: 'Certifications',
  plural_competencies: 'Compétences',
  plural_educations: 'Éducation',
  plural_experiences: 'Expérience',
  plural_interview_questions: "Questions d'entrevue",
  plural_jobs: 'Emplois',
  plural_languages: 'Langues',
  plural_learning_resources: "Ressources d'apprentissage",
  plural_requirement: 'Exigences',
  plural_responsibilities: 'Responsabilités',
  plural_skills: 'Aptitudes',
  plural_working_conditions: 'Conditions de travail',
  plural: 'Pluriel',
  png_jpg_svg_up_to_10mb: "PNG, JPG, SVG jusqu'à 10 Mo",
  png_jpg_svg_up_to_5mb: "PNG, JPG, SVG jusqu'à 5 Mo",
  position_description: "Les entrées ci-dessous montrent l'historique des postes pour {name}.",
  position_details: 'Détails du poste',
  position_exception: 'Exception de poste',
  position_history: 'Historique des postes',
  position_id: 'ID de poste',
  position_updates_effective_date_msg: 'ID de poste',
  position_without_jobs: 'Postes sans emplois',
  positions_deleted: 'Poste supprimé | Postes supprimés',
  positions_will_be_created: '{count} poste sera créé | {count} postes seront créés',
  positions: 'Poste | Postes',
  post_template_can_not_delete: "Ce gabarit ne peut pas être supprimé car il est en cours d'utilisation.",
  post_template_can_not_turned_off: "Ce gabarit ne peut pas être désactivé car il est en cours d'utilisation.",
  preferences: 'Préférences',
  preview: 'Aperçu',
  previous_published_version: 'Version précédemment publiée',
  previous_published: 'Précédent publié',
  previous: 'Précédent',
  privacy_policy: 'Politique de confidentialité',
  procedure: 'Procédure',
  process_job_updates_standby_message: "En attente… nous traitons les mises à jour d'emploi.",
  product_name: 'Nom du produit',
  profile_email_notifications: 'Profil - Notifications par courriel',
  profile_integrations: 'Intégrations de profil',
  profile_job_description: "Description d'emploi",
  profile_job_signoffs: "Profil - Approbations d'emploi",
  profile_password_reset: 'Nous avons envoyé un courriel à {email} avec un lien pour réinitialiser votre mot de passe.',
  profile_picture: 'Photo de profil',
  profile_preferences: 'Profil - Préférences',
  profile_settings: 'Profil - Paramètres',
  profile_signoffs_description: "Voir l'historique des approbations de votre emploi.",
  profile: 'Profil',
  progress_updated: 'Progression mise à jour',
  progress: 'Progression',
  promoted: 'Promu',
  properties: 'Propriété | Propriétés',
  property_use_warning: "Pour utiliser cette fonctionnalité dans les descriptions de poste, rendez-vous dans la section \"Informations\" d'un gabarit de poste et activez-la.",
  provide_a_process_for_notifying_incumbents: 'Fournit un processus pour informer les titulaires.',
  provide_feedback: 'Fournir des retours',
  providers: 'Fournisseur',
  publish_additional_information_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_additional_information: "Publier @.lower:{'additional_information'}?",
  publish_certifications_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_certifications: "Publier @.lower:{'certifications'}?",
  publish_competencies_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_competencies: "Publier @.lower:{'competencies'}?",
  publish_competency: "Publier @.lower:{'competencies'}?",
  publish_educations_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_educations: "Publier @.lower:{'educations'}?",
  publish_experiences_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_experiences: "Publier @.lower:{'experiences'}?",
  publish_jobs_msg: 'Cet emploi sera publié. | Ces emplois seront publiés.',
  publish_jobs: "Publier l'emploi | Publier les emplois",
  publish_languages_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_languages: "Publier @.lower:{'languages'}?",
  publish_responsibilities_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_responsibilities: "Publier @.lower:{'responsibilities'}?",
  publish_responsibility_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_responsibility: "Publier @.lower:{'responsibilities'}?",
  publish_skill_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_skill: "Publier @.lower:{'skills'}?",
  publish_working_conditions_msg: 'Cet élément sera publié. | Ces éléments seront publiés.',
  publish_working_conditions: "Publier @.lower:{'working_conditions'}?",
  publish: 'Publier',
  published_by_name: 'Publié par {name}',
  published_date: 'Publié le : {date}',
  published_interview_guide: "Gabarit d'entrevue publié",
  published_job_rate_tooltip: "Le pourcentage d'emplois actuellement publiés.",
  published_job_rate: 'Taux de publication',
  published_job_thanks_msg: 'Merci pour votre temps et vos contributions!',
  published_job: 'Emploi publié',
  published_jobs_x: 'Emplois publiés : {count}',
  published_plus_draft: 'Publié + Brouillon',
  published_with_date: 'Publié : {date}',
  published_with_draft_copy: 'Publié avec un brouillon',
  published: 'Publié',
  question_not_translated: "Cette question n'a pas été traduite.",
  question_not_used_on_job: "Cette question n'a pas été utilisée pour cet emploi. ",
  question_number: 'Question {number}',
  question_types: 'Type de question',
  questionnaire_results: 'Résultats du questionnaire',
  questionnaire_submitted_banner_2: "Il s'agit d'une vue en lecture seule de l'évaluation de {name}.",
  questionnaire_submitted_banner: 'Votre évaluation a été soumise et ne peut pas être mise à jour.',
  questionnaires: 'Questionnaire | Questionnaires',
  questions_added_from_template: 'Questions ajoutées depuis le gabarit {template}',
  questions_are_x: 'Les questions sont {x}',
  questions_content: 'Questions/Contenu',
  questions: 'Question | Questions',
  quick_start: 'Démarrage rapide',
  quinto_admin_home: 'Quinto - Accueil administrateur',
  quinto_data: 'Données Quinto',
  quinto_home: 'Quinto - Accueil',
  quinto_login: 'Quinto - Connexion',
  quinto_version: 'Version Quinto',
  ranges: 'Fourchette',
  rated_by_x: 'Évalué par {name}',
  rated_by_you: 'Évalué par vous',
  rating_factors: "Facteurs d'évaluation",
  rating_help: "Aide à l'évaluation",
  rating_na_option_excluded: 'Option N/A exclue',
  rating_na_option_included: 'Option N/A incluse',
  rating_scale_info: 'Informations sur l’échelle de notation',
  ratings_by: 'Notations par',
  ratings_distribution: 'Répartition des notations',
  ratings_scale: "Échelle d'évaluation | Échelles d'évaluation",
  ratings_submitted: 'Vos évaluations ont été soumises',
  ratings_visibility_description: "Permettre aux intervieweurs de voir toutes les évaluations après avoir soumis leur fiche d'évaluation.",
  ratings_visibility: 'Visibilité des évaluations',
  ratings_x_an_na_option: 'Notations {x} une option N/A. {learn_more_link}',
  ratings: 'Évaluation | Évaluations',
  raw_data: 'Données brutes',
  re_send: 'Renvoyer',
  reacted: 'Réagi',
  ready_to_acknowledge: 'Prêt à accuser réception',
  ready_to_share: 'Prêt à être partagé',
  reason_for_granting_signoff: "Raison de l'approbation",
  reason: 'Raison',
  reassigned_ownership_access: "Réattribution de l'accès à la propriété",
  recent_updates: 'Mises à jour récentes',
  recently_viewed: 'Récemment consulté',
  recipients: 'Destinataires',
  recommend: 'Recommander',
  recruiters: 'Recruteur',
  redo: 'Refaire Ctrl + Maj + Z',
  reference_contents: 'Contenu de référence',
  refresh_data: 'Actualiser les données',
  refresh_latest_version: 'Actualiser pour obtenir la dernière version.',
  refresh_table: 'Actualiser le tableau',
  regular_option_description: 'Inclut les autorisations pour un accès limité à Quinto.',
  regular_users: 'Utilisateur régulier | Utilisateurs réguliers',
  regular: 'Ordinaire',
  reinvited: 'Réinvité',
  reminded: 'Rappel',
  reminder_sent: 'Rappel envoyé',
  reminders_sent: 'Rappels envoyés',
  remove_all_permission_sets: 'Retirer tous les ensembles de permissions actuellement attribués',
  remove_candidates_confirmation_message: "{name} sera retiré de ce tour et toutes les données d'entrevue associées seront définitivement supprimées. Si une entrevue a été planifiée, nous informerons les intervieweurs qu'elle a été annulée.",
  remove_candidates_confirmation_title: 'Supprimer le candidat?',
  remove_candidates: 'Supprimer le candidat',
  remove_collaborator_description: "Le collaborateur ne pourra plus accéder à cet emploi (à moins qu'il ne dispose des permissions de gestion des emplois). Nous conserverons leurs contributions.",
  remove_collaborator: 'Retirer le collaborateur',
  remove_competencies: "Retirer @.lower:{'competencies'}?",
  remove_contents_confirmation_message: 'Toutes les données associées seront définitivement supprimées. Vous ne pouvez pas annuler cette action.',
  remove_contents: 'Retirer le contenu?',
  remove_core_competencies_confirmation_message: 'Cela sera retiré de chaque emploi sur votre site.',
  remove_department: 'Retirer le département',
  remove_direct_reports: 'Retirer le subordonné direct',
  remove_employee_dialog_msg: "Cet employé sera retiré de l'emploi. Il restera à son poste actuel.",
  remove_employee: "Retirer l'employé?",
  remove_flags: 'Retirer le marqueur',
  remove_highlight: 'Retirer le marquage',
  remove_incumbent: 'Retirer le titulaire',
  remove_interviewers_confirmation: "{name} sera retiré de cette entrevue. Toutes les évaluations qu'il a soumises pour cette entrevue seront définitivement supprimées.",
  remove_interviewers: "Supprimer l'intervieweur?",
  remove_item_to_library: 'Retirer {number} {library_type}',
  remove_job_family_competencies_confirmation_message: 'Cela sera retiré de tous les emplois dans ce groupe.',
  remove_job: "Retirer l'emploi",
  remove_jobs_position: 'Cet emploi sera retiré du poste.',
  remove_jobs: "Retirer l'emploi",
  remove_leadership_competencies_confirmation_message: 'Cela sera retiré de tous les emplois à ce niveau.',
  remove_logo_confirmation: 'Vous ne pouvez pas annuler cette action. Le logo sera définitivement supprimé.',
  remove_logo: 'Retirer le logo?',
  remove_manager: 'Retirer le gestionnaire',
  remove_min_max_item_to_library: 'Retirer {min} à {max} {library_type}',
  remove_opening_interviewers_confirmation: "{name} sera retiré de l'équipe de recrutement et de toutes les entrevues auxquelles il a été ajouté. Toutes les évaluations qu'il a soumises seront définitivement supprimées.",
  remove_owner_confirmation: "Cet utilisateur ne sera plus propriétaire du groupe d'emplois.",
  remove_owner: 'Retirer le propriétaire?',
  remove_participant_dialog_message: "Vous ne pouvez pas annuler cette action. La suppression de {participantName} de cette évaluation supprimera toutes les données qu'ils ont soumises.",
  remove_participant: 'Supprimer le participant',
  remove_participants_confirmation_message: 'Toutes les données fournies par le participant seront définitivement supprimées. Vous ne pouvez pas annuler cette action.',
  remove_participants: 'Retirer le participant?',
  remove_photo: 'Supprimer la photo',
  remove_position_msg: "Ce poste sera retiré de l'historique.",
  remove_positions: 'Retirer le poste',
  remove_rounds: 'Supprimer le tour',
  remove_stars: "Retirer l'étoile",
  remove_types_message: "Retirer tous les autres types d'emplois actuellement attribués.",
  remove: 'Retirer',
  removed_all_permission_sets: 'Ensemble de permissions retiré',
  removed_from_guides: 'Retiré du gabarit',
  removed_jobs: 'Emploi supprimé',
  removed_owners: 'Propriétaires retirés',
  removed_ownership_access: 'Accès à la propriété retiré',
  removed_reactions: 'Réaction retirée',
  removed_tags: 'Balise retirée',
  removed: 'Retiré',
  rename_communications: 'Renommer la communication | Renommer les communications',
  rename_groups: 'Renommer le groupe',
  rename_libraries: 'Renommer la bibliothèque',
  rename_library_refresh: 'Pour voir le nom de la bibliothèque mis à jour, veuillez rafraîchir votre navigateur après avoir sauvegardé.',
  rename_name: 'Renommer {name}',
  rename_permission_set: "Renommer l'ensemble de permissions",
  rename_position: 'Modifier le poste',
  rename_property_msg: "Cette propriété sera mise à jour dans tous les gabarits d'emploi et les emplois.",
  rename_property: 'Renommer la propriété',
  rename_surveys: 'Renommer le sondage',
  rename: 'Renommer',
  reopen_openings: "Rouvrir l'ouverture",
  reopen_participant_survey_confirmation_message: "Permettre à ce participant de mettre à jour ses réponses. Cela retirera ses réponses et commentaires des résultats jusqu'à ce qu'il complète de nouveau le sondage. Nous enverrons un courriel au participant pour lui faire savoir qu'il peut à nouveau accéder au sondage.",
  reopen_participant_survey: 'Réouvrir le sondage pour ce participant',
  reopen_scorecards: "Rouvrir la fiche d'évaluation",
  reopen_survey_confirmation_message: "Les participants qui n'ont pas encore terminé le sondage retrouveront l'accès. Nous enverrons un courriel à toute personne n'ayant pas encore fourni de contributions afin de les en informer.",
  reopen_survey: 'Réouvrir le sondage',
  reopen: 'Réouvrir',
  reopened_scorecard: "Fiche d'évaluation rouverte",
  reopened_survey_for_participant: 'Réouverture du sondage pour le participant',
  reopened: 'Rouvert',
  reordered: 'Réordonné',
  replace_with_this_job: 'Remplacer par cet emploi',
  reply: 'Répondre',
  reports_to: 'Rend compte à',
  request_additional_review_message: 'Êtes-vous sûr de vouloir demander une révision supplémentaire?',
  request_additional_review: 'Demander une révision supplémentaire',
  request_cancelled_by: 'Demande annulée par {name}',
  request_cancelled: 'Demande annulée',
  request_signoff_message: "Une demande d'approbation sera envoyée à {name}. | Une demande d'approbation sera envoyée aux employés sélectionnés.",
  request_signoff_title: 'Demander une approbation?',
  request_signoffs_on_version: "Demande d'approbation pour la version {version} ",
  request_signoffs: 'Demander une approbation',
  request: 'Demander',
  requested_by: 'Demandé par {name}',
  requested_description: "Une demande d'approbation a été envoyée à l'employé.",
  requested: 'Demandé',
  require_comments: 'Exiger des commentaires',
  require_participants_to_comment: "Exiger des participants de laisser un commentaire lorsqu'une option spécifique est sélectionnée.",
  require: 'Exiger',
  required_level_x: 'Niveau {level} requis',
  required_three_plus_responsibility: 'Nécessite 3+ responsabilités',
  required: 'Requis',
  requirements_gaps: 'Lacunes dans les exigences',
  requirements: "@:{'singular_requirement'} | @:{'plural_requirement'} ",
  reschedule: 'Replanifier',
  resend_invitations: "Renvoyer l'invitation | Renvoyer les invitations",
  resend_invite: "Renvoyer l'invitation",
  resend_invites: "Renvoyer l'invitation",
  resend_to: 'Renvoyer à',
  resend: 'Renvoyer',
  resent_invitation: 'Invitation renvoyée',
  reset_integration_description: 'Êtes-vous sûr de vouloir réinitialiser cette intégration?',
  reset_integration: "Réinitialiser l'intégration",
  reset_password_msg: 'Veuillez saisir votre courriel de connexion et nous vous enverrons un lien pour réinitialiser votre mot de passe.',
  reset_password: 'Réinitialiser le mot de passe',
  reset: 'Réinitialiser',
  resigned: 'Démissionné',
  resources: 'Ressources',
  response_options: 'Options de réponse',
  response_rate: 'Taux de réponse',
  responses: 'Réponses',
  responsibilities_archived: 'Élément archivé | Éléments archivés',
  responsibilities_created: 'Élément créé | Éléments créés',
  responsibilities_deleted: 'Élément supprimé | Éléments supprimés',
  responsibilities_edited: 'Élément modifié | Éléments modifiés',
  responsibilities_guideline_description: "Les responsabilités se concentrent sur les aspects les plus critiques et importants de l'emploi - elles ne sont pas destinées à décrire chaque aspect de l'emploi.<p class=\"mt-2\"> Il devrait y avoir de 8 à 12 responsabilités au total.<p>",
  responsibilities_moved: 'Élément déplacé | Éléments déplacés',
  responsibilities_published: 'Élément publié | Éléments publiés',
  responsibilities_restored: 'Élément restauré | Éléments restaurés',
  responsibilities_updated: "Mis à jour : @.lower:{'singular_responsibilities'} | Mis à jour : @.lower:{'plural_responsibilities'}",
  responsibilities: "@:{'singular_responsibilities'} | @:{'plural_responsibilities'}",
  responsibility_archive: "Archive : @:{'plural_responsibilities'}",
  responsibility_archived: 'Élément archivé | Éléments archivés',
  responsibility_created: 'Élément créé | Éléments créés',
  responsibility_deleted: 'Élément supprimé | Éléments supprimés',
  responsibility_groups: "Groupes : @.capitalize:{'plural_responsibilities'}",
  responsibility_moved: 'Élément déplacé | Éléments déplacés',
  responsibility_published: 'Élément publié | Éléments publiés',
  responsibility_restored: 'Élément restauré | Éléments restaurés',
  responsibility_updated: 'Élément mis à jour | Éléments mis à jour',
  responsibility: "@:{'singular_responsibilities'} | @:{'plural_responsibilities'}",
  restore_additional_information_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_additional_information: 'Élément restauré | Éléments restaurés',
  restore_competencies_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_competencies: "Restaurer @.lower:{'competencies'}?",
  restore_educations_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_educations: "Restaurer @.lower:{'educations'}?",
  restore_experiences_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_experiences: "Restaurer @.lower:{'experiences'}?",
  restore_jobs_message: 'Il y a un emploi avec le même nom sur votre site. Si vous restaurez cet emploi, nous ajouterons un numéro à la fin.',
  restore_jobs: "Restaurer l'emploi?",
  restore_languages_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_languages: "Restaurer @.lower:{'languages'}?",
  restore_library_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_responsibilities: "Restaurer @.lower:{'responsibilities'}?",
  restore_responsibility_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_responsibility: "Restaurer @.lower:{'responsibilities'}?",
  restore_skill_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_skill: "Restaurer @.lower:{'skills'}?",
  restore_version_description: 'Utiliser la version {number} de cet emploi pour créer un nouveau brouillon.',
  restore_version: 'Restaurer la version',
  restore_working_conditions_msg: 'Il y a un élément identique dans cette bibliothèque. Si vous restaurez cet élément, nous lui ajouterons un numéro à la fin.',
  restore_working_conditions: "Restaurer @.lower:{'working_conditions'}?",
  restore: 'Restaurer',
  restored: 'Restauré',
  results_not_available_yet: 'Résultats non disponibles pour le moment. Veuillez vérifier plus tard.',
  results: 'Résultats',
  resume: 'Reprendre',
  resumes: 'Reprendre',
  retired: 'Retraité',
  return_users_table: 'Retour à la table des utilisateurs',
  reuse_previous_mapping: 'Réutiliser la cartographie précédente',
  review_acknowledgement_email_for_employees: "Courriel d'accusé de réception des évaluations pour les employés",
  review_acknowledgements: 'Accusés de réception des évaluations',
  review_administrators: 'Administrateurs des évaluations',
  review_and_edit: 'Revoir et modifier',
  review_assessments_cancel_msg: 'Les évaluations sont annulées',
  review_bulk_share_results_modal_message: 'Partager ces résultats.',
  review_bulk_share_results_modal_note: 'Veuillez noter : seules les évaluations dont le statut est complet ou prêt à être partagé peuvent être partagées en masse.',
  review_bulk_share_results_msg: 'Les évaluations éligibles ont été partagées.',
  review_by: 'Révision par',
  review_cancel_dialog_message: "Si vous annulez cet examen, les invitations ne seront pas envoyées aux participants. Vous pouvez toujours reprogrammer l'examen plus tard.",
  review_cancel_dialog_title: "Annuler l'examen",
  review_changes_locked_assessment: 'Les modifications apportées au centre de gestion des utilisateurs ou au profil de compétences/aptitudes ne mettront pas à jour cette évaluation.',
  review_changes_locked_participant: 'Les modifications apportées au centre de gestion des utilisateurs ou au profil de compétences/aptitudes ne mettront pas à jour ce participant.',
  review_close_dialog_message: "Si vous fermez cet examen, toutes les évaluations en attente seront annulées et les participants ne pourront plus soumettre de données d'évaluation. Le partage des résultats et les accusés de réception des employés ne sont pas affectés.",
  review_close_dialog_title: "Fermer l'examen",
  review_communications_automated_reminders_note: 'Laissez vide pour ne pas envoyer de rappels automatisés.',
  review_communications_automated_reminders_start_before_deadline_days_input: 'Envoyer automatiquement aux participants des rappels quotidiens à partir de {input} jours avant chaque date limite.',
  review_communications: "Communications de l'examen",
  review_contains_error_message: 'Cet examen contient des erreurs qui nécessitent votre attention.',
  review_cycle_banner_message_1: 'Cet emploi a été planifié pour révision à partir du {date}.',
  review_cycle_banner_message_2: "Si aucune modification n'est nécessaire, vous pouvez <span class=\"text-link cursor-pointer\">marquer l'emploi comme révisé</span>.",
  review_cycle_banner_message_3: " Pour mettre à jour l'emploi ou inviter d'autres personnes à fournir des contributions, vous pouvez <span class=\"text-link cursor-pointer\">modifier l'emploi</span>.",
  review_cycle_notice_explanations_1: "Les notifications sont envoyées aux propriétaires de groupes d'emplois et aux utilisateurs ayant la permission de gérer les emplois. ",
  review_cycle_notice_explanations_2: 'Les emplois applicables dans le tableau des emplois peuvent être filtrés en sélectionnant le statut Révision à venir.',
  review_cycle_notice_explanations: 'Quand commence la période de préavis :',
  review_cycle_notice_input: 'La période de préavis commence {input} jours avant la date limite de révision.',
  review_cycle_notice_message: 'La période de préavis commence <span class="text-link cursor-pointer font-semibold">{days} jours</span> avant la date limite de révision.',
  review_cycle_scheduled_input: 'Les révisions des emplois publiés sont planifiées tous les {input} jours.',
  review_cycle_scheduled_message: 'Les révisions des emplois publiés sont planifiées tous les <span class="text-link cursor-pointer font-semibold">{days} jours</span>.',
  review_cycles: 'Cycle de révision | Cycles de révision',
  review_due_date: 'Date limite de révision',
  review_duplicated: 'Examen dupliqué',
  review_error_delay_message: "Les erreurs peuvent prendre jusqu'à 15 minutes à résoudre.",
  review_export_scheduled: "L'exportation a commencé. Nous vous enverrons un courriel une fois terminé.",
  review_goes_live: 'Mis en ligne',
  review_managers: "gestionnaire d'examen | gestionnaires d'examen",
  review_messagebar_check_setup_complete: 'Dernière vérification le {datetime}.',
  review_messagebar_check_setup_in_process: 'Vérification en cours...',
  review_messagebar_complete_setup_in_process: 'Configuration en cours...',
  review_messagebar_complete_setup: 'Cet examen est prévu pour être lancé le {date}.',
  review_messagebar_live_close_on_date: 'Cet examen a été fermé le {date}.',
  review_messagebar_went_live_on_date: 'Cet examen a été lancé le {date}.',
  review_new_subject_added_mas: "Participants ajoutés. Note : Il peut prendre jusqu'à 15 minutes pour que les nouveaux participants apparaissent.",
  review_previous_mapping: 'Examiner la cartographie précédente',
  review_questionnaire_indepth_rating_instruction_manager: "Évaluez l'efficacité de {subjectName} sur : {bi}",
  review_questionnaire_indepth_rating_instruction_subject: 'Évaluez votre efficacité sur : {bi}',
  review_questionnaire_snapshot_rating_instruction_manager: "Évaluez l'efficacité de {subjectName} sur : {competencyOrSkill}",
  review_questionnaire_snapshot_rating_instruction_subject: 'Évaluez votre efficacité sur : {competencyOrSkill}',
  review_reopen_dialog_message: 'Si vous rouvrez cet examen, les participants ne seront pas contactés, sauf si vous rouvrez manuellement les évaluations individuelles.',
  review_reopen_dialog_title: "Rouvrir l'examen",
  review_requested: 'Révision demandée',
  review_result_header_title_text_for_manager: "Les capacités de {subject} comparées aux exigences d'un {job}",
  review_result_header_title_text_for_subject: "Vos capacités comparées aux exigences d'un {job}",
  review_results_visibility: 'Visibilité des résultats',
  review_reviewers: 'Évaluateur des résultats | Évaluateurs des résultats',
  review_share_results_modal_note: "Veuillez noter : {subjectFirstName} n'a pas encore soumis son évaluation.",
  review_share_results_msg: "Les résultats de l'examen ont été partagés.",
  review_sharing_email_for_managers: 'Courriel de partage des résultats pour les gestionnaires',
  review_types_automated_subtitle: "Évaluez les employés selon des règles (par exemple, le nombre de jours depuis la date d'embauche). Utile pour soutenir des processus tels que l'intégration des employés.",
  review_types_automated_title: 'Automatisé',
  review_types_delegated_subtitle: "Permettre aux utilisateurs désignés de contrôler certains aspects du processus d'examen (par exemple, contenu ou calendrier). Utile pour les examens axés sur le développement.",
  review_types_delegated_title: 'Délégué',
  review_types_scheduled_subtitle: 'Évaluer les employés selon les calendriers imposés par les RH. Utile pour les examens axés sur la performance ou le développement.',
  review_types_scheduled_title: 'Planifié',
  review_your_rating_for_candidate: 'Révisez vos notations pour {candidate}.',
  review_your_rating: 'Réviser vos évaluations pour {candidat}.',
  review: 'Réviser',
  reviewed: 'Révisé',
  reviewers_description: "Les relecteurs ont un accès en lecture seule. Ils peuvent fournir des commentaires et des retours, mais ne peuvent pas modifier l'emploi.",
  reviewers: 'Relecteur | Relecteurs',
  reviews_description: 'Confirmer les détails clés de votre examen.',
  reviews_invitations_description: '{review_and_edit_action} les courriels et messages des participants. Envoyer automatiquement des rappels quotidiens à partir de {number_of_days_action} jours avant chaque date limite.',
  reviews_reminders_description: 'Envoyer automatiquement des rappels aux participants <span class="text-link cursor-pointer">{days} jours</span> avant chaque date limite.',
  reviews_setup_details_completion_notifications_text: "Définir quels utilisateurs doivent recevoir un courriel quotidien avec les statistiques d'achèvement.",
  reviews_setup_details_description_text: "Uniquement visible par les administrateurs de l'examen.",
  reviews_setup_details_review_administrators_text: "Définir les utilisateurs qui peuvent gérer les paramètres, le calendrier, le contenu et les participants de l'examen.",
  reviews_setup_details_review_results_visibility_text: 'Définir les utilisateurs qui peuvent consulter les résultats de toutes les évaluations de cet examen.',
  reviews_setup_details_title_text: "Informations générales sur l'examen, y compris les droits d'administration et d'accès.",
  reviews_setup_participants_title_text: 'Les employés que vous souhaitez évaluer dans le cadre de cet examen. Vous ne devez ajouter que les sujets (les personnes évaluées). Nous utiliserons vos liens hiérarchiques pour identifier automatiquement les participants supplémentaires nécessaires.',
  reviews_setup_questions_content_type_sub_title: 'Inclure les compétences et/ou les aptitudes.',
  reviews_setup_questions_current_job_text: 'Évaluer les employés en fonction des exigences de leur emploi actuel.',
  reviews_setup_questions_in_depth_text: "Pour les aptitudes uniquement. Utilise plusieurs questions par aptitude. Les participants évaluent le sujet sur chaque énoncé comportemental selon le niveau de maîtrise défini pour l'emploi.",
  reviews_setup_questions_snapshots_text: "Pour les compétences uniquement. Utilise plusieurs questions par compétence. Les participants évaluent le sujet sur chaque énoncé comportemental à partir du niveau de maîtrise défini pour l'emploi.",
  reviews_setup_questions_specific_job_architecture_options_text: "Choisissez les couches de l'architecture de compétences à inclure.",
  reviews_setup_questions_specific_job_question_types_text: 'Choisissez des questions de type instantané ou approfondies.',
  reviews_setup_questions_specific_job_select_targets_text: "La cible est utilisée pour calculer le moment où les sujets ont atteint le niveau de compétence requis spécifié pour l'emploi.",
  reviews_setup_questions_specific_job_text: "Évaluez l'employé en fonction des exigences d'un emploi spécifique.",
  reviews_setup_questions_specific_questions_text: 'Sélectionnez des compétences ou des aptitudes spécifiques pour évaluer les employés.',
  reviews_setup_questions_title_text: "Les questions que vous souhaitez inclure dans l'évaluation. Les questions peuvent être basées sur les exigences du poste ou sur une sélection directe de compétences et d'aptitudes.",
  reviews_setup_schedule_title_text: 'Le calendrier pour votre examen. Chaque point de vue peut être programmé indépendamment en utilisant le fuseau horaire de votre site : {site_time_zone}. Veuillez noter que les invitations peuvent prendre un certain temps à être traitées en fonction du nombre de participants dans votre examen.',
  reviews_setup_viewpoints_manager_reviews_text: 'Les gestionnaires évaluent les performances de leurs subordonnés directs et suggèrent des domaines de croissance futurs.',
  reviews_setup_viewpoints_self_reflection_text: 'Les employés évaluent leurs propres performances et capacités.',
  reviews_setup_viewpoints_title_text: "Les groupes d'employés que vous souhaitez inclure dans l'examen. Pour minimiser les biais et offrir davantage d'opportunités d'insights, nous recommandons d'inclure plusieurs points de vue dans un examen. {learn_more}",
  reviews: 'Examen | Examens',
  revoke_device_access_message: 'Supprimer cet appareil de vos appareils de confiance.',
  revoke_device_access: "Révoquer l'accès de l'appareil",
  revoke: 'Révoquer',
  roles: 'Rôle | Rôles',
  round_details: 'Détails du tour',
  rounds: 'Tours',
  rows: 'Lignes',
  salary_ranges: 'Fourchette salariale',
  salary: 'Salaire',
  save_and_exit: 'Enregistrer et quitter',
  save_as_templates: 'Enregistrer comme gabarit',
  save_comment_modal_message: 'Voulez-vous enregistrer ce commentaire?',
  save_comment_modal_title: 'Enregistrer le commentaire?',
  save: 'Enregistrer',
  saved: 'Enregistré',
  saving: 'Enregistrement',
  scale_deleted_impact: 'Ce niveau a été supprimé. {jobs_count} emplois ont été mis à jour.',
  scale_deleted: 'Ce niveau a été supprimé.',
  scale_disabled_impact: 'Cette échelle a été désactivée. {jobs_count} emplois ont été mis à jour.',
  scale_disabled: 'Cette échelle a été désactivée.',
  scale_table_desc: "Les échelles peuvent être utilisées dans les emplois, les bibliothèques et les gabarits d'entrevue.",
  scales_updated: 'Échelles mises à jour',
  scales: 'Échelles',
  schedule_description: 'Définir la période du sondage.',
  schedule_survey: 'Planifier le sondage',
  schedule: 'Planifier',
  scheduled_for_review_by: 'Planifié pour révision à partir du {date}',
  scheduled_review: 'Révision planifiée',
  scheduled: 'Planifié',
  score_candidates: 'Noter le candidat',
  scorecard_comments: "Commentaires sur la fiche d'évaluation",
  scorecard_reopened: "Fiche d'évaluation rouverte",
  scorecard_submitted_banner: "Votre fiche d'évaluation a été soumise et ne peut pas être modifiée.",
  scorecard_submitted_count: "{submitted} sur {total} fiches d'évaluation soumises",
  scorecards: "Fiche d'évaluation",
  scoring_authority: 'Autorité de notation',
  search_capabilities: 'Rechercher des capacités',
  search_employee: 'Rechercher un employé',
  search_employees: 'Rechercher des employés',
  search_for_a_job_to_compare_to: 'Rechercher un emploi à comparer.',
  search_for_a_position_or_user: 'Rechercher poste ou utilisateur',
  search_for_competencies: "Rechercher @.lower:{'competencies'}",
  search_managers: 'Rechercher des gestionnaires',
  search_name_or_email: 'Rechercher un nom ou un courriel',
  search_name_or_id: 'Rechercher par nom ou ID',
  search_or_enter_title: 'Rechercher ou entrer le titre',
  search_or_type: "Rechercher  <span class=\"text-grey-3\">ou taper '#'</span>",
  search_subjects_or_jobs: 'Rechercher des sujets ou emplois',
  search_subjects: 'Rechercher des sujets',
  search_user_or_position: 'Rechercher des postes ou des utilisateurs assignés',
  search_users: 'Rechercher des utilisateurs',
  search: 'Rechercher',
  seats: 'Sièges',
  sections: 'Section',
  see_examples: "Voir l'exemple | Voir les exemples",
  see_full_import_details: "Pour voir les détails complets de l'importation",
  see_here: 'voir ici',
  see_less: 'Voir moins',
  see_more: 'Voir plus',
  select_a_level: 'Sélectionner un niveau',
  select_a_version_to_compare: 'Sélectionner une version à comparer',
  select_all_additional_information_items: 'Sélectionner tous',
  select_all_certifications_items: 'Sélectionner tous',
  select_all_certifications: 'Sélectionner tous',
  select_all_competencies_items: 'Sélectionner tous',
  select_all_competencies: 'Sélectionner tous',
  select_all_educations_items: 'Sélectionner tous',
  select_all_educations: 'Sélectionner tous',
  select_all_experiences_items: 'Sélectionner tous',
  select_all_jobs: 'Sélectionner tous',
  select_all_language_items: 'Sélectionner tous',
  select_all_languages_items: 'Sélectionner tous',
  select_all_positions: 'Sélectionner tous',
  select_all_questions: 'Sélectionner tous',
  select_all_records: 'Sélectionner tous',
  select_all_responsibilities: 'Sélectionner tous',
  select_all_responsibility_items: 'Sélectionner tous',
  select_all_skill_items: 'Sélectionner tous',
  select_all_skill: 'Sélectionner tous',
  select_all_skills_items: 'Sélectionner tous',
  select_all_skills: 'Sélectionner tous',
  select_all_surveys: 'Sélectionner tous',
  select_all_users: 'Sélectionner tous',
  select_all_working_conditions_items: 'Sélectionner tous',
  select_all: 'Sélectionner tous',
  select_competencies: "Sélectionner @.lower:{'plural_competencies'}",
  select_end_date: 'Sélectionner la date de fin',
  select_groups: 'Sélectionner un groupe',
  select_job_post_template: "Sélectionner le gabarit d'annonce d'emploi",
  select_job: "Sélectionner l'emploi",
  select_levels: 'Sélectionner le niveau',
  select_libraries: 'Sélectionner des bibliothèques',
  select_new_parent: 'Sélectionner le nouveau parent',
  select_permission_sets: 'Sélectionner les ensembles de permissions',
  select_scales: 'Sélectionner une échelle',
  select_scoring_authority: "Sélectionner l'autorité de notation :",
  select_skill: "Sélectionner @.lower:{'plural_skills'}",
  select_skills: "Sélectionner @.lower:{'plural_skills'}",
  select_start_date: 'Sélectionner la date de début',
  select_targets: 'Sélectionner la cible',
  select_topics: 'Sélectionner les sujets',
  select_types: 'Sélectionner les types',
  select_versions: 'Sélectionner la version',
  select: 'Sélectionner',
  selected_by: 'Sélectionné par :',
  selected_content: 'Contenu sélectionné',
  selected: 'Sélectionné | Sélectionnés',
  self_reflection_communicated_deadline: "Il s'agit de la date limite indiquée sur les demandes de retour d'information, mais le retour d'information peut encore être fourni après cette date.",
  self_reflection_description: 'Les employés évaluent leurs propres performances et capacités',
  self_reflection_launch_date: 'Les employés recevront une notification et un courriel à cette date, les invitant à fournir leurs réflexions personnelles.',
  self_reflection_share_with_manager_description: "Partager les réflexions personnelles avec les gestionnaires directs des employés une fois qu'elles ont été soumises.",
  self_reflection_subtitle: 'Fournir une réflexion personnelle',
  self_reflections: 'Réflexion personnelle | Réflexions personnelles',
  send_daily_reminder_new_responses: 'Envoyer un courriel quotidien montrant les nouvelles réponses au sondage.',
  send_email_when_done_message: 'Nous enverrons un courriel à {email} quand ce sera terminé.',
  send_email_when_done: 'Nous vous enverrons un courriel quand cela sera terminé.',
  send_invitations: 'Envoyer une invitation | Envoyer des invitations',
  send_invite: 'Envoyer une invitation',
  send_invites_confirmation_message: "Envoyer des invitations à la réunion aux intervieweurs. Veuillez noter que Quinto n'envoie pas d'invitations aux candidats.",
  send_invites_interviewers_tooltip: "Veuillez noter que Quinto n'envoie pas d'invitations aux candidats.",
  send_invites: 'Envoyer une invitation | Envoyer des invitations',
  send_job_post: "Envoyer une annonce d'emploi",
  send_password_reset_email_message: 'Cet utilisateur recevra un courriel avec un lien pour réinitialiser son mot de passe.',
  send_password_reset_email_title: 'Envoyer un courriel de réinitialisation de mot de passe.',
  send_password_reset_now: 'Envoyer une <span class="text-link cursor-pointer">réinitialisation de mot de passe</span> maintenant.',
  send_password_reset: 'Envoyer une réinitialisation de mot de passe',
  send_reminders: 'Envoyer des rappels',
  send_signoff_reminders_message: "Envoyer un rappel d'approbation à {name} | Envoyer un rappel d'approbation aux employés.",
  send_signoff_reminders: "Envoyer un rappel d'approbation",
  send_to_ats: 'Envoyer à {ats}',
  send_to: 'Envoyer à',
  send: 'Envoyer',
  sent_by: 'Envoyé par {name}',
  sent_password_reset: 'Réinitialisation du mot de passe envoyée',
  sent: 'Envoyé',
  sep: 'Sep',
  set_a_due_date: "Définir une date d'échéance",
  set_level: 'Définir le niveau',
  set_to_done: 'Définir comme terminé',
  set_up_interviews: "Configurer l'entrevue",
  settings_description: 'Modifier les paramètres de ce sondage.',
  settings: 'Paramètre | Paramètres',
  setup: 'Configuration',
  share_again: 'Partager à nouveau',
  share_results_with_subject: 'Partager ces résultats avec {subjectName}.',
  share_results: 'Partager les résultats',
  share_with_x: 'Partager avec {name}',
  shared_feedback: 'Rétroaction partagée',
  shared_results: 'Résultats partagés',
  shared_with_x: 'Partagé avec {name}',
  sharing_results_option_1: 'Les résultats ne seront pas partagés avec les sujets.',
  sharing_results_option_2: 'Permettre aux sujets de voir les résultats automatiquement dès que les réponses sont soumises.',
  sharing_results_option_3: 'Les administrateurs sont responsables du partage des résultats.',
  sharing_results_option_4_sub_1: "Permettre aux gestionnaires de partager les résultats dès qu'ils soumettent des rétroactions.",
  sharing_results_option_4_sub_2: 'Exiger que les gestionnaires partagent les résultats selon un calendrier.',
  sharing_results_option_4: 'Exiger que les gestionnaires partagent et discutent des résultats avec leurs rapports directs.',
  sharing_results_subtitle: 'Les résultats sont partagés avec les employés.',
  sharing_results: 'Partager les résultats',
  show_all_details: 'Afficher tous les détails',
  show_architecture_description: "Afficher une étiquette d'architecture (par exemple, de base ou famille d'emplois).",
  show_architecture: "Afficher l'architecture",
  show_assigned_levels: 'Afficher les niveaux attribués',
  show_changes: 'Afficher les modifications',
  show_columns: 'Afficher les colonnes',
  show_core_items_description: "Permettre aux participants de voir les @.lower:{'plural_competencies'} de base.",
  show_details: 'Afficher les détails',
  show_leadership_items_description: "Permettre aux participants de voir les @.lower:{'plural_competencies'} de direction.",
  show_less_comments: 'Voir moins de commentaires',
  show_less: 'Voir moins',
  show_library_items: 'Surligner les éléments de la bibliothèque',
  show_more_comments: 'Voir plus de commentaires',
  show_more: 'Afficher plus',
  show_subheadings: 'Afficher les sous-titres',
  show_toolbar: "Afficher la barre d'outils",
  show_track_changes: 'Afficher les modifications de suivi',
  show_untranslated_items: 'Afficher les éléments non traduits',
  show: 'Afficher',
  showing: 'Affichage',
  side_content: 'Contenu latéral',
  signature_date_description: "Ajouter un champ de signature et de date sur les exportations d'annonces d'emploi.",
  signature_date: 'Signature et date',
  signed_off_by: 'Approuvé par {name}',
  signed_off_date: 'Approuvé le : {date}',
  signed_off: 'Approuvé',
  signoff_are_due_after_each_request_is_sent_input: 'Les approbations sont dues {input} jours après chaque demande envoyée.',
  signoff_are_due_after_each_request_is_sent: 'Les approbations sont dues <span class="text-link cursor-pointer">{days} jours</span> après chaque demande envoyée.',
  signoff_auto_reminders_input_text: "Les rappels automatiques sont envoyés quotidiennement, à partir de {input} jours avant la date limite et jusqu'à ce que l'approbation soit complétée.",
  signoff_auto_reminders_panel_text: 'Envoyer automatiquement des rappels quotidiens, à partir de <span class="text-link cursor-pointer font-semibold"> {days} jours </span> avant la date limite.',
  signoff_canceled_message: "Votre demande d'approbation a été annulée.",
  signoff_complete_message: "Merci d'avoir complété votre approbation. Vous pouvez <span id=\"download\" class=\"text-link cursor-pointer\">télécharger</span> une copie maintenant pour vos dossiers ou le télécharger ultérieurement depuis votre <span id=\"profile\" class=\"text-link cursor-pointer\">profil</span>.",
  signoff_complete: 'Approbation complète',
  signoff_confirmation_message: 'Pour confirmer votre identité, veuillez saisir le code de confirmation à six chiffres qui vous a été envoyé par courriel.',
  signoff_confirmation: "Confirmation d'approbation",
  signoff_date: "Date d'approbation",
  signoff_deleted_message: "Cet emploi a été supprimé et votre demande d'approbation a été annulée.",
  signoff_due_date: "Date d'échéance d'approbation",
  signoff_due: "Échéance d'approbation",
  signoff_expire_after_days_input: 'Les approbations expirent après {input} jours.',
  signoff_expire_after_days: 'Les approbations expirent après <span class="text-link cursor-pointer">{days} jours</span>.',
  signoff_expired: "L'approbation a expiré",
  signoff_expiry_date: "Date d'expiration de l'approbation",
  signoff_granted_by: 'Approbation accordée par {name}',
  signoff_granted: 'Approbation accordée',
  signoff_history: 'Historique des approbations',
  signoff_request_message: "Message de demande d'approbation",
  signoff_statement_description: "La déclaration que les employés reconnaissent lorsqu'ils approuvent leurs descriptions d'emploi.",
  signoff_statement: "Déclaration d'approbation",
  signoff_updated_message: "Votre emploi a été mis à jour. Veuillez vérifier votre courriel pour une nouvelle demande d'approbation.",
  signoff: 'Approbation',
  signoffs_will_appear_here: 'Les approbations apparaîtront ici.',
  signoffs: 'Approbations',
  single_select: 'Sélection unique',
  single_sign_on_desc: "Permettre aux utilisateurs d'accéder à Quinto en utilisant l'authentification unique (SSO).",
  single_sign_on: 'Authentification unique',
  singular_additional_information: 'Information supplémentaire',
  singular_certifications: 'Certification',
  singular_competencies: 'Compétence',
  singular_educations: 'Éducation',
  singular_experiences: 'Expérience',
  singular_interview_questions: "Question d'entrevue",
  singular_jobs: 'Emploi',
  singular_languages: 'Langue',
  singular_learning_resources: "Ressource d'apprentissage",
  singular_requirement: 'Exigence',
  singular_responsibilities: 'Responsabilité',
  singular_skills: 'Aptitude',
  singular_working_conditions: 'Condition de travail',
  singular: 'Singulier',
  site_admin_email: "Courriel de l'administrateur du site",
  site_logo: 'Logo du site',
  site_update_message: 'Nous avons mis à jour Quinto.',
  skill_archive: "Archive : @:{'plural_skills'}",
  skill_archived: 'Élément archivé | Éléments archivés',
  skill_created: 'Élément créé | Éléments créés',
  skill_deleted: 'Élément supprimé | Éléments supprimés',
  skill_edited: 'Élément modifié | Éléments modifiés',
  skill_gaps: 'Lacunes en aptitudes',
  skill_groups: "Groupes : @.capitalize:{'plural_skills'}",
  skill_levels: "Niveaux de @.lower:{'singular_skills'}",
  skill_matches: "Correspondances d'aptitudes",
  skill_moved: 'Élément déplacé | Éléments déplacés',
  skill_published: 'Élément publié | Éléments publiés',
  skill_restored: 'Élément restauré | Éléments restaurés',
  skill_scales: "Échelle d'aptitudes",
  skill_updated: 'Élément mis à jour | Éléments mis à jour',
  skill: "@:{'singular_skills'} | @:{'plural_skills'}",
  skills_guideline_description: 'Les aptitudes sont des capacités développées nécessaires pour accomplir une tâche ou un emploi.<p class="mt-2"> Il devrait y avoir entre 8 et 16 aptitudes en tout.<p/>',
  skills_updated: "Mis à jour : @.lower:{'singular_skills'} | Mis à jour : @.lower:{'plural_skills'}",
  skills: "@:{'singular_skills'} | @:{'plural_skills'}",
  snapshots: 'Instantané',
  some_interviewers_not_invited: "Certains intervieweurs n'ont pas été invités.",
  some_jobs_published: "L'emploi n'a pas été publié car il ne répondait pas aux exigences de cohérence. | Certains emplois n'ont pas été publiés car ils ne répondaient pas aux exigences de cohérence.",
  sorry_page_not_available: "Désolé, cette page n'est pas disponible.",
  sort_by: 'Trier par',
  specific_jobs: 'Emploi spécifique',
  specific_questions: 'Questions spécifiques',
  sso_auth_url: "URL d'autorisation",
  sso_client_id: 'Identifiant client',
  sso_keys_url: 'URL des clés',
  sso_provider: 'Fournisseur',
  sso: 'SSO',
  starred_only_visible_to_you: 'Étoilé. Visible uniquement pour vous.',
  starred: 'Étoilé',
  start_date: 'Date de début',
  start_from_scratch: 'Partir de zéro',
  start_with_ai_generated_label: "Commencer avec du contenu généré par l'IA?",
  start_with_ai_generated_sublabel: "L'IA suggérera des indicateurs comportementaux pour la compétence. Il s'agit d'une fonctionnalité de Quinto Labs.",
  start_with_templates: 'Commencer avec un gabarit',
  start_with_warehouse_content: "Commencer avec le contenu de l'entrepôt?",
  start: 'Démarrer',
  started_tracking_changes: 'Commencé à suivre les modifications',
  statement: 'Déclaration',
  status_error: 'Erreur {status}',
  statuses: 'Statut | Statuts',
  stay_tuned: "Restez à l'écoute.",
  stop_level_on_job_description: "Cesser d'utiliser ce niveau dans les descriptions d'emploi",
  stopped_personifying: 'Arrêt de la personnalisation',
  stopped_tracking_changes: 'Arrêt du suivi des modifications',
  strength_checks: 'Évaluations des aptitudes',
  strengths: 'Atouts',
  subheadings: 'Sous-titre',
  subject_capability_match_percentage_of_job: 'Vous répondez ou dépassez {percentage} des exigences du poste pour un {job}.',
  subject_has_inactive_manager_tooltip: '{subjectName} a un gestionnaire inactif.',
  subject: 'Sujet',
  subjects: 'Sujet | Sujets',
  submit_rating: 'Soumettre les évaluations maintenant',
  submit_your_input: 'Soumettre votre contribution',
  submit: 'Soumettre',
  submitted_scorecard: "Fiche d'évaluation soumise",
  submitted: 'Soumis',
  suggested_alternative: 'Alternative suggérée',
  suggested_summary: 'Résumé suggéré',
  suggestions: 'Suggestion | Suggestions',
  summary_guideline_description: "Le résumé de l'emploi est une vue d'ensemble informative d'un poste. Il décrit brièvement les responsabilités essentielles associées au rôle.<p class=\"mt-2\"> Il devrait y avoir de 20 à 60 mots dans le résumé de l'emploi.</p>",
  summary: 'Résumé',
  survey_comment_error_message: 'Veuillez commenter les éléments que vous avez ajoutés à la colonne {name}.',
  survey_communications: 'Communications de sondage',
  survey_contents_added: 'Contenu du sondage ajouté',
  survey_contents_not_translated: "Ce sondage contient du contenu qui n'a pas été traduit.",
  survey_contents_removed: 'Contenu du sondage retiré',
  survey_contents: 'Contenu du sondage',
  survey_has_additional_informations: "Ce sondage comporte {count} @.lower:{'additional_information'}.",
  survey_has_certifications: "Ce sondage comporte {count} @.lower:{'certifications'}.",
  survey_has_competencies: "Ce sondage comporte {count} @.lower:{'competencies'}.",
  survey_has_educations: "Ce sondage comporte {count} @.lower:{'educations'}.",
  survey_has_experiences: "Ce sondage comporte {count} @.lower:{'experiences'}.",
  survey_has_languages: "Ce sondage comporte {count} @.lower:{'languages'}.",
  survey_has_participants: 'Ce sondage comporte {count} participants.',
  survey_has_responsibilities: "Ce sondage comporte {count} @.lower:{'responsibilities'}.",
  survey_has_skills: "Ce sondage comporte {count} @.lower:{'skills'}.",
  survey_has_working_conditions: "Ce sondage comporte {count} @.lower:{'working_conditions'}.",
  survey_invitation_resent: "Renvoi de l'invitation au sondage",
  survey_invitation_sent: "Envoi de l'invitation au sondage",
  survey_is_live_and_due: 'Ce sondage est actif et doit être complété avant le {date}.',
  survey_is_live: 'Ce sondage est actif.',
  survey_is_scheduled_on: 'Ce sondage est planifié pour être actif le {date} à {time}.',
  survey_max_selection_error_message: "Vous avez déjà ajouté le nombre maximal d'éléments à cette colonne.",
  survey_min_selection_error_message: "Veuillez ajouter davantage d'éléments à la colonne {name}.",
  survey_not_available: 'Désolé, vous ne pouvez pas accéder à cette enquête. {link}',
  survey_not_translated: "Le nom du sondage ou une partie du contenu du sondage n'a pas été traduit.",
  survey_participant_responses: 'Réponses des participants au sondage',
  survey_participants_added: 'Participant au sondage ajouté',
  survey_participants_removed: 'Participant au sondage retiré',
  survey_participants: 'Participants au sondage',
  survey_recipients_added: 'Destinataire du sondage ajouté',
  survey_recipients_removed: 'Destinataire du sondage retiré',
  survey_response_options: 'Options de réponse au sondage',
  survey_responses: 'Réponse au sondage',
  survey_results: 'Résultats du sondage',
  survey_was_closed_on: 'Ce sondage a été fermé le {date} par {name}.',
  surveys: 'Sondage | Sondages',
  suspended_msg: 'Cet utilisateur a été suspendu en raison de trop nombreuses tentatives de connexion.',
  suspended: 'Suspendu',
  synchronize_now: 'Synchroniser maintenant',
  synchronize: 'Synchroniser',
  system_settings: 'Paramètres système',
  system: 'Système',
  tab_ats: 'ATS',
  tab_hris: 'HRIS',
  tab_jobs: 'Emplois',
  tab_libraries: 'Bibliothèques',
  tags_tooltip_info: 'Cliquer pour voir plus de balises.',
  tags_use_count: 'Utilisé {count} fois | Utilisé {count} fois',
  tags: 'Balises',
  take_me_there: 'Emmenez-moi là !',
  target_user_groups: 'Groupes d’utilisateurs cibles',
  target: 'Cible',
  task_interview_candidate: 'Candidat : {candidat}',
  task_opening_recruiter: 'Recruteur : {assigner}',
  tasks: 'Tâches',
  tbd: 'À déterminer',
  team: 'Équipe',
  template_additional_informations_message: "Activer les @.lower:{'plural_additional_information'} pour les emplois utilisant ce gabarit. ",
  template_can_not_delete: 'Ce gabarit ne peut pas être supprimé car il a été utilisé.',
  template_can_not_turned_off: 'Ce gabarit ne peut pas être désactivé car il est par défaut.',
  template_can_not_turned_on: "Ce gabarit ne peut pas être activé car il n'est associé à aucun gabarit d'annonce.",
  template_certifications_message: "Activer les @.lower:{'plural_certifications'} pour les emplois utilisant ce gabarit. ",
  template_competencies_description: "Activer les @.lower:{'plural_competencies'} pour les emplois utilisant ce gabarit. ",
  template_content_description: "Définir l'ordre et les sections de contenu de vos annonces d'emploi.",
  template_educations_message: "Activer les @.lower:{'plural_educations'} pour les emplois utilisant ce gabarit.",
  template_enable_consistency_scores_description: "Les scores de cohérence aident à obtenir des descriptions d'emploi plus cohérentes plus cohérentes en définissant les fourchettes idéales de contenu.",
  template_enable_consistency_scores: "Activer les scores de cohérence pour les emplois utilisant ce gabarit.\n\nLes scores de cohérence aident à obtenir des descriptions d'emploi plus cohérentes en définissant des plages de contenu idéales.",
  template_enable_feedback_description: "Les retours permettent aux collaborateurs de fournir des retours de haut niveau sur la description d'emploi.",
  template_enable_feedback: "Activer les retours pour les emplois utilisant ce gabarit.\n\nLes retours permettent aux collaborateurs de l'emploi de fournir des commentaires de haut niveau sur la description d'emploi.",
  template_enable_job_signoffs: "Activer les approbations d'emploi pour les emplois utilisant ce gabarit.",
  template_enable_properties: 'Activer les informations pour les emplois utilisant ce gabarit.',
  template_enable_review_cycles: 'Activer les cycles de révision pour les emplois utilisant ce gabarit.',
  template_experiences_message: "Activer les @.lower:{'plural_experiences'} pour les emplois utilisant ce gabarit.",
  template_groups: 'Groupes de gabarits',
  template_job_post_description: "Définir l'ordre et les sections de contenu de vos annonces d'emploi. Les sections vides ne seront pas affichées dans les annonces.",
  template_languages_message: "Activer les @.lower:{'plural_languages'} pour les emplois utilisant ce gabarit.",
  template_legal_description: 'Choisir les sections légales à inclure dans les exports PDF/Word.',
  template_logo_description: "Télécharger un logo pour l'afficher dans les exportations PDF/Word. ",
  template_responsibilities_message: "Activer les @.lower:{'plural_responsibilities'} pour les emplois utilisant ce gabarit.",
  template_settings_description: 'Définir les paramètres pour ce gabarit',
  template_skills_message: "Activer les @.lower:{'plural_skills'} pour les emplois utilisant ce gabarit.",
  template_statuses: 'Statut du gabarit',
  template_summary_message: 'Activer le résumé pour les emplois utilisant ce gabarit.',
  template_title: 'Titre du gabarit',
  template_working_conditions_message: "Activer les @.lower:{'plural_working_conditions'} pour les emplois utilisant ce gabarit.",
  template: 'Gabarit | Gabarits',
  templates_duplicated: 'Guide dupliqué | Guides dupliqués',
  templates_table_msg: "Les gabarits définissent la mise en page et les propriétés d'un emploi. Ils s'appliquent à tous les emplois et annonces d'emplois.",
  templates: 'Gabarits',
  terms: 'Conditions',
  textbox: 'Alphanumérique',
  thanks_for_providing_input: "Merci d'avoir apporté votre contribution. Nous l'apprécions vraiment!",
  this_month: 'Ce mois-ci',
  this_week: 'Cette semaine',
  this_year: 'Cette année',
  time_zone: 'Fuseau horaire',
  timeline: 'Chronologie',
  times: 'Temps',
  timezone_africa_abidjan: 'Afrique/Abidjan',
  timezone_africa_accra: 'Afrique/Accra',
  timezone_africa_addis_ababa: 'Afrique/Addis-Abeba',
  timezone_africa_algiers: 'Afrique/Algérie',
  timezone_africa_asmara: 'Afrique/Asmara',
  timezone_africa_bamako: 'Afrique/Bamako',
  timezone_africa_bangui: 'Afrique/Bangui',
  timezone_africa_banjul: 'Afrique/Banjul',
  timezone_africa_bissau: 'Afrique/Bissau',
  timezone_africa_blantyre: 'Afrique/Blantyre',
  timezone_africa_brazzaville: 'Afrique/Brazzaville',
  timezone_africa_bujumbura: 'Afrique/Bujumbura',
  timezone_africa_cairo: 'Afrique/Le Caire',
  timezone_africa_casablanca: 'Afrique/Casablanca',
  timezone_africa_ceuta: 'Afrique/Ceuta',
  timezone_africa_conakry: 'Afrique/Conakry',
  timezone_africa_dakar: 'Afrique/Dakar',
  timezone_africa_dar_es_salaam: 'Afrique/Dar es Salaam',
  timezone_africa_djibouti: 'Afrique/Djibouti',
  timezone_africa_douala: 'Afrique/Douala',
  timezone_africa_el_aaiun: 'Afrique/El Aaiun',
  timezone_africa_freetown: 'Afrique/Freetown',
  timezone_africa_gaborone: 'Afrique/Gaborone',
  timezone_africa_harare: 'Afrique/Harare',
  timezone_africa_johannesburg: 'Afrique/Johannesburg',
  timezone_africa_juba: 'Afrique/Juba',
  timezone_africa_kampala: 'Afrique/Kampala',
  timezone_africa_khartoum: 'Afrique/Khartoum',
  timezone_africa_kigali: 'Afrique/Kigali',
  timezone_africa_kinshasa: 'Afrique/Kinshasa',
  timezone_africa_lagos: 'Afrique/Lagos',
  timezone_africa_libreville: 'Afrique/Libreville',
  timezone_africa_lome: 'Afrique/Lome',
  timezone_africa_luanda: 'Afrique/Luanda',
  timezone_africa_lubumbashi: 'Afrique/Lubumbashi',
  timezone_africa_lusaka: 'Afrique/Lusaka',
  timezone_africa_malabo: 'Afrique/Malabo',
  timezone_africa_maputo: 'Afrique/Maputo',
  timezone_africa_maseru: 'Afrique/Maseru',
  timezone_africa_mbabane: 'Afrique/Mbabane',
  timezone_africa_mogadishu: 'Afrique/Mogadiscio',
  timezone_africa_monrovia: 'Afrique/Monrovia',
  timezone_africa_nairobi: 'Afrique/Nairobi',
  timezone_africa_ndjamena: 'Afrique/Ndjamena',
  timezone_africa_niamey: 'Afrique/Niamey',
  timezone_africa_nouakchott: 'Afrique/Nouakchott',
  timezone_africa_ouagadougou: 'Afrique/Ouagadougou',
  timezone_africa_porto_novo: 'Afrique/Porto-Novo',
  timezone_africa_sao_tome: 'Afrique/Sao Tomé',
  timezone_africa_tripoli: 'Afrique/Tripoli',
  timezone_africa_tunis: 'Afrique/Tunis',
  timezone_africa_windhoek: 'Afrique/Mogadiscio',
  timezone_america_adak: 'Amérique/Adak',
  timezone_america_anchorage: 'Amérique/Anchorage',
  timezone_america_anguilla: 'Amérique/Anguilla',
  timezone_america_antigua: 'Amérique/Antigua',
  timezone_america_araguaina: 'Amérique/Araguaina',
  timezone_america_argentina_buenos_aires: 'Amérique/Argentine/Buenos Aires',
  timezone_america_argentina_catamarca: 'Amérique/Argentine/Catamarca',
  timezone_america_argentina_cordoba: 'Amérique/Argentine/Cordoba',
  timezone_america_argentina_jujuy: 'Amérique/Argentine/Jujuy',
  timezone_america_argentina_la_rioja: 'Amérique/Argentine/La Rioja',
  timezone_america_argentina_mendoza: 'Amérique/Argentine/Mendoza',
  timezone_america_argentina_rio_gallegos: 'Amérique/Argentine/Rio Gallegos',
  timezone_america_argentina_salta: 'Amérique/Argentine/Salta',
  timezone_america_argentina_san_juan: 'Amérique/Argentine/San Juan',
  timezone_america_argentina_san_luis: 'Amérique/Argentine/San Luis',
  timezone_america_argentina_tucuman: 'Amérique/Argentine/Tucuman',
  timezone_america_argentina_ushuaia: 'Amérique/Argentine/Ushuaia',
  timezone_america_aruba: 'Amérique/Aruba',
  timezone_america_asuncion: 'Amérique/Asuncion',
  timezone_america_atikokan: 'Amérique/Atikokan',
  timezone_america_bahia_banderas: 'Amérique/Bahia Banderas',
  timezone_america_bahia: 'Amérique/Bahia',
  timezone_america_barbados: 'Amérique/Barbade',
  timezone_america_belem: 'Amérique/Belem',
  timezone_america_belize: 'Amérique/Belize',
  timezone_america_blanc_sablon: 'Amérique/Blanc-Sablon',
  timezone_america_boa_vista: 'Amérique/Boa Vista',
  timezone_america_bogota: 'Amérique/Bogota',
  timezone_america_boise: 'Amérique/Boise',
  timezone_america_cambridge_bay: 'Amérique/Cambridge Bay',
  timezone_america_campo_grande: 'Amérique/Campo Grande',
  timezone_america_cancun: 'Amérique/Cancun',
  timezone_america_caracas: 'Amérique/Caracas',
  timezone_america_cayenne: 'Amérique/Cayenne',
  timezone_america_cayman: 'Amérique/Caïman',
  timezone_america_chicago: 'Amérique/Chicago',
  timezone_america_chihuahua: 'Amérique/Chihuahua',
  timezone_america_costa_rica: 'Amérique/Costa Rica',
  timezone_america_creston: 'Amérique/Creston',
  timezone_america_cuiaba: 'Amérique/Cuiaba',
  timezone_america_curacao: 'Amérique/Curacao',
  timezone_america_danmarkshavn: 'Amérique/Danmarkshavn',
  timezone_america_dawson_creek: 'Amérique/Dawson Creek',
  timezone_america_dawson: 'Amérique/Dawson',
  timezone_america_denver: 'Amérique/Denver',
  timezone_america_detroit: 'Amérique/Détroit',
  timezone_america_dominica: 'Amérique/Dominique',
  timezone_america_edmonton: 'Amérique/Edmonton',
  timezone_america_eirunepe: 'Amérique/Eirunepe',
  timezone_america_el_salvador: 'Amérique/El Salvador',
  timezone_america_fort_nelson: 'Amérique/Fort Nelson',
  timezone_america_fortaleza: 'Amérique/Fortaleza',
  timezone_america_glace_bay: 'Amérique/Glace Bay',
  timezone_america_goose_bay: 'Amérique/Goose Bay',
  timezone_america_grand_turk: 'Amérique/Grand Turc',
  timezone_america_grenada: 'Amérique/Grenade',
  timezone_america_guadeloupe: 'Amérique/Guadeloupe',
  timezone_america_guatemala: 'Amérique/Guatemala',
  timezone_america_guayaquil: 'Amérique/Guayaquil',
  timezone_america_guyana: 'Amérique/Guyane',
  timezone_america_halifax: 'Amérique/Halifax',
  timezone_america_havana: 'Amérique/Havana',
  timezone_america_hermosillo: 'Amérique/Hermosillo',
  timezone_america_indiana_indianapolis: 'Amérique/Indiana/Indianapolis',
  timezone_america_indiana_knox: 'Amérique/Indiana/Knox',
  timezone_america_indiana_marengo: 'Amérique/Indiana/Marengo',
  timezone_america_indiana_petersburg: 'Amérique/Indiana/Petersburg',
  timezone_america_indiana_tell_city: 'Amérique/Indiana/Tell City',
  timezone_america_indiana_vevay: 'Amérique/Indiana/Vevay',
  timezone_america_indiana_vincennes: 'Amérique/Indiana/Vincennes',
  timezone_america_indiana_winamac: 'Amérique/Indiana/Winamac',
  timezone_america_inuvik: 'Amérique/Inuvik',
  timezone_america_iqaluit: 'Amérique/Iqaluit',
  timezone_america_jamaica: 'Amérique/Jamaïque',
  timezone_america_juneau: 'Amérique/Juneau',
  timezone_america_kentucky_louisville: 'Amérique/Kentucky/Louisville',
  timezone_america_kentucky_monticello: 'Amérique/Kentucky/Monticello',
  timezone_america_kralendijk: 'Amérique/Kralendijk',
  timezone_america_la_paz: 'Amérique/La Paz',
  timezone_america_lima: 'Amérique/Lima',
  timezone_america_los_angeles: 'Amérique/Los Angeles',
  timezone_america_lower_princes: 'Amérique/Lower Princes',
  timezone_america_maceio: 'Amérique/Maceio',
  timezone_america_managua: 'Amérique/Managua',
  timezone_america_manaus: 'Amérique/Manaus',
  timezone_america_marigot: 'Amérique/Marigot',
  timezone_america_martinique: 'Amérique/Martinique',
  timezone_america_matamoros: 'Amérique/Matamoros',
  timezone_america_mazatlan: 'Amérique/Mazatlan',
  timezone_america_menominee: 'Amérique/Menominee',
  timezone_america_merida: 'Amérique/Merida',
  timezone_america_metlakatla: 'Amérique/Metlakatla',
  timezone_america_mexico_city: 'Amérique/Mexico City',
  timezone_america_miquelon: 'Amérique/Miquelon',
  timezone_america_moncton: 'Amérique/Moncton',
  timezone_america_monterrey: 'Amérique/Monterrey',
  timezone_america_montevideo: 'Amérique/Montevideo',
  timezone_america_montserrat: 'Amérique/Montserrat',
  timezone_america_nassau: 'Amérique/Nassau',
  timezone_america_new_york: 'Amérique/New York',
  timezone_america_nipigon: 'Amérique/Nipigon',
  timezone_america_nome: 'Amérique/Nome',
  timezone_america_noronha: 'Amérique/Noronha',
  timezone_america_north_dakota_beulah: 'Amérique/North Dakota/Beulah',
  timezone_america_north_dakota_center: 'Amérique/North Dakota/Center',
  timezone_america_north_dakota_new_salem: 'Amérique/North Dakota/New Salem',
  timezone_america_nuuk: 'Amérique/Nuuk',
  timezone_america_ojinaga: 'Amérique/Ojinaga',
  timezone_america_panama: 'Amérique/Panama',
  timezone_america_pangnirtung: 'Amérique/Pangnirtung',
  timezone_america_paramaribo: 'Amérique/Paramaribo',
  timezone_america_phoenix: 'Amérique/Phoenix',
  timezone_america_port_au_prince: 'Amérique/Port-au-Prince',
  timezone_america_port_of_spain: 'Amérique/Port of Spain',
  timezone_america_porto_velho: 'Amérique/Porto Velho',
  timezone_america_puerto_rico: 'Amérique/Puerto Rico',
  timezone_america_punta_arenas: 'Amérique/Punta Arenas',
  timezone_america_rainy_river: 'Amérique/Rainy River',
  timezone_america_rankin_inlet: 'Amérique/Rankin Inlet',
  timezone_america_recife: 'Amérique/Recife',
  timezone_america_regina: 'Amérique/Regina',
  timezone_america_resolute: 'Amérique/Resolute',
  timezone_america_rio_branco: 'Amérique/Rio Branco',
  timezone_america_santarem: 'Amérique/Santarem',
  timezone_america_santiago: 'Amérique/Santiago',
  timezone_america_santo_domingo: 'Amérique/Santo Domingo',
  timezone_america_sao_paulo: 'Amérique/Sao Paulo',
  timezone_america_scoresbysund: 'Amérique/Scoresbysund',
  timezone_america_sitka: 'Amérique/Sitka',
  timezone_america_st_barthelemy: 'Amérique/St Barthélemy',
  timezone_america_st_johns: 'Amérique/St Kitts',
  timezone_america_st_kitts: 'Amérique/St Kitts',
  timezone_america_st_lucia: 'Amérique/St Lucie',
  timezone_america_st_thomas: 'Amérique/St Thomas',
  timezone_america_st_vincent: 'Amérique/St Vincent',
  timezone_america_swift_current: 'Amérique/Swift Current',
  timezone_america_tegucigalpa: 'Amérique/Tegucigalpa',
  timezone_america_thule: 'Amérique/Thule',
  timezone_america_thunder_bay: 'Amérique/Thunder Bay',
  timezone_america_tijuana: 'Amérique/Tijuana',
  timezone_america_toronto: 'Amérique/Toronto',
  timezone_america_tortola: 'Amérique/Tortola',
  timezone_america_vancouver: 'Amérique/Vancouver',
  timezone_america_whitehorse: 'Amérique/Whitehorse',
  timezone_america_winnipeg: 'Amérique/Winnipeg',
  timezone_america_yakutat: 'Amérique/Yakutat',
  timezone_america_yellowknife: 'Amérique/Yellowknife',
  timezone_antarctica_casey: 'Antarctique/Casey',
  timezone_antarctica_davis: 'Antarctique/Davis',
  timezone_antarctica_dumontdurville: 'Antarctique/DumontDUrville',
  timezone_antarctica_macquarie: 'Antarctique/Macquarie',
  timezone_antarctica_mawson: 'Antarctique/Mawson',
  timezone_antarctica_mcmurdo: 'Antarctique/McMurdo',
  timezone_antarctica_palmer: 'Antarctique/Palmer',
  timezone_antarctica_rothera: 'Antarctique/Rothera',
  timezone_antarctica_syowa: 'Antarctique/Syowa',
  timezone_antarctica_troll: 'Antarctique/Troll',
  timezone_antarctica_vostok: 'Antarctique/Vostok',
  timezone_arctic_longyearbyen: 'Arctique/Longyearbyen',
  timezone_asia_aden: 'Asie/Aden',
  timezone_asia_almaty: 'Asie/Almaty',
  timezone_asia_amman: 'Asie/Amman',
  timezone_asia_anadyr: 'Asie/Aqtau',
  timezone_asia_aqtau: 'Asie/Aqtau',
  timezone_asia_aqtobe: 'Asie/Aqtobe',
  timezone_asia_ashgabat: 'Asie/Ashgabat',
  timezone_asia_atyrau: 'Asie/Atyrau',
  timezone_asia_baghdad: 'Asie/Bagdad',
  timezone_asia_bahrain: 'Asie/Bahreïn',
  timezone_asia_baku: 'Asie/Bakou',
  timezone_asia_bangkok: 'Asie/Barnaul',
  timezone_asia_barnaul: 'Asie/Barnaul',
  timezone_asia_beirut: 'Asie/Brunei',
  timezone_asia_bishkek: 'Asie/Bishkek',
  timezone_asia_brunei: 'Asie/Brunei',
  timezone_asia_chita: 'Asie/Chita',
  timezone_asia_choibalsan: 'Asie/Choibalsan',
  timezone_asia_colombo: 'Asie/Colombo',
  timezone_asia_damascus: 'Asie/Dili',
  timezone_asia_dhaka: 'Asie/Hébron',
  timezone_asia_dili: 'Asie/Dili',
  timezone_asia_dubai: 'Asie/Hovd',
  timezone_asia_dushanbe: 'Asie/Dushanbe',
  timezone_asia_famagusta: 'Asie/Famagouste',
  timezone_asia_gaza: 'Asie/Kamchatka',
  timezone_asia_hebron: 'Asie/Hébron',
  timezone_asia_ho_chi_minh: 'Asie/Ho Chi Minh',
  timezone_asia_hong_kong: 'Asie/Khandyga',
  timezone_asia_hovd: 'Asie/Hovd',
  timezone_asia_irkutsk: 'Asie/Jakarta',
  timezone_asia_jakarta: 'Asie/Jakarta',
  timezone_asia_jayapura: 'Asie/Jayapura',
  timezone_asia_jerusalem: 'Asie/Makassar',
  timezone_asia_kabul: 'Asie/Manille',
  timezone_asia_kamchatka: 'Asie/Kamchatka',
  timezone_asia_karachi: 'Asie/Karachi',
  timezone_asia_kathmandu: 'Asie/Kathmandu',
  timezone_asia_khandyga: 'Asie/Khandyga',
  timezone_asia_kolkata: 'Asie/Kuala Lumpur',
  timezone_asia_krasnoyarsk: 'Asie/Pontianak',
  timezone_asia_kuala_lumpur: 'Asie/Kuala Lumpur',
  timezone_asia_kuching: 'Asie/Kuching',
  timezone_asia_kuwait: 'Asie/Koweït',
  timezone_asia_macau: 'Asie/Macau',
  timezone_asia_magadan: 'Asie/Riyadh',
  timezone_asia_makassar: 'Asie/Makassar',
  timezone_asia_manila: 'Asie/Manille',
  timezone_asia_muscat: 'Asie/Muscat',
  timezone_asia_nicosia: 'Asie/Nicosie',
  timezone_asia_novokuznetsk: 'Asie/Novokuznetsk',
  timezone_asia_novosibirsk: 'Asie/Omsk',
  timezone_asia_omsk: 'Asie/Omsk',
  timezone_asia_oral: 'Asie/Oral',
  timezone_asia_phnom_penh: 'Asie/Phnom Penh',
  timezone_asia_pontianak: 'Asie/Pontianak',
  timezone_asia_pyongyang: 'Asie/Pyongyang',
  timezone_asia_qatar: 'Asie/Qatar',
  timezone_asia_qostanay: 'Asie/Qostanay',
  timezone_asia_qyzylorda: 'Asie/Qyzylorda',
  timezone_asia_riyadh: 'Asie/Riyadh',
  timezone_asia_sakhalin: 'Asie/Sakhaline',
  timezone_asia_samarkand: 'Asie/Samarkand',
  timezone_asia_seoul: 'Asie/Séoul',
  timezone_asia_shanghai: 'Asie/Shanghai',
  timezone_asia_singapore: 'Asie/Singapour',
  timezone_asia_srednekolymsk: 'Asie/Srednekolymsk',
  timezone_asia_taipei: 'Asie/Taipei',
  timezone_asia_tashkent: 'Asie/Tbilissi',
  timezone_asia_tbilisi: 'Asie/Tbilissi',
  timezone_asia_tehran: 'Asie/Thimphu',
  timezone_asia_thimphu: 'Asie/Thimphu',
  timezone_asia_tokyo: 'Asie/Tokyo',
  timezone_asia_tomsk: 'Asie/Tomsk',
  timezone_asia_ulaanbaatar: 'Asie/Ulaanbaatar',
  timezone_asia_urumqi: 'Asie/Urumqi',
  timezone_asia_ust_nera: 'Asie/Ust-Nera',
  timezone_asia_vientiane: 'Asie/Vientiane',
  timezone_asia_vladivostok: 'Asie/Yangon',
  timezone_asia_yakutsk: 'Asie/Yakutsk',
  timezone_asia_yangon: 'Asie/Yangon',
  timezone_asia_yekaterinburg: 'Asie/Ekaterinbourg',
  timezone_asia_yerevan: 'Asie/Yerevan',
  timezone_atlantic_azores: 'Atlantique/Açores',
  timezone_atlantic_bermuda: 'Atlantique/Bermudes',
  timezone_atlantic_canary: 'Atlantique/Canaries',
  timezone_atlantic_cape_verde: 'Atlantique/Féroé',
  timezone_atlantic_faroe: 'Atlantique/Féroé',
  timezone_atlantic_madeira: 'Atlantique/Madère',
  timezone_atlantic_reykjavik: 'Atlantique/Reykjavik',
  timezone_atlantic_south_georgia: 'Atlantique/South Georgia',
  timezone_atlantic_st_helena: 'Atlantique/St Hélène',
  timezone_atlantic_stanley: 'Atlantique/Stanley',
  timezone_australia_adelaide: 'Australie/Adélaïde',
  timezone_australia_brisbane: 'Australie/Brisbane',
  timezone_australia_broken_hill: 'Australie/Broken Hill',
  timezone_australia_darwin: 'Australie/Lindeman',
  timezone_australia_eucla: 'Australie/Melbourne',
  timezone_australia_hobart: 'Australie/Sydney',
  timezone_australia_lindeman: 'Australie/Lindeman',
  timezone_australia_lord_howe: 'Australie/Melbourne',
  timezone_australia_melbourne: 'Australie/Melbourne',
  timezone_australia_perth: 'Australie/Sydney',
  timezone_australia_sydney: 'Australie/Sydney',
  timezone_chile_easterisland: 'Chili/EasterIsland : (GMT-06:00) Île de Pâques',
  timezone_etc_gmt_10: 'Etc/GMT+10 : (GMT-10:00) Hawaï',
  timezone_etc_gmt_11: 'Etc/GMT-11 : (GMT+11:00) Îles Salomon, Nouvelle-Calédonie',
  timezone_etc_gmt_12: 'Etc/GMT-12 : (GMT+12:00) Fiji, Kamchatka, Marshall Is.',
  timezone_etc_gmt_8: 'Etc/GMT+8 : (GMT-08:00) Îles Pitcairn',
  timezone_europe_amsterdam: 'Europe/Amsterdam',
  timezone_europe_andorra: 'Europe/Andorre',
  timezone_europe_astrakhan: 'Europe/Astrakhan',
  timezone_europe_athens: 'Europe/Athènes',
  timezone_europe_belgrade: 'Europe/Bratislava',
  timezone_europe_berlin: 'Europe/Berlin',
  timezone_europe_bratislava: 'Europe/Bratislava',
  timezone_europe_brussels: 'Europe/Bruxelles',
  timezone_europe_bucharest: 'Europe/Bucarest',
  timezone_europe_budapest: 'Europe/Budapest',
  timezone_europe_busingen: 'Europe/Busingen',
  timezone_europe_chisinau: 'Europe/Chisinau',
  timezone_europe_copenhagen: 'Europe/Copenhague',
  timezone_europe_dublin: 'Europe/Dublin',
  timezone_europe_gibraltar: 'Europe/Gibraltar',
  timezone_europe_guernsey: 'Europe/Guernsey',
  timezone_europe_helsinki: 'Europe/Helsinki',
  timezone_europe_isle_of_man: 'Europe/Isle of Man',
  timezone_europe_istanbul: 'Europe/Istanbul',
  timezone_europe_jersey: 'Europe/Jersey',
  timezone_europe_kaliningrad: 'Europe/Kaliningrad',
  timezone_europe_kirov: 'Europe/Kirov',
  timezone_europe_kyiv: 'Europe/Kyiv',
  timezone_europe_lisbon: 'Europe/Ljubljana',
  timezone_europe_ljubljana: 'Europe/Ljubljana',
  timezone_europe_london: 'Europe/Luxembourg',
  timezone_europe_luxembourg: 'Europe/Luxembourg',
  timezone_europe_madrid: 'Europe/Madrid',
  timezone_europe_malta: 'Europe/Malte',
  timezone_europe_mariehamn: 'Europe/Mariehamn',
  timezone_europe_minsk: 'Europe/Minsk',
  timezone_europe_monaco: 'Europe/Monaco',
  timezone_europe_moscow: 'Europe/Oslo',
  timezone_europe_oslo: 'Europe/Oslo',
  timezone_europe_paris: 'Europe/Paris',
  timezone_europe_podgorica: 'Europe/Podgorica',
  timezone_europe_prague: 'Europe/Prague',
  timezone_europe_riga: 'Europe/Riga',
  timezone_europe_rome: 'Europe/Rome',
  timezone_europe_samara: 'Europe/Samara',
  timezone_europe_san_marino: 'Europe/San Marino',
  timezone_europe_sarajevo: 'Europe/Sarajevo',
  timezone_europe_saratov: 'Europe/Saratov',
  timezone_europe_simferopol: 'Europe/Simferopol',
  timezone_europe_skopje: 'Europe/Skopje',
  timezone_europe_sofia: 'Europe/Sofia',
  timezone_europe_stockholm: 'Europe/Stockholm',
  timezone_europe_tallinn: 'Europe/Tallinn',
  timezone_europe_tirane: 'Europe/Tiran',
  timezone_europe_ulyanovsk: 'Europe/Ulyanovsk',
  timezone_europe_uzhgorod: 'Europe/Uzhgorod',
  timezone_europe_vaduz: 'Europe/Vaduz',
  timezone_europe_vatican: 'Europe/Vatican',
  timezone_europe_vienna: 'Europe/Vienne',
  timezone_europe_vilnius: 'Europe/Vilnius',
  timezone_europe_volgograd: 'Europe/Volgograd',
  timezone_europe_warsaw: 'Europe/Varsovie',
  timezone_europe_zagreb: 'Europe/Zagreb',
  timezone_europe_zaporozhye: 'Europe/Zaporozhye',
  timezone_europe_zurich: 'Europe/Zurich',
  timezone_indian_antananarivo: 'Inde/Antananarivo',
  timezone_indian_chagos: 'Indien/Chagos',
  timezone_indian_christmas: 'Indien/Noël',
  timezone_indian_cocos: 'Indien/Cocos',
  timezone_indian_comoro: 'Indien/Comoro',
  timezone_indian_kerguelen: 'Indien/Kerguelen',
  timezone_indian_mahe: 'Indien/Mahe',
  timezone_indian_maldives: 'Indien/Maldives',
  timezone_indian_mauritius: 'Indien/Maurice',
  timezone_indian_mayotte: 'Indien/Mayotte',
  timezone_indian_reunion: 'Indien/Réunion',
  timezone_pacific_apia: 'Pacifique/Apia',
  timezone_pacific_auckland: 'Pacifique/Bougainville',
  timezone_pacific_bougainville: 'Pacifique/Bougainville',
  timezone_pacific_chatham: 'Pacifique/Chuuk',
  timezone_pacific_chuuk: 'Pacifique/Chuuk',
  timezone_pacific_easter: 'Pacifique/Easter',
  timezone_pacific_efate: 'Pacifique/Efate',
  timezone_pacific_fakaofo: 'Pacifique/Fakaofo',
  timezone_pacific_fiji: 'Pacifique/Fiji',
  timezone_pacific_funafuti: 'Pacifique/Funafuti',
  timezone_pacific_galapagos: 'Pacifique/Galapagos',
  timezone_pacific_gambier: 'Pacifique/Guadalcanal',
  timezone_pacific_guadalcanal: 'Pacifique/Guadalcanal',
  timezone_pacific_guam: 'Pacifique/Guam',
  timezone_pacific_honolulu: 'Pacifique/Honolulu',
  timezone_pacific_kanton: 'Pacifique/Kanton',
  timezone_pacific_kiritimati: 'Pacifique/Kiritimati',
  timezone_pacific_kosrae: 'Pacifique/Kosrae',
  timezone_pacific_kwajalein: 'Pacifique/Kwajalein',
  timezone_pacific_majuro: 'Pacifique/Majuro',
  timezone_pacific_marquesas: 'Pacifique/Nauru',
  timezone_pacific_midway: 'Pacifique/Midway',
  timezone_pacific_nauru: 'Pacifique/Nauru',
  timezone_pacific_niue: 'Pacifique/Niue',
  timezone_pacific_norfolk: 'Pacifique/Nouméa',
  timezone_pacific_noumea: 'Pacifique/Nouméa',
  timezone_pacific_pago_pago: 'Pacifique/Pago Pago',
  timezone_pacific_palau: 'Pacifique/Palau',
  timezone_pacific_pitcairn: 'Pacifique/Pitcairn',
  timezone_pacific_pohnpei: 'Pacifique/Pohnpei',
  timezone_pacific_port_moresby: 'Pacifique/Port Moresby',
  timezone_pacific_rarotonga: 'Pacifique/Rarotonga',
  timezone_pacific_saipan: 'Pacifique/Saipan',
  timezone_pacific_tahiti: 'Pacifique/Tahiti',
  timezone_pacific_tarawa: 'Pacifique/Tarawa',
  timezone_pacific_tongatapu: 'Pacifique/Wake',
  timezone_pacific_wake: 'Pacifique/Wake',
  timezone_pacific_wallis: 'Pacifique/Wallis',
  timezone_utc: 'UTC',
  timezones: 'Fuseau horaire',
  tips: 'Conseils',
  title_change_only: 'Changement de titre uniquement',
  title_not_translated: "Le titre n'a pas été traduit. ",
  titles: 'Titre',
  to_be_scheduled: 'À planifier',
  to_complete_by: "À compléter d'ici",
  to_do_description: "L'employé n'a pas reçu de demande d'approbation pour la dernière version de son emploi.",
  to_do: 'À faire',
  to: 'à',
  today: "Aujourd'hui",
  todo: 'À faire',
  too_many_requirements: "Trop d'@.lower:{'plural_requirement'} de {library_type}",
  too_many: "Trop de {library_type} @.lower:{'plural_requirement'}",
  top_competencies_tooltip: 'Les compétences les plus fréquemment utilisées dans les emplois publiés.',
  top_competencies: 'Les compétences principales',
  top_skills_tooltip: 'Les aptitudes les plus fréquemment utilisées dans les emplois publiés.',
  top_skills: 'Les aptitudes principales',
  topic_question_updated: 'Question de sujet mise à jour',
  topic_questions_deleted: 'Question de sujet supprimée | Questions de sujet supprimées',
  topic_questions: 'Question de sujet | Questions de sujet',
  topics_tooltip_info: "Cliquer pour voir d'autres sujets supplémentaires.",
  topics: 'Sujet | Sujets',
  track_changes_description: "Suivre les modifications apportées à cet emploi. Afficher / masquer les modifications n'affecte que votre vue. Toutes les modifications sont automatiquement acceptées lorsque l'emploi est publié.",
  track_changes: 'Suivi des modifications',
  track_completion_of_this_job: "Suivre l'achèvement",
  track_progress_description: "Suivi l'achèvement des emplois en brouillon.",
  track_progress: 'Suivi la progression',
  tracking: 'Suivi',
  translate_in_library: 'La traduction peut être effectuée dans la bibliothèque.',
  translated_name_required: 'Veuillez ajouter du texte dans toutes les langues disponibles.',
  translation_highlighter: 'Surligneur de traduction',
  translation_requires_name: 'Pour enregistrer ce document, le titre doit être saisi dans toutes les langues disponibles. Cliquer sur Actions et sélectionner Renommer pour ajouter les traductions manquantes.',
  trust_this_device: 'Faites confiance à cet appareil pendant 30 jours',
  trusted_devices_description: "Ce sont des appareils pour lesquels vous ne serez pas invité à fournir un code A2F pour vous connecter pendant 30 jours. Vous pouvez passer en revue la liste et révoquer l'accès aux appareils que vous ne reconnaissez pas.",
  trusted_devices: 'Appareil de confiance | Appareils de confiance',
  try_again: 'Réessayer',
  turn_into_subheading: 'Transformer en sous-titre',
  turn_off_consistency: 'Désactiver la cohérence?',
  turn_off_delete_data: 'Désactiver et supprimer les données',
  turn_off_expiry_date_message: "Désactiver la date d'expiration supprimera toutes les dates d'expiration existantes pour les approbations qui n'ont pas encore expiré. Vous ne pouvez pas annuler cette action.",
  turn_off_expiry_date_title: "Désactiver la date d'expiration?",
  turn_off_feedback_message: 'Les retours seront désactivés pour les emplois utilisant ce gabarit.',
  turn_off_feedback: 'Désactiver les retours?',
  turn_off_hide_data: 'Désactiver et masquer les données',
  turn_off_job_property_sublabel: 'Cette propriété ne sera plus disponible. Le contenu et les données existants ne seront pas affectés.',
  turn_off_job_property: 'Désactiver cette propriété',
  turn_off_library_and_delete_data_sublabel: 'Cette bibliothèque ne sera plus disponible. Les données seront supprimées des brouillons et des emplois publiés concernés. Si installées, les données de développement peuvent également être affectées. En savoir plus.',
  turn_off_library_and_delete_data: 'Désactiver la bibliothèque et supprimer les données',
  turn_off_library_sublabel: 'Cette bibliothèque ne sera plus disponible. Le contenu et les données existants ne seront pas affectés.',
  turn_off_library: 'Désactiver la bibliothèque',
  turn_off_name: 'Désactiver {name}?',
  turn_off_properties_confirmation_message: "Cette propriété sera désactivée. Il n'y a pas d'emplois publiés qui incluent cette propriété.",
  turn_off_properties_with_jobs_confirmation_message: 'Cette propriété sera désactivée. Les données de propriété seront supprimées définitivement pour {count_published_jobs}.',
  turn_off_scale_2: "Désactiver l'échelle et supprimer les données",
  turn_off_scale_sublabel_2: 'Les données seront supprimées des brouillons et des emplois publiés. Si installées, les données de développement peuvent également être affectées. En savoir plus.',
  turn_off_scale_sublabel: 'Cette échelle ne sera plus disponible. Le contenu et les données existants ne seront pas affectés.',
  turn_off_scale: 'Désactiver cette échelle',
  turn_off_scales_confirmation_message: "Cette échelle sera désactivée. Il n'y a pas d'emplois publiés qui incluent cette échelle. ",
  turn_off_scales_with_jobs_confirmation_message: "Cette échelle sera désactivée. Les données de l'échelle seront définitivement supprimées pour {count_published_jobs}.",
  turn_off_sections_confirmation_message: "Cette section sera désactivée. Il n'y a pas d'emplois publiés avec du contenu dans cette section. ",
  turn_off_sections_with_jobs_confirmation_message: 'Cette section sera désactivée. Les données seront définitivement supprimées de {count_published_jobs}.',
  turn_off_signoffs_message: "Désactiver les approbations supprimera toutes les approbations demandées et supprimera toutes les dates d'expiration existantes. Vous ne pouvez pas annuler cette action.",
  turn_off_signoffs_title: 'Désactiver les approbations?',
  turn_off_sso_setting_description: "Empêcher les utilisateurs d'accéder à Quinto en utilisant l'authentification unique (SSO).",
  turn_off_sso_setting: "Désactiver l'authentification unique",
  turn_off_subheadings_confirmation_message: "Ce sous-titre sera désactivé. Il n'y a pas d'emplois publiés qui incluent ce sous-titre.",
  turn_off_subheadings_with_jobs_confirmation_message: 'Ce sous-titre sera désactivé et définitivement supprimé de {count_published_jobs}.',
  turn_off_template_message: "Le gabarit ne sera plus disponible pour une utilisation future, mais restera associé aux descriptions d'emploi existantes.",
  turn_off_template: 'Désactiver le gabarit?',
  turn_off_two_fa_setting_description: "Désactiver l'authentification à deux facteurs réduit considérablement la sécurité du compte.",
  turn_off_two_fa_setting_profile_description: "Désactiver l'authentification à deux facteurs réduit considérablement la sécurité de votre compte. ",
  turn_off_two_fa_setting: "Désactiver l'authentification à deux facteurs?",
  turn_off: 'Désactiver',
  turn_on_disclaimer: 'Activer le disclaimer?',
  turn_on_legal_section_message: '{section} sera ajouté à toutes les exportations PDF / Word pour les emplois utilisant ce gabarit.',
  turn_on_template_section_message: '{section} sera activé pour les emplois utilisant ce gabarit.',
  turn_on_template_section: 'Activer {section} ?',
  turn_on_tracking: 'Activer le suivi',
  turn_on: 'Activer',
  two_fa_setting_turn_on: "Vous avez activé l'authentification à deux facteurs. La prochaine fois que vous vous connecterez, nous vous enverrons un code d'authentification à {email}. ",
  two_factor_authentication_description: 'Maintenez la sécurité supplémentaire de votre compte avec une étape de connexion supplémentaire.',
  two_factor_authentication_mandatory: "L'administrateur du site a rendu l'authentification à deux facteurs obligatoire pour tous les utilisateurs.",
  two_factor_authentication_title: 'Authentification à deux facteurs',
  two_factor_authentication_verification: "Exiger des utilisateurs qu'ils saisissent un code de vérification lors de leur connexion. ",
  two_factor_authentication: 'Authentification à deux facteurs',
  type_or_paste: 'Entrer ou coller le contenu ici',
  type_to_find: 'Taper pour trouver',
  type_x: 'Type : {type}',
  types_updated: 'Type mis à jour | Types mis à jour',
  types: 'Type | Types',
  unassign_department: 'Désattribuer le département?',
  unassign_position_msg: "L'employé ne sera plus lié à ce poste et le poste deviendra vacant.",
  unassign_position_text: "Utiles pour les départs des employés. \n Choisir cette option pour supprimer le lien entre l'employé et son poste.",
  unassign_position: 'Désattribuer le poste?',
  unassign_positions: 'Désattribuer le poste',
  unassign_questions: 'Désattribuer la question',
  unassign: 'Désattribuer',
  unauthorized_msg: "Désolé, vous n'avez pas les permissions nécessaires pour accéder à cette page. ",
  undo: 'Annuler Ctrl + Z',
  unit_minutes: 'minutes',
  unit_seconds: 'secondes',
  unsaved_changes_banner: 'Pour sauvegarder vos dernières modifications, {clickHere}',
  unused_questions: 'Questions inutilisées',
  upcoming_review: 'Révision à venir',
  update_added: 'Mise à jour ajoutée au site',
  update_additional_information_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_additional_information: "Modifier @.lower:{'additional_information'}",
  update_architecture_display: "Mettre à jour l'affichage de l'architecture",
  update_architecture_message_with_jobs: "L'architecture sera mise à jour sur {count} emplois publiés",
  update_architecture_message: "L'architecture sera mise à jour",
  update_certifications_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_certifications: "Modifier @.lower:{'certifications'}",
  update_competency_published_msg: 'Les mises à jour prendront effet lorsque cela sera publié.',
  update_education_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_educations: "Modifier @.lower:{'educations'}",
  update_experiences_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_experiences: "Modifier @.lower:{'experiences'}",
  update_jobs: "Mettre à jour l'emploi",
  update_languages_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_languages: "Modifier @.lower:{'languages'}",
  update_position_title_text: "Utile pour les mises à jour de poste qui n'affectent pas les relations hiérarchiques.\n Choisir cette option pour mettre à jour le titre actuel de l'employé.",
  update_position_title: 'Mettre à jour le titre du poste',
  update_property_level_message: 'Ce niveau sera mis à jour sur {count_published_jobs}.',
  update_property_option_message: 'Cette option sera mise à jour sur {count_published_jobs}.',
  update_responsibilities_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_responsibility_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_responsibility: "Modifier @.lower:{'responsibilities'}",
  update_scale_level_message: 'Ce niveau sera mis à jour sur {count_published_jobs}.',
  update_schedule: 'Modifier la planification',
  update_skill_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_skill: "Modifier @.lower:{'skills'}",
  update_status_emails_subtext: 'Adresses courriel séparées par des virgules.',
  update_status_emails: "Mettre à jour les courriels d'état",
  update_subheadings_message: 'Ce sous-titre sera mis à jour sur {count_published_jobs}.',
  update_template_section_message: 'Cette section sera mise à jour sur {count_published_jobs}.',
  update_working_conditions_msg: 'Cet élément sera mis à jour sur {count_published_jobs}.',
  update_working_conditions: "Modifier @.lower:{'working_conditions'}",
  update_your_profile_picture: 'Mettre à jour votre photo de profil',
  update: 'Mettre à jour',
  updated_by_name: 'Mise à jour par {name}',
  updated_by_system: 'Mise à jour par le système',
  updated_description: "L'emploi a été republié depuis la dernière approbation. Une nouvelle approbation peut être nécessaire.",
  updated_due_date: "Date d'échéance mise à jour",
  updated_email_notification_settings: 'Paramètres de notification par courriel mis à jour',
  updated_invitation: 'Invitation mise à jour',
  updated_section_status: 'Statut de la section mis à jour',
  updated_status: 'Statut mis à jour',
  updated: 'Mis à jour',
  updating_system: 'Mise à jour du système',
  updating_your_jobs: 'Nous mettons à jour vos emplois…',
  upload_a_file: 'Télécharger un fichier',
  upload_a_new_file: 'Télécharger un nouveau fichier',
  upload_a_new_photo: 'Télécharger une nouvelle photo',
  upload_a_photo: 'Télécharger une photo',
  url_invalid: "URL invalide. Doit commencer par 'http://' ou 'https://'",
  url: 'URL',
  usage: 'Utilisation',
  use_content_finder: 'Utiliser le recherche de contenu',
  use_due_date_description: "Utiliser la date d'échéance pour aider à hiérarchiser les mises à jour du poste.",
  use_due_date: "Suivre la date d'échéance",
  use_equal_item_weighting_for_job_matches: "Utiliser une pondération égale pour les correspondances d'emploi",
  use_time_zone_specified: 'Nous utiliserons le fuseau horaire spécifié dans les paramètres du compte.',
  used: 'Utilisé',
  useful_links_description: 'Fournir des liens utiles pour un accès rapide.',
  useful_links_widget: 'Widget de liens utiles',
  useful_links: 'Liens utiles',
  user_deactivated: 'Utilisateur désactivé',
  user_defaults_sub: 'Définir les valeurs par défaut pour tous les nouveaux utilisateurs ajoutés à votre compte.',
  user_defaults: "Paramètres par défaut de l'utilisateur",
  user_deleted: 'Utilisateur supprimé',
  user_details: "Détails de l'utilisateur",
  user_exception: 'Exceptions des utilisateurs',
  user_groups: "Groupes d'utilisateurs",
  user_management: 'Gestion des utilisateurs',
  user_permission_sets: 'Utilisateurs - Ensembles de permissions',
  user_positions: 'Positions des utilisateurs',
  user: 'Utilisateur',
  users_activated: 'Utilisateur activé | Utilisateurs activés',
  users_can_hide_welcome_message: 'Les utilisateurs peuvent masquer le message de bienvenue',
  users_cannot_hide_welcome_message: 'Les utilisateurs ne peuvent pas masquer le message de bienvenue',
  users_deactivated: 'Utilisateur désactivé | Utilisateurs désactivés',
  users_deleted: 'Utilisateur supprimé | Utilisateurs supprimés',
  users_will_be_added: '{count} utilisateur sera ajouté | {count} utilisateurs seront ajoutés',
  users_will_be_updated: '{count} utilisateur sera mis à jour | {count} utilisateurs seront mis à jour',
  users_will_not_imported: '{count} utilisateur ne sera pas importé | {count} utilisateurs ne seront pas importés',
  users_will_partially_imported: '{count} utilisateur sera partiellement importé | {count} utilisateurs seront partiellement importés',
  users_with_the_review_administrator_permission: 'Utilisateurs ayant la permission de gérer les examens',
  users_with_the_review_result_visibility_administrator_permission: "Utilisateur ayant la permission d'administrateur d'examen",
  users_without_positions: 'Utilisateurs sans poste',
  users: 'Utilisateur | Utilisateurs',
  vacant_positions: 'Postes vacants',
  vacant: 'Vacant',
  validated_items: 'Éléments validés',
  verification_code_error_msg: 'Vous avez saisi un code incorrect ou expiré. Veuillez réessayer ou tenter de vous connecter à nouveau dans quelques minutes.',
  verification_code_msg: 'Veuillez saisir votre code de vérification à six chiffres.',
  version_number: 'Version {number}',
  versions: 'Version',
  view_all_ratings: 'Voir toutes les évaluations',
  view_all: 'Voir tout',
  view_archives: "Voir l'archive",
  view_assessments: 'Voir les évaluations',
  view_changes: 'Voir les modifications apportées à cette tâche.',
  view_comment: 'Voir le commentaire',
  view_comments: 'Voir le commentaire | Voir les commentaires',
  view_competency_details: 'Voir les détails de compétence',
  view_competency: "Voir @.lower:{'competencies'}",
  view_details: 'Voir les détails',
  view_draft_version: 'Voir la version brouillon',
  view_edit_tags: 'Voir / modifier les balises',
  view_error_counts: 'Voir {count} erreur | Voir {count} erreurs',
  view_feedback: 'Voir les retours',
  view_in_org_chart: "Voir dans l'organigramme",
  view_inherited_owners: 'Voir les propriétaires hérités.',
  view_instructions: 'Voir les instructions',
  view_job_description: "Voir la description d'emploi",
  view_job_family_competencies: "Voir les @.lower:{'plural_competencies'} de la famille d'emplois.",
  view_more: 'Voir plus',
  view_org_chart: "Voir l'organigramme",
  view_published_version: 'Voir la version publiée',
  view_questionnaire: 'Voir le questionnaire',
  view_rating_scale_details: 'Voir les détails de l’échelle de notation',
  view_read_only_version: 'Voir la version en lecture seule',
  view_reference_competencies: "Voir les @.lower:{'plural_competencies'} de référence",
  view_results: 'Voir les résultats',
  view_reviews: 'Voir les évaluations',
  view_scorecards: "Voir la fiche d'évaluation",
  view_settings: 'Voir les paramètres',
  view_survey_details: 'Voir les détails du sondage',
  view_team: "Voir l'équipe de {name}",
  view_timeline: 'Voir la chronologie',
  view_your_assessment: 'Voir votre évaluation',
  view_your_scorecard: "Voir votre fiche d'évaluation",
  view: 'Voir',
  viewed: 'Consulté',
  viewing_error_counts: 'Consultation de {count} erreur | Consultation de {count} erreurs',
  viewing_now: 'Visualisation en cours',
  viewpoint_comparison_agree_percentage: '{person1} et {person2} sont d’accord <span class="text-orange-2">{percentage}%</span> du temps.',
  viewpoint_comparison: 'Comparaison de points de vue',
  viewpoints: 'Points de vue',
  visibility_description: 'Autoriser les utilisateurs à masquer le message de bienvenue.',
  visibility_sharing_options: 'Options de visibilité et de partage',
  visibility: 'Visibilité',
  visible_columns: 'Colonnes visibles',
  waiting_for_job_title: "En attente d'un titre d'emploi…",
  warehouse_add_certifications: 'Ajouter certification? | Ajouter certifications?',
  warehouse_add_competencies: 'Ajouter compétence? | Ajouter compétences?',
  warehouse_add_education: 'Ajouter éducation? | Ajouter éducations?',
  warehouse_add_educations: 'Ajouter éducation? | Ajouter éducations?',
  warehouse_add_jobs: "Ajouter l'emploi? | Ajouter les emplois?",
  warehouse_add_msg_certifications: 'Cet élément sera ajouté à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau. | Ces éléments seront ajoutés à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau.',
  warehouse_add_msg_competencies: "Cet article sera ajouté à votre bibliothèque. Les articles existants sur votre site ne seront ajoutés à nouveau que s'ils sont étiquetés comme mis à jour dans l'entrepôt.| Ces articles seront ajoutés à votre bibliothèque. Les articles existants sur votre site ne seront ajoutés à nouveau que s'ils sont étiquetés comme mis à jour dans l'entrepôt.",
  warehouse_add_msg_education: 'Cet élément sera ajouté à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau. | Ces éléments seront ajoutés à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau.',
  warehouse_add_msg_educations: 'Cet élément sera ajouté à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau. | Ces éléments seront ajoutés à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau.',
  warehouse_add_msg_jobs: 'Cet emploi sera ajouté à votre site. | Ces emplois seront ajoutés à votre site.',
  warehouse_add_msg_questions: 'Cette question sera ajoutée à votre bibliothèque. Toute question déjà présente sur votre site ne sera pas ajoutée à nouveau. | Ces questions seront ajoutées à votre bibliothèque. Toute question déjà présente sur votre site ne sera pas ajoutée à nouveau.',
  warehouse_add_msg_responsibilities: 'Cet élément sera ajouté à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau. | Ces éléments seront ajoutés à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau.',
  warehouse_add_msg_skill: 'Cet élément sera ajouté à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau. | Ces éléments seront ajoutés à votre bibliothèque. Tout élément déjà présent sur votre site ne sera pas ajouté à nouveau.',
  warehouse_add_questions: 'Ajouter la question? | Ajouter les questions?',
  warehouse_add_responsibilities: 'Ajouter responsabilité? | Ajouter responsabilités?',
  warehouse_add_skill: 'Ajouter aptitude? | Ajouter aptitudes',
  warehouse_adding_items: 'Ajout de vos {resource}…',
  warehouse_additional_information: 'Information supplémentaire | Information supplémentaire',
  warehouse_certifications: 'Certification | Certifications',
  warehouse_competencies: 'Compétence | Compétences',
  warehouse_educations: 'Éducation | Éducation',
  warehouse_experiences: 'Expérience | Expérience',
  warehouse_interview_questions: "Entrepôt : Questions d'entrevue",
  warehouse_job_code: "Code d'emploi",
  warehouse_job_group: 'Groupe',
  warehouse_job_level: "Niveaux d'emploi",
  warehouse_job_type: "Type d'emploi",
  warehouse_jobs: 'Entrepôt : Emploi | Entrepôt : Emplois',
  warehouse_languages: 'Langue | Langues',
  warehouse_last_published: 'Publié',
  warehouse_libraries_certifications: 'Entrepôt : Bibliothèques - Certifications',
  warehouse_libraries_competencies: 'Entrepôt : Bibliothèques - Compétences',
  warehouse_libraries_educations: 'Entrepôt : Bibliothèques - Éducation',
  warehouse_libraries_responsibilities: 'Entrepôt : Bibliothèques - Responsabilités',
  warehouse_libraries_skills: 'Entrepôt : Bibliothèques - Aptitudes',
  warehouse_locations: 'Lieux',
  warehouse_reimport_competency_duplicate: 'Créer une nouvelle compétence',
  warehouse_reimport_competency_overwrite: 'Remplacer la version existante',
  warehouse_reimport_competency: 'Cette compétence a déjà été ajoutée à votre site. Que souhaitez-vous faire?',
  warehouse_reports_to: 'Rend compte à',
  warehouse_responsibilities: 'Responsabilité | Responsabilités',
  warehouse_salary: 'Salaire',
  warehouse_settings_msg: "Définir ce qu'il faut inclure lors de l'importation des compétences à partir de l'entrepôt.",
  warehouse_settings: "Paramètres de l'entrepôt",
  warehouse_skills: 'Aptitude | Aptitudes',
  warehouse_working_conditions: 'Condition de travail | Conditions de travail',
  warehouse: 'Entrepôt',
  we_have_check_x_record_for_updates: "Nous avons vérifié l'enregistrement utilisateur de {subjectFirstName} pour des mises à jour.",
  website: 'Site Web',
  welcome_message_widgets: 'Widget de bienvenue',
  welcome_messages_description: 'Définir un message de bienvenue pour les utilisateurs du logiciel.',
  welcome_messages: 'Message de bienvenue',
  welcome_page: "Page d'accueil",
  what_to_look_for: "Ce qu'il faut rechercher",
  whats_new: 'Quoi de neuf',
  why: 'Pourquoi?',
  word: 'Word',
  workflows: 'Flux de travail',
  working_conditions_archive: "Archive: @:{'plural_working_conditions'}",
  working_conditions_archived: 'Élément archivé | Éléments archivés',
  working_conditions_created: 'Élément créé | Éléments créés',
  working_conditions_deleted: 'Élément supprimé | Éléments supprimés',
  working_conditions_published: 'Élément publié | Éléments publiés',
  working_conditions_restored: 'Élément restauré | Éléments restaurés',
  working_conditions_updated: "Mis à jour : @.lower:{'singular_working_conditions'} | Mis à jour : @.lower:{'plural_working_conditions'}",
  working_conditions: "@:{'singular_working_conditions'} | @:{'plural_working_conditions'}",
  x_capabilities: 'Les capacités de {name}',
  x_interview: 'Entrevue de {name}',
  x_job_match: "La correspondance d'emploi actuelle de {name}",
  x_overview: "L'aperçu de {name}",
  x_possessive: 'De {name}',
  x_ratings: 'Les notations de {name}',
  x_reviews: 'Les évaluations de {name}',
  xlsx_or_csv_up_to_5_mb: "XLSX ou CSV jusqu'à 5 Mo",
  yes_import_file: 'Oui, importer mon fichier',
  yes: 'Oui',
  yesterday: 'Hier',
  you_are_all_caught_up: 'Vous êtes au courant!',
  you_are_all_upto_date: 'Vous êtes à jour',
  you_are_logged_in_as: 'Vous êtes connecté en tant que {name}.',
  you_have_been_logged_out_due_to_inactivity: "Vous avez été déconnecté en raison d'une inactivité.",
  you_have_no_job_description_yet: "Vous n'avez pas encore de description d'emploi.",
  you_have_tasks_to_do: 'Vous avez {count} tâches à effectuer',
  you_will_automatically_be_logged_out_30_min: "Pour des raisons de sécurité, vous êtes sur le point d'être déconnecté en raison d'une inactivité.",
  your_file_data: 'Les données de votre fichier',
  your_profile: 'Ton profil',
  your_self_reflection: 'Votre réflexion personnelle'
};
