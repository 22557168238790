const getEndpoint = (url, method) => ({ url, method });
const getPostEndpoint = url => getEndpoint(url, 'post');
const getPatchEndpoint = url => getEndpoint(url, 'patch');
const getGetEndpoint = url => getEndpoint(url, 'get');
const getDeleteEndpoint = url => getEndpoint(url, 'delete');

const getCRUDEndpoints = (url, id = 'id') => ({
  index: getGetEndpoint(url),
  store: getPostEndpoint(url),
  show: getGetEndpoint(`${url}/{${id}}`),
  update: getPatchEndpoint(`${url}/{${id}}`),
  delete: getDeleteEndpoint(`${url}/{${id}}`)
});

export default {
  teams: {
    ...getCRUDEndpoints('teams/my-team'),
    'position-team': getGetEndpoint('teams/position-team/{positionId}'),
    reviews: getGetEndpoint('teams/team-member/{userId}/assessments'),
    capabilities: getGetEndpoint('teams/team-member/{userId}/capabilities'),
    'job-match': getGetEndpoint('teams/team-member/{userId}/job-match'),
    overview: getGetEndpoint('teams/team-member/{userId}/overview')
  },
  analytics: {
    summary: getGetEndpoint('analytics/summary'),
    'top-skills': getGetEndpoint('analytics/top-skills'),
    'top-competencies': getGetEndpoint('analytics/top-competencies'),
    'summary-refresh': getGetEndpoint('analytics/summary-refresh'),
    competencies: getGetEndpoint('analytics/level-analysis-competencies'),
    skills: getGetEndpoint('analytics/level-analysis-skills')
  },
  platform: {
    authentication: {
      'get-login-type': getPostEndpoint('user/login-type-check'),
      login: getPostEndpoint('user/login'),
      'login-sso': getPostEndpoint('user/login-sso'),
      'resend-2fa-code': getPostEndpoint('user/resend-2fa-code'),
      logout: getPostEndpoint('user/logout'),
      'password-reset': getPostEndpoint('password/reset'),
      'password-request-reset': getPostEndpoint('password/request-reset'),
      'password-change': getPostEndpoint('password/change'),
      'send-otp': getPostEndpoint('user/send-otp')
    },
    homepage: {
      index: getGetEndpoint('homepage'),
      'toggle-welcome': getGetEndpoint('homepage/welcome-message-1/toggle-display')
    },
    'my-account': {
      permissions: getGetEndpoint('user/permissions'),
      profile: getGetEndpoint('user/profile'),
      'stay-logged-in': getGetEndpoint('user/stay-logged'),
      'remove-trusted-device': getDeleteEndpoint('user/remove-trusted-device'),
      'update-settings': getPatchEndpoint('user/update-settings'),
      'update-email-notifications': getPatchEndpoint('user/update-email-notifications'),
      'update-avatar': getPostEndpoint('user/update-avatar'),
      integrations: getGetEndpoint('user/integrations'),
      'initiate-integration': getGetEndpoint('user/initiate-integration'),
      'delete-integration': getDeleteEndpoint('user/integration/{id}'),
      positions: getGetEndpoint('user/positions'),
      signoffs: getGetEndpoint('user/signoffs'),
      assessments: getGetEndpoint('user/assessments')
    },
    tasks: {
      index: getGetEndpoint('user/tasks'),
      update: getPatchEndpoint('user/tasks/{id}/update-status'),
      star: getPatchEndpoint('user/tasks/{id}/toggle-star')
    },
    files: {
      show: getGetEndpoint('files/show/{id}'),
      download: getGetEndpoint('files/download/{id}'),
      downloadUuid: getGetEndpoint('files/uuid-download/{uuid}'),
      delete: getDeleteEndpoint('files/delete/{id}')
    },
    'user-column-settings': {
      'store-multiple': getPostEndpoint('/user/column-settings/store-multiple'),
      'update-multiple': getPatchEndpoint('/user/column-settings/update-multiple')
    },
    notifications: {
      index: getGetEndpoint('user/notifications'),
      seen: getPatchEndpoint('user/notifications/seen'),
      'toggle-read': getPatchEndpoint('user/notifications/toggle-read/{id}'),
      'read-all': getPatchEndpoint('user/notifications/read/all'),
      'job-status': getGetEndpoint('user/notifications/job-statuses/{ids}')
    },
    'ai-code': getPostEndpoint('/ai-suggestions/{code}')
  },
  administration: {
    'ui-strings': {
      update: getPostEndpoint('administration/ui-strings'),
      delete: getDeleteEndpoint('administration/ui-strings')
    },
    'account-defaults': getPostEndpoint('administration/account-defaults'),
    'architecture-competencies': getCRUDEndpoints('administration/architecture-competencies'),
    'system-settings': {
      index: getGetEndpoint('administration/system-settings'),
      update: getPatchEndpoint('administration/system-settings/{code}')
    },
    'content-providers': getCRUDEndpoints('administration/content-providers'),
    departments: getCRUDEndpoints('administration/departments'),
    'job-levels': {
      ...getCRUDEndpoints('administration/job-levels'),
      reorder: getPostEndpoint('administration/job-levels/reorder-positions')
    },
    'homepage-widgets': {
      index: getGetEndpoint('administration/homepage-widgets'),
      show: getGetEndpoint('administration/homepage-widgets/{id}'),
      update: getPatchEndpoint('administration/homepage-widgets/{id}'),
      reorder: getPostEndpoint('administration/homepage-widgets/reorder-positions')
    },
    'job-properties': {
      ...getCRUDEndpoints('administration/job-properties'),
      options: {
        ...getCRUDEndpoints('administration/job-properties/{property_id}/options'),
        reorder: getPostEndpoint('administration/job-properties/{property_id}/options/reorder-positions')
      }
    },
    'interview-types': getCRUDEndpoints('administration/interview-types'),
    'candidacy-statuses': {
      ...getCRUDEndpoints('administration/candidacy-statuses'),
      reorder: getPostEndpoint('administration/candidacy-statuses/reorder-positions')
    },
    'default-interview-rounds': {
      ...getCRUDEndpoints('administration/default-interview-rounds'),
      reorder: getPostEndpoint('administration/default-interview-rounds/reorder-positions')
    },
    'job-salaries': {
      ...getCRUDEndpoints('administration/job-salaries'),
      reorder: getPostEndpoint('administration/job-salaries/reorder-positions')
    },
    'job-templates': {
      ...getCRUDEndpoints('administration/job-templates'),
      'set-default': getGetEndpoint('administration/job-templates/{id}/set-default'),
      'duplicate-template': getGetEndpoint('administration/job-templates/{id}/duplicate'),
      'update-status': getPatchEndpoint('administration/job-templates/{id}/update-status'),
      'update-logo': getPostEndpoint('administration/job-templates/{id}/update-logo'),
      contents: {
        ...getCRUDEndpoints('administration/job-templates/{job_template_id}/contents'),
        reorder: getPostEndpoint('administration/job-templates/{job_template_id}/contents/reorder-positions'),
        updateContents: getPatchEndpoint('administration/job-templates/{job_template_id}/contents/{content_id}'),
        'usage-counts': getGetEndpoint('administration/job-templates/{template_id}/contents/{id}/usage-counts')
      },
      properties: {
        ...getCRUDEndpoints('administration/job-templates/{job_template_id}/properties'),
        reorder: getPostEndpoint('administration/job-templates/{job_template_id}/properties/reorder-positions'),
        'usage-counts': getGetEndpoint('administration/job-templates/{template_id}/properties/{id}/usage-counts'),
        'subheadings-usage-counts': getGetEndpoint('administration/job-templates/{template_id}/property-subheadings/{id}/usage-counts')
      },
      display: getCRUDEndpoints('administration/job-templates/{job_template_id}/display'),
      subheadings: getCRUDEndpoints('administration/job-templates/{job_template_id}/property-subheadings'),
      guideline: {
        ...getCRUDEndpoints('administration/job-templates/{job_template_id}/contents/{content_id}/guidelines/{id}'),
        updateGuideline: getPatchEndpoint('administration/job-templates/{job_template_id}/contents/{content_id}/guidelines/{id}')
      }
    },
    'job-types': {
      ...getCRUDEndpoints('administration/job-types'),
      reorder: getPostEndpoint('administration/job-types/reorder-positions')
    },
    locations: getCRUDEndpoints('administration/locations'),
    logs: {
      index: getGetEndpoint('administration/logs'),
      'search-users': getGetEndpoint('administration/logs/search-users')
    },
    'pending-job-updates': {
      index: getGetEndpoint('administration/pending-job-updates'),
      status: getGetEndpoint('administration/pending-job-update-status'),
      initiate: getPostEndpoint('administration/initiate-job-updates')
    },
    'permission-sets': getCRUDEndpoints('administration/permission-sets'),
    positions: {
      ...getCRUDEndpoints('administration/positions'),
      'assign-department': getPostEndpoint('administration/positions/{id}/assign-department'),
      'assign-permission-sets': getPostEndpoint('administration/positions/{id}/assign-permission-set'),
      'search-users-positions': getGetEndpoint('administration/positions/search-users-positons'),
      'update-job': getPatchEndpoint('administration/positions/{id}/update-job')
    },
    scales: {
      ...getCRUDEndpoints('administration/scales'),
      education: {
        ...getCRUDEndpoints('content/education-levels'),
        reorder: getPostEndpoint('content/education-levels/reorder-positions')
      },
      skill: {
        ...getCRUDEndpoints('content/skill-levels'),
        reorder: getPostEndpoint('content/skill-levels/reorder-positions')
      },
      language: {
        ...getCRUDEndpoints('content/language-levels'),
        reorder: getPostEndpoint('content/language-levels/reorder-positions')
      },
      proficiency: getCRUDEndpoints('content/proficiency-levels'),
      'interview-guide': {
        ...getCRUDEndpoints('administration/interview-guide-levels/{type}/levels'),
        reorder: getPostEndpoint('administration/interview-guide-levels/{type}/levels/reorder-positions')
      },
      'indepth-assessment': {
        ...getCRUDEndpoints('administration/indepth-assessment-levels'),
        reorder: getPostEndpoint('administration/indepth-assessment-levels/reorder-positions'),
        'set-target': getPostEndpoint('administration/indepth-assessment-levels/set-target')
      }
    },
    integration: {
      integrations: getGetEndpoint('administration/integrations'),
      initiate: getPostEndpoint('administration/integrations/initiate'),
      'update-token': getPostEndpoint('administration/integrations/update-token'),
      reset: getPostEndpoint('administration/integrations/reset'),
      'synchronize-merge': getPostEndpoint('administration/integrations/synchronize-merge'),
      groups: getGetEndpoint('administration/integrations/groups'),
      update: getPatchEndpoint('administration/integrations/update')
    },
    users: {
      ...getCRUDEndpoints('administration/users'),
      departments: getGetEndpoint('administration/users/{id}/departments'),
      anonymize: getPostEndpoint('administration/users/{id}/anonymize'),
      invite: getGetEndpoint('administration/users/{id}/invite'),
      personify: getPostEndpoint('administration/users/{id}/personify'),
      'end-personify': getGetEndpoint('administration/users/{id}/end-personify'),
      'reset-password': getGetEndpoint('administration/users/{id}/reset-password'),
      'index-deleted': getGetEndpoint('administration/users/deleted'),
      restore: getGetEndpoint('administration/users/{id}/restore'),
      'assign-manager': getPostEndpoint('administration/users/{id}/assign-manager'),
      'assign-department': getPostEndpoint('administration/users/{id}/assign-department'),
      'assign-permission-sets': getPostEndpoint('administration/users/{id}/assign-permission-set'),
      'positions-check': getPostEndpoint('administration/users/positions-check'),
      'set-status': getPostEndpoint('administration/users/set-status'),
      'set-type': getPostEndpoint('administration/users/set-type'),
      'license-availability-check': getPostEndpoint('administration/users/license-availability-check'),
      'export-data': getGetEndpoint('administration/users/{id}')
    },
    'org-imports': {
      upload: getPostEndpoint('administration/org-imports/upload'),
      'current-mapping': getGetEndpoint('administration/org-imports/current-mapping'),
      'update-mapping': getPatchEndpoint('administration/org-imports/update-mapping'),
      'uploaded-statuses': getGetEndpoint('administration/org-imports/uploaded-statuses'),
      'update-settings': getPatchEndpoint('administration/org-imports/update-settings'),
      analyze: getGetEndpoint('administration/org-imports/analyze'),
      import: getPostEndpoint('administration/org-imports/import')
    },
    'user-positions': {
      ...getCRUDEndpoints('administration/user-positions'),
      replace: getPostEndpoint('administration/user-positions/{id}/replace')
    },
    'widget-useful-links': {
      ...getCRUDEndpoints('administration/widget-useful-links'),
      reorder: getPostEndpoint('administration/widget-useful-links/reorder-positions')
    },
    'widget-core-competencies': {
      index: getGetEndpoint('administration/widget-core-competencies'),
      store: getPostEndpoint('administration/widget-core-competencies'),
      show: getGetEndpoint('administration/widget-core-competencies/{id}'),
      update: getPatchEndpoint('administration/widget-core-competencies/{id}'),
      'update-color': getPostEndpoint('administration/widget-core-competencies/update-colour')
    },
    'widget-welcome-messages': {
      index: getGetEndpoint('administration/widget-welcome-messages-1'),
      store: getPostEndpoint('administration/widget-welcome-messages-1'),
      show: getGetEndpoint('administration/widget-welcome-messages-1/{id}'),
      update: getPatchEndpoint('administration/widget-welcome-messages-1/{id}')
    },
    'general-information': getGetEndpoint('administration'),
    'user-defaults': {
      show: getGetEndpoint('administration/user-defaults'),
      update: getPatchEndpoint('administration/user-defaults')
    },
    communications: getCRUDEndpoints('administration/communications'),
    'api-keys': getCRUDEndpoints('administration/api-keys')
  },
  content: {
    'interview-questions': {
      ...getCRUDEndpoints('content/interview-questions'),
      'store-multiple': getPostEndpoint('content/interview-questions/store-multiple'),
      duplicate: getGetEndpoint('content/interview-questions/{id}/duplicate'),
      topics: getCRUDEndpoints('content/interview-question-topics'),
      followups: {
        ...getCRUDEndpoints('content/interview-questions/{interview_question_id}/followups'),
        reorder: getPostEndpoint('content/interview-questions/{interview_question_id}/followups/reorder-positions'),
        'update-multiple': getPatchEndpoint('content/interview-questions/{interview_question_id}/followups/update-multiple'),
        'store-multiple': getPostEndpoint('content/interview-questions/{interview_question_id}/followups/store-multiple')
      }
    },
    'interview-guides': {
      ...getCRUDEndpoints('interviews/interview-guides'),
      publish: getGetEndpoint('interviews/interview-guides/{id}/publish'),
      duplicate: getGetEndpoint('interviews/interview-guides/{id}/duplicate'),
      pdf: getGetEndpoint('interviews/interview-guides/{id}/pdf'),
      docx: getGetEndpoint('interviews/interview-guides/{id}/docx'),
      'save-as-template': getPostEndpoint('interviews/interview-guides/{id}/save-as-template'),
      'used-question-ids': getGetEndpoint('interviews/interview-guides/{id}/used-question-ids'),
      groups: getCRUDEndpoints('interviews/interview-guide-groups'),
      questions: {
        ...getCRUDEndpoints('interviews/interview-guides/{interview_guide_id}/questions'),
        reorder: getPostEndpoint('interviews/interview-guides/{interview_guide_id}/questions/reorder-positions'),
        'store-multiple': getPostEndpoint('interviews/interview-guides/{interview_guide_id}/questions/store-multiple'),
        'competency-questions': getGetEndpoint('interviews/interview-guides/{interview_guide_id}/available-competency-questions')
      },
      levels: {
        ...getCRUDEndpoints('interviews/interview-guide-levels'),
        reorder: getPostEndpoint('interviews/interview-guide-levels/reorder-positions')
      },
      communications: {
        ...getCRUDEndpoints('interviews/interview-guide-communications'),
        'set-default': getPatchEndpoint('interviews/interview-guide-communications/{id}/set-default')
      }
    },
    tags: {
      ...getCRUDEndpoints('content/tags'),
      'tag-item': getPostEndpoint('content/tags/tag-item'),
      'untag-item': getDeleteEndpoint('content/tags/untag-items/{id}')
    },
    'learning-resource-types': getCRUDEndpoints('content/learning-resource-types'),
    'learning-resource': getCRUDEndpoints('content/learning-resource'),
    'responsibility-groups': getCRUDEndpoints('content/responsibility-groups'),
    responsibilities: {
      ...getCRUDEndpoints('content/responsibilities'),
      'check-duplicates': getPostEndpoint('content/responsibilities/check-duplicates'),
      'store-multiple': getPostEndpoint('content/responsibilities/store-multiple'),
      publish: getGetEndpoint('content/responsibilities/{id}/publish'),
      archive: getGetEndpoint('content/responsibilities/{id}/archive'),
      restore: getGetEndpoint('content/responsibilities/{id}/restore'),
      'job-ids': getGetEndpoint('content/responsibilities/{id}/job-ids')
    },
    'competency-groups': getCRUDEndpoints('content/competency-groups'),
    'competency-levels': {
      ...getCRUDEndpoints('content/competencies/{competency_id}/levels'),
      reorder: getPostEndpoint('content/competencies/{competency_id}/levels/reorder-positions')
    },
    competencies: {
      ...getCRUDEndpoints('content/competencies'),
      'check-duplicates': getPostEndpoint('content/competencies/check-duplicates'),
      'store-multiple': getPostEndpoint('content/competencies/store-multiple'),
      publish: getGetEndpoint('content/competencies/{id}/publish'),
      archive: getGetEndpoint('content/competencies/{id}/archive'),
      restore: getGetEndpoint('content/competencies/{id}/restore'),
      edit: getGetEndpoint('content/competencies/{id}/edit'),
      pdf: getGetEndpoint('content/competencies/{id}/pdf'),
      'multiple-pdf': getPostEndpoint('content/competencies/pdf'),
      'multiple-docx': getPostEndpoint('content/competencies/docx'),
      docx: getGetEndpoint('content/competencies/{id}/docx'),
      'job-ids': getGetEndpoint('content/competencies/{id}/job-ids'),
      duplicate: getGetEndpoint('content/competencies/{id}/duplicate'),
      move: getPatchEndpoint('content/competencies/{id}/move'),
      'job-comparisons': getGetEndpoint('content/competencies/job-comparisons/{id}')
    },
    'behavioral-indicators': {
      ...getCRUDEndpoints('content/competencies/{competency_id}/levels/{competency_level_id}/behavioral-indicators'),
      reorder: getPostEndpoint('content/competencies/{competency_id}/levels/{competency_level_id}/behavioral-indicators/reorder-positions')
    },
    skills: {
      ...getCRUDEndpoints('content/skills'),
      'check-duplicates': getPostEndpoint('content/skills/check-duplicates'),
      'store-multiple': getPostEndpoint('content/skills/store-multiple'),
      publish: getGetEndpoint('content/skills/{id}/publish'),
      archive: getGetEndpoint('content/skills/{id}/archive'),
      'job-ids': getGetEndpoint('content/skills/{id}/job-ids'),
      restore: getGetEndpoint('content/skills/{id}/restore'),
      'job-comparisons': getGetEndpoint('content/skills/job-comparisons/{id}')
    },
    educations: {
      ...getCRUDEndpoints('content/educations'),
      publish: getGetEndpoint('content/educations/{id}/publish'),
      archive: getGetEndpoint('content/educations/{id}/archive'),
      restore: getGetEndpoint('content/educations/{id}/restore'),
      'job-ids': getGetEndpoint('content/educations/{id}/job-ids'),
      'check-duplicates': getPostEndpoint('content/educations/check-duplicates'),
      'store-multiple': getPostEndpoint('content/educations/store-multiple')
    },
    certifications: {
      ...getCRUDEndpoints('content/certifications'),
      publish: getGetEndpoint('content/certifications/{id}/publish'),
      archive: getGetEndpoint('content/certifications/{id}/archive'),
      restore: getGetEndpoint('content/certifications/{id}/restore'),
      'job-ids': getGetEndpoint('content/certifications/{id}/job-ids'),
      'check-duplicates': getPostEndpoint('content/certifications/check-duplicates'),
      'store-multiple': getPostEndpoint('content/certifications/store-multiple')
    },
    languages: {
      ...getCRUDEndpoints('content/languages'),
      publish: getGetEndpoint('content/languages/{id}/publish'),
      archive: getGetEndpoint('content/languages/{id}/archive'),
      restore: getGetEndpoint('content/languages/{id}/restore'),
      'check-duplicates': getPostEndpoint('content/languages/check-duplicates'),
      'store-multiple': getPostEndpoint('content/languages/store-multiple'),
      'job-ids': getGetEndpoint('content/languages/{id}/job-ids')
    },
    experiences: {
      ...getCRUDEndpoints('content/experiences'),
      publish: getGetEndpoint('content/experiences/{id}/publish'),
      archive: getGetEndpoint('content/experiences/{id}/archive'),
      restore: getGetEndpoint('content/experiences/{id}/restore'),
      'check-duplicates': getPostEndpoint('content/experiences/check-duplicates'),
      'store-multiple': getPostEndpoint('content/experiences/store-multiple'),
      'job-ids': getGetEndpoint('content/experiences/{id}/job-ids')
    },
    'working-conditions': {
      ...getCRUDEndpoints('content/working-conditions'),
      publish: getGetEndpoint('content/working-conditions/{id}/publish'),
      archive: getGetEndpoint('content/working-conditions/{id}/archive'),
      restore: getGetEndpoint('content/working-conditions/{id}/restore'),
      'check-duplicates': getPostEndpoint('content/working-conditions/check-duplicates'),
      'store-multiple': getPostEndpoint('content/working-conditions/store-multiple'),
      'job-ids': getGetEndpoint('content/working-conditions/{id}/job-ids')
    },
    'additional-information': {
      ...getCRUDEndpoints('content/additional-information'),
      publish: getGetEndpoint('content/additional-information/{id}/publish'),
      archive: getGetEndpoint('content/additional-information/{id}/archive'),
      restore: getGetEndpoint('content/additional-information/{id}/restore'),
      'check-duplicates': getPostEndpoint('content/additional-information/check-duplicates'),
      'store-multiple': getPostEndpoint('content/additional-information/store-multiple'),
      'job-ids': getGetEndpoint('content/additional-information/{id}/job-ids')
    },
    'additional-information-groups': getCRUDEndpoints('content/additional-information-groups'),
    'certification-groups': getCRUDEndpoints('content/certification-groups'),
    'certification-levels': getCRUDEndpoints('content/certifications/{cert}/levels'),
    'education-groups': getCRUDEndpoints('content/education-groups'),
    'skill-groups': getCRUDEndpoints('content/skill-groups'),
    warehouse: {
      index: getGetEndpoint('content/warehouse/{type}'),
      add: getPostEndpoint('content/warehouse/{type}/add'),
      view: getGetEndpoint('content/warehouse/{type}/item/{handle}'),
      groups: getGetEndpoint('content/warehouse/{type}/groups')
    }
  },
  jobs: {
    ...getCRUDEndpoints('jobs/jobs'),
    'job-groups': {
      ...getCRUDEndpoints('jobs/job-groups'),
      contributors: getCRUDEndpoints('jobs/job-groups/{job_group_id}/contributors'),
      competencies: getCRUDEndpoints('jobs/job-groups/{job_group_id}/competencies'),
      'can-own-jobs': getGetEndpoint('jobs/job-groups/can-own-jobs')
    },
    'heavy-libraries': getGetEndpoint('/jobs/jobs/{id}/heavy-libraries'),
    'warehouse-import': getPostEndpoint('jobs/jobs/warehouse-import'),
    edit: getGetEndpoint('/jobs/jobs/{id}/edit'),
    restore: getGetEndpoint('jobs/jobs/{id}/restore'),
    'restore-archived': getGetEndpoint('jobs/jobs/{id}/restore-archived'),
    archive: getGetEndpoint('jobs/jobs/{id}/archive'),
    duplicate: getGetEndpoint('jobs/jobs/{id}/duplicate'),
    publish: getGetEndpoint('jobs/jobs/{id}/publish'),
    'incumbents-count': getPostEndpoint('jobs/jobs/incumbents-count'),
    'assign-types': getPostEndpoint('jobs/jobs/assign-types'),
    'assign-level': getPostEndpoint('jobs/jobs/assign-level'),
    move: getGetEndpoint('jobs/jobs/{id}/move'),
    'check-duplicates': getPostEndpoint('jobs/jobs/check-duplicates'),
    'due-date': getPostEndpoint('jobs/jobs/{id}/update-due-date'),
    'change-template': getPostEndpoint('jobs/jobs/{id}/update-template'),
    'toggle-track': getGetEndpoint('jobs/jobs/{id}/toggle-track-changes'),
    'get-changes': getGetEndpoint('/jobs/jobs/{id}/track-changes-file'),
    'toggle-input': getGetEndpoint('jobs/jobs/{id}/toggle-input'),
    'toggle-flagged': getGetEndpoint('jobs/jobs/{id}/toggle-flagged'),
    'toggle-starred': getGetEndpoint('jobs/jobs/{id}/toggle-star'),
    'mark-reviewed': getGetEndpoint('jobs/jobs/{id}/mark-reviewed'),
    'draft-copy-id': getGetEndpoint('jobs/jobs/{id}/draft-copy-id'),
    pdf: getPostEndpoint('jobs/jobs/{id}/pdf'),
    docx: getPostEndpoint('jobs/jobs/{id}/docx'),
    xlsx: getPostEndpoint('jobs/jobs/{id}/xlsx'),
    download: getGetEndpoint('files/download/jobs/{id}'),
    'multiple-pdf': getPostEndpoint('jobs/jobs/pdf'),
    'multiple-docx': getPostEndpoint('jobs/jobs/docx'),
    'multiple-xlsx': getPostEndpoint('jobs/jobs/xlsx'),
    'download-signoff-pdf': getGetEndpoint('jobs/signoffs/{id}/download-pdf'),
    contributors: {
      ...getCRUDEndpoints('jobs/jobs/{job_id}/contributors'),
      invite: getPostEndpoint('jobs/jobs/{job_id}/contributors/invite'),
      add: getPostEndpoint('jobs/jobs/{job_id}/contributors/store-invite-multiple'),
      'request-review': getPostEndpoint('jobs/jobs/{job_id}/contributors/{id}/request-additional-review'),
      'toggle-show-changes': getGetEndpoint('/jobs/jobs/{job_id}/contributors/toggle-show-track-changes')
    },
    feedback: getPostEndpoint('jobs/jobs/{id}/contributors/feedback'),
    'update-feedback': getPatchEndpoint('jobs/jobs/{job_id}/contributors/feedback/{feedback_id}'),
    'delete-feedback': getDeleteEndpoint('jobs/jobs/{job_id}/contributors/feedback/{feedback_id}'),
    'download-feedback': getGetEndpoint('jobs/jobs/{id}/download-feedbacks?type=PDF'),
    'get-feedbacks': getGetEndpoint('jobs/jobs/{id}/contributors/feedbacks'),
    'update-section-status': getPostEndpoint('jobs/jobs/{id}/update-section-status'),
    comments: getCRUDEndpoints('jobs/jobs/{job_id}/comments'),
    information: getCRUDEndpoints('jobs/jobs/{job_id}/information'),
    items: {
      ...getCRUDEndpoints('jobs/jobs/{job_id}/{item_type}'),
      reorder: getPostEndpoint('jobs/jobs/{job_id}/{item_type}/reorder-positions'),
      react: getCRUDEndpoints('jobs/jobs/{job_id}/reactions'),
      subitems: getCRUDEndpoints('jobs/jobs/{job_id}/{item_type}/{parent_id}/items'),
      'recognize-entities': getPostEndpoint('ai-suggestions/{code}')
    },
    subheadings: getCRUDEndpoints('jobs/jobs/{job_id}/subheadings'),
    'post-data': getGetEndpoint('jobs/jobs/{id}/post-data'),
    employees: getGetEndpoint('jobs/jobs/{id}/employees'),
    'interview-guides': getGetEndpoint('jobs/jobs/{id}/interview-guides'),
    versions: getGetEndpoint('jobs/jobs/{id}/versions'),
    'warehouse-job': getGetEndpoint('jobs/jobs/warehouse-job/{id}'),
    'ats-push': getGetEndpoint('jobs/jobs/{id}/ats-push'),
    'update-review-date': getPostEndpoint('jobs/jobs/{id}/update-review-date'),
    'summary-ai-suggestion': getPostEndpoint('/ai-suggestions/{code}'),
    'job-signoffs': getGetEndpoint('jobs/jobs/{id}/signoffs'),
    signoffs: {
      ...getCRUDEndpoints('jobs/signoffs'),
      view: getGetEndpoint('jobs/signoffs/{id}/view'),
      remind: getGetEndpoint('jobs/signoffs/{id}/remind'),
      complete: getPostEndpoint('jobs/signoffs/{id}/complete'),
      grant: getPostEndpoint('jobs/signoffs/{id}/grant'),
      'get-request-details': getGetEndpoint('jobs/signoffs/{id}/request'),
      request: getPostEndpoint('jobs/signoffs/{id}/request'),
      cancel: getGetEndpoint('jobs/signoffs/{id}/cancel-request'),
      'completed-signoffs': getGetEndpoint('jobs/signoffs/completed-signoffs'),
      'user-history': getGetEndpoint('jobs/signoffs/user/{id}'),
      download: getGetEndpoint('jobs/signoffs/{id}/download-pdf')
    },
    'job-description-import': {
      'add-import-file': getPostEndpoint('jobs/jobs/add-import-file'),
      'added-import-file': getGetEndpoint('jobs/jobs/added-import-files'),
      'parse-import-files': getPostEndpoint('jobs/jobs/parse-import-files')
    },
    'toggle-entity-recognition': getGetEndpoint('jobs/jobs/{id}/toggle-entity-recognition')
  },
  interviews: {
    openings: {
      ...getCRUDEndpoints('interviews/openings'),
      'licenses-available': getGetEndpoint('interviews/openings/licenses-available'),
      'creation-suggestions': getPostEndpoint('interviews/openings/creation-suggestions'),
      compare: getGetEndpoint('interviews/openings/{id}/compare'),
      candidacy: getGetEndpoint('interviews/openings/{id}/candidacy/{candidacyId}'),
      'update-candidacy-status': getPatchEndpoint('interviews/openings/{id}/candidacy/{candidacyId}/update-status'),
      'candidacy-comment': getCRUDEndpoints('interviews/openings/{id}/candidacy/{candidacyId}/comments'),
      'search-users': getGetEndpoint('interviews/openings/{id}/search-users')
    },
    'openings-interviewers': getCRUDEndpoints('interviews/openings/{openingId}/interviewers'),
    'interview-rounds': {
      ...getCRUDEndpoints('interviews/openings/{openingId}/interview-rounds'),
      'add-guide': getPostEndpoint('interviews/openings/{openingId}/interview-rounds/{id}/add-guide'),
      reorder: getPostEndpoint('interviews/openings/{openingId}/interview-rounds/reorder-positions'),
      'search-candidates': getPostEndpoint('interviews/openings/{openingId}/interview-rounds/{id}/search-candidates')
    },
    interviews: {
      ...getCRUDEndpoints('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews'),
      nudge: getGetEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{id}/nudge'),
      cancel: getGetEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{id}/cancel'),
      'move-forward': getGetEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{id}/move-forward'),
      invite: getPostEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{id}/invite')
    },
    'interview-interviewers': {
      ...getCRUDEndpoints('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers'),
      'reopen-scorecard': getPostEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers/{id}/reopen-scorecard'),
      'scorecard-question': getGetEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers/scorecard/question/{questionId}'),
      scorecard: getGetEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers/scorecard'),
      'save-answer': getPostEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers/scorecard'),
      submit: getPostEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers/scorecard/submit'),
      nudge: getGetEndpoint('interviews/openings/{openingId}/interview-rounds/{roundId}/interviews/{interviewId}/interviewers/{id}/nudge')
    },
    interviewers: {
      ...getCRUDEndpoints('interviews/openings/{openingId}/interviewers'),
      'available-roles': getGetEndpoint('interviews/openings/{openingId}/interviewers/{userId}/available-roles')
    },
    candidates: {
      ...getCRUDEndpoints('interviews/candidates'),
      anonymize: getGetEndpoint('interviews/candidates/{id}/anonymize'),
      exist: getGetEndpoint('interviews/candidates/exists'),
      restore: getGetEndpoint('interviews/candidates/{id}/restore')
    }
  },
  surveys: {
    ...getCRUDEndpoints('surveys/surveys'),
    'update-due-date': getPostEndpoint('surveys/surveys/{id}/update-due-date'),
    results: getGetEndpoint('surveys/surveys/{id}/results'),
    result: getGetEndpoint('surveys/surveys/{survey_id}/results/{id}'),
    start: getGetEndpoint('surveys/surveys/{id}/start'),
    download: getGetEndpoint('surveys/surveys/{id}/results/download'),
    cancel: getPostEndpoint('surveys/surveys/{id}/cancel'),
    reopen: getPostEndpoint('surveys/surveys/{id}/reopen'),
    close: getPostEndpoint('surveys/surveys/{id}/close'),
    complete: getPostEndpoint('surveys/surveys/{id}/complete'),
    schedule: getPostEndpoint('surveys/surveys/{id}/schedule'),
    communications: getCRUDEndpoints('surveys/surveys/{survey_id}/communications'),
    contents: {
      ...getCRUDEndpoints('surveys/surveys/{survey_id}/contents'),
      'store-multiple': getPostEndpoint('surveys/surveys/{survey_id}/contents/store-multiple')
    },
    recipients: {
      ...getCRUDEndpoints('surveys/surveys/{survey_id}/notification-recipients'),
      'store-multiple': getPostEndpoint('surveys/surveys/{survey_id}/notification-recipients/store-multiple')
    },
    participants: {
      ...getCRUDEndpoints('surveys/surveys/{survey_id}/participants'),
      'store-multiple': getPostEndpoint('surveys/surveys/{survey_id}/participants/store-multiple'),
      reinvite: getGetEndpoint('surveys/surveys/{survey_id}/participants/{id}/reinvite'),
      reopen: getGetEndpoint('surveys/surveys/{survey_id}/participants/{id}/reopen'),
      reset: getGetEndpoint('surveys/surveys/{survey_id}/participants/{id}/reset')
    },
    'participant-responses': getCRUDEndpoints('surveys/surveys/{survey_id}/participant-responses'),
    'response-option': getCRUDEndpoints('surveys/surveys/{survey_id}/response-options')
  },
  reviews: {
    reviews: {
      ...getCRUDEndpoints('reviews/reviews'),
      'complete-setup': getPostEndpoint('reviews/reviews/{id}/complete-setup'),
      'verify-assessments': getPostEndpoint('reviews/reviews/{id}/verify-assessments'),
      close: getPostEndpoint('reviews/reviews/{id}/close'),
      cancel: getPostEndpoint('reviews/reviews/{id}/cancel'),
      reopen: getPostEndpoint('reviews/reviews/{id}/reopen'),
      duplicate: getPostEndpoint('reviews/reviews/{id}/duplicate'),
      summary: getGetEndpoint('reviews/reviews/{id}/summary'),
      results: getGetEndpoint('reviews/reviews/{id}/results/{type}'),
      contents: getGetEndpoint('/reviews/reviews/{id}/contents'),
      'share-results': getPostEndpoint('reviews/reviews/{id}/share-results'),
      'all-participants': getGetEndpoint('reviews/reviews/{id}/all-participants'),
      'check-admin': getGetEndpoint('reviews/reviews/{id}/is-admin'),
      'competency-details': getGetEndpoint('reviews/reviews/{id}/competency/{contentId}'),
      export: getGetEndpoint('reviews/reviews/{id}/export-data/{type}'),
      'launch-now': getGetEndpoint('reviews/reviews/{id}/launch-now')
    },
    administrators: getCRUDEndpoints('reviews/reviews/{reviewId}/administrators'),
    assessments: {
      ...getCRUDEndpoints('reviews/reviews/{reviewId}/assessments'),
      questionnaire: getGetEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/questionnaire'),
      'save-response': getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/save-response'),
      submit: getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/submit'),
      results: getGetEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/results'),
      'share-results': getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/share-results'),
      'change-job': getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/change-job'),
      cancel: getPostEndpoint('/reviews/reviews/{reviewId}/assessments/cancel'),
      acknowledge: getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/acknowledge'),
      'check-updates': getGetEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/check-updates')
    },
    'assessments-participants': {
      'all-participants': getGetEndpoint('reviews/reviews/{reviewId}/all-participants'),
      'view-questionnaire': getGetEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/view-questionnaire'),
      'resend-invitation': getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/resend-invitation'),
      'reopen-questionnaire': getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/reopen-questionnaire'),
      'add-manager': getPostEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants'),
      'remove-manager': getDeleteEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{id}'),
      'remove-participant': getDeleteEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{id}'),
      'preview-questionnaire': getGetEndpoint('/reviews/reviews/{reviewId}/assessments/{assessmentId}/participants/{participantId}/preview-questionnaire')
    },
    'notification-recipients': getCRUDEndpoints('reviews/reviews/{reviewId}/notification-recipients'),
    'result-accesses': getCRUDEndpoints('reviews/reviews/{reviewId}/result-accesses'),
    communications: getCRUDEndpoints('reviews/reviews/{review_id}/communications')
  },
  'my-Profile': {
    reviews: getGetEndpoint('profile/assessments'),
    capabilities: getGetEndpoint('profile/capabilities'),
    'capability-details': getGetEndpoint('profile/capability-details/{type}/{id}'),
    'capability-history': getGetEndpoint('profile/capability-history/{type}/{id}'),
    'job-match': getGetEndpoint('profile/job-match')
  }
};
