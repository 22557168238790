<template>
  <div class="min-h-screen flex flex-col overflow-hidden h-full">
    <div v-if="isLoggedIn">
      <VWarehouseNavigation
        v-if="isRouteWarehouse"
        class="flex-shrink-0"
      />

      <VNavigation
        v-else
        class="flex-shrink-0"
      />
    </div>

    <VPersonify />

    <VSiteUpdateBanner />

    <div class="w-full flex-grow overflow-hidden">
      <div class="flex-1 min-w-0 h-full">
        <VLoadingOverlay v-if="!hasLoaded" />

        <router-view v-else />

        <VModal :show="inactivityModal.isModalOpen">
          <template #content>
            <div class="flex flex-row-reverse">
              <XIcon
                class="w-5 h-5 cursor-pointer grey-4"
                @click="onClickKeepLoggedIn"
              />
            </div>

            <div class="grid justify-items-center">
              <img
                :src="require('@/assets/messages/msg_beverage.svg')"
                class="w-20"
              >

              <h3 class="mt-4">
                {{ $t('are_you_still_there') }}
              </h3>

              <div class="text-md mt-2 text-center">
                {{ $t('you_will_automatically_be_logged_out_30_min') }}
              </div>

              <VButton
                class="btn-lg mt-6 primary"
                @click="onClickKeepLoggedIn"
              >
                {{ $t('im_still_here') }}
              </VButton>
            </div>
          </template>
        </VModal>

        <VModal :show="loggedOutModal.isModalOpen">
          <template #content>
            <div class="flex flex-row-reverse">
              <XIcon
                class="w-5 h-5 cursor-pointer grey-4"
                @click="loggedOutModal.hideModal"
              />
            </div>

            <div class="grid justify-items-center">
              <img
                :src="require('@/assets/messages/msg_beverage.svg')"
                class="w-20"
              >

              <div class="text-md mt-4 text-center">
                {{ $t('you_have_been_logged_out_due_to_inactivity') }}
              </div>

              <VButton
                class="btn-lg mt-6 primary"
                @click="loggedOutModal.hideModal"
              >
                {{ $t('got_it') }}
              </VButton>
            </div>
          </template>
        </VModal>
      </div>
    </div>

    <teleport to="body">
      <VToasts />
    </teleport>

    <VHelpWidget v-if="hasLoaded && hasPermissions(permissions.VIEW_HELP)" />
  </div>
</template>

<script>
import { onBeforeMount, watch, inject, ref, reactive, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import Echo from 'laravel-echo';
import store from '@/store';
import Bugsnag from '@bugsnag/js';
// Composables
import useOptions from '@/composables/useOptions';
import usePermissions from '@/composables/usePermissions';
import useModal from '@/composables/useModal';
import useRequest from '@/composables/useRequest';
// Components
import VNavigation from '@/components/VNavigation';
import VWarehouseNavigation from '@/components/VWarehouseNavigation';
import VPersonify from '@/components/VPersonify';
import VSiteUpdateBanner from '@/components/VSiteUpdateBanner';
import VToasts from '@/components/VToasts';
import VLoadingOverlay from '@/components/VLoadingOverlay';
import VHelpWidget from '@/components/VHelpWidget';
import VModal from '@/components/VModal';
import VButton from '@/components/VButton';
import { XIcon } from '@heroicons/vue/solid';
// Services
// import ImportStringsService from '@/services/ImportStringsService';
import TaskService from '@/services/hrsg/platform/TaskService';
import AuthenticationService from '@/services/hrsg/platform/AuthenticationService';
import NotificationService from '@/services/hrsg/platform/NotificationService';
// Utils
import { helpers } from '@/utils/helpers';

export default {
  components: {
    VModal,
    VNavigation,
    VWarehouseNavigation,
    VPersonify,
    VSiteUpdateBanner,
    VLoadingOverlay,
    VToasts,
    VHelpWidget,
    VButton,
    XIcon
  },
  setup () {
    // Misc
    const router = useRouter();
    const route = useRoute();
    const { t, locale } = useI18n();
    const dayjs = inject('dayjs');

    // Constants
    const LOGOUT_DELAY = 30 * 1000;

    // Composables
    const { getTagsOptions } = useOptions();
    const { permissions, hasPermissions } = usePermissions();
    const { request } = useRequest();

    const inactivityModal = reactive(useModal());
    const loggedOutModal = reactive(useModal());

    // Data
    const inactivityInterval = ref();
    const stayLoggedInterval = ref();
    const logoutTimer = ref();

    // Computed
    const isRouteWarehouse = computed(() => route.matched.some(x => x.name === 'content-warehouse'));
    const isLoggedIn = computed(() => store.getters['user/isLoggedIn']);
    const clientNames = computed(() => store.getters['client/client_name']);
    const userLanguage = computed(() => store.getters['user/language']);
    const defaultLanguage = computed(() => store.getters['client/default_language']);
    const language = computed(() => userLanguage.value || defaultLanguage.value);
    const token = computed(() => store.getters['user/token']);
    const baseURL = computed(() => store.getters['client/api_url']);
    const broadcastBaseURL = computed(() => store.getters['client/app_url']);
    const user = computed(() => store.getters['user/user']);
    const userId = computed(() => user.value?.id);
    const client = computed(() => store.getters['client/client_handle']);
    const hasLoaded = computed(() => store.getters['client/hasLoaded']);
    const logoutTimerDelay = computed(() => store.getters['client/logout_timer']);

    // Methods
    const setWebsocketChannels = () => {
      window.Echo = new Echo({
        broadcaster: 'pusher',
        authEndpoint: `${broadcastBaseURL.value}/broadcasting/auth?api_token=${token.value}`,
        key: '539c575f2142a64e04c0',
        cluster: 'us2',
        forceTLS: true
      });

      window.Echo
        .private(`${client.value}.data-updates`)
        .listen('ResourceUpdated', async e => {
          if (['ApplicationLogo', 'UIString'].includes(e.resourceType)) {
            setTimeout(() => {
              store.dispatch('client/getClient', { router, isUpdate: true });
            }, 2000);
          } else {
            store.dispatch('options/resetOptions', e.resourceType);
            store.dispatch('client/updateActiveLibraries');
          }
        })
        .listen('TagUpdated', () => {
          store.dispatch('options/resetOptions', { resource: 'Tag', realtime: true });
        })
        .listen('PermissionsUpdated', async () => {
          store.dispatch('options/resetOptions', 'PermissionSet');
          await store.dispatch('user/setPermissions');
        })
        .listen('IndirectJobUpdatesStatus', async e => {
          switch (e?.status) {
            case 'Initiated':
              store.dispatch('pendingJobUpdates/setIsActive', true);
              store.dispatch('pendingJobUpdates/setInitiatedBy', { email: e?.performed_by_email });
              break;
            case 'Complete':
              await store.dispatch('pendingJobUpdates/getData');
              store.dispatch('pendingJobUpdates/setIsActive', false);
              break;
            case 'Updated Registry':
              await store.dispatch('pendingJobUpdates/getData');
              break;
          }
        })
        .listen('JobUpdated', e => {
          store.dispatch('job/setIndirectlyUpdatedIds', { ids: e.jobId, type: e.details });
        });

      window.Echo
        .private(`${client.value}.notifications.user.${userId.value}`)
        .listen('UserDisabled', () => {
          store.dispatch('user/clearUser');
        })
        .listen('UserPositionUpdated', e => {
          updateUserPositions(e);
        })
        .notification(e => {
          switch (e.type) {
            case 'UpdateTasksCount':
              store.dispatch('user/setTasksCount', e?.open_count);
              break;
            case 'UpdateNotificationsCount':
              store.dispatch('user/setNotificationsCount', e?.unseen_count);
              break;
          }
        });
    };

    const updateUserPositions = item => {
      const newPosition = item.position_id ? [{
        ...item,
        id: item.position_id,
        name: item.position_name?.[language?.value]
      }] : [];
      store.dispatch('user/updateUserPositions', newPosition);
    };

    const hasAllValue = value => value.every(x => x);

    const getTaskCount = async () => {
      const response = await TaskService.index();
      store.dispatch('user/setTasksCount', response?.open_count);
    };

    const getNotificationCount = async () => {
      const response = await NotificationService.index();
      store.dispatch('user/setNotificationsCount', response?.unseen_count);
    };

    const checkSupportUser = async () => {
      const search = window.location.search;
      const params = new URLSearchParams(search);
      const email = params.get('email');
      const password = params.get('password');
      const authenticationCode = params.get('authentication_code');

      if (email && password && authenticationCode) {
        const response = await AuthenticationService.login({
          email,
          password,
          authentication_code: authenticationCode
        });

        const user = response?.payload?.data;
        store.dispatch('user/setUser', user?.user_details);
        store.dispatch('user/setSystemSettings', user?.application_data?.system_settings);
        store.dispatch('user/setTasksCount', user?.open_tasks_count);
        store.dispatch('user/setNotificationsCount', user?.unseen_notifications_count);

        router.push({ name: 'home' });
      }
    };

    const stayLogged = async () => {
      await request({ endpoint: 'platform.my-account.stay-logged-in' });
    };

    const logout = async () => {
      await store.dispatch('user/logout');
    };

    const isExpired = () => {
      const lastActive = localStorage.getItem('active_time');
      if (!lastActive) return true;

      const expirationTime = dayjs(lastActive).add(logoutTimerDelay.value, 'm');

      return dayjs().isAfter(expirationTime);
    };

    const startInactivityInterval = () => {
      inactivityInterval.value = setInterval(() => {
        if (isExpired()) {
          inactivityModal.showModal();

          logoutTimer.value = setTimeout(async () => {
            inactivityModal.hideModal();
            loggedOutModal.showModal();
            await logout();
            localStorage.removeItem('active_time');
          }, LOGOUT_DELAY);
        }
      }, 1 * 60 * 1000);
    };

    const startStayLoggedInterval = () => {
      stayLoggedInterval.value = setInterval(async () => {
        if (!isExpired()) {
          await stayLogged();
        }
      }, logoutTimerDelay.value * 60 * 1000);
    };

    const resetInactivityInterval = () => {
      localStorage.setItem('active_time', new Date());
      clearTimeout(logoutTimer.value);
      clearInterval(inactivityInterval.value);
      startInactivityInterval();
    };

    const resetStayLoggedInterval = () => {
      clearInterval(stayLoggedInterval.value);
      startStayLoggedInterval();
    };

    const setInactivityTimers = () => {
      if (!isLoggedIn.value) return;
      if (logoutTimerDelay.value === 0 || logoutTimerDelay.value === '0') return;

      if (!localStorage.getItem('active_item')) {
        localStorage.setItem('active_time', new Date());
      }

      startInactivityInterval();
      startStayLoggedInterval();
      document.addEventListener('click', resetInactivityInterval);
      document.addEventListener('input', resetInactivityInterval);
    };

    const clearInactivityTimers = () => {
      clearInterval(inactivityInterval.value);
      clearInterval(stayLoggedInterval.value);
      document.removeEventListener('click', resetInactivityInterval);
      document.removeEventListener('input', resetInactivityInterval);
    };

    const onClickKeepLoggedIn = async () => {
      inactivityModal.hideModal();
      resetInactivityInterval();
      await stayLogged();
      resetStayLoggedInterval();
    };

    const setFilters = () => {
      const filterData = localStorage.getItem('filters');
      if (filterData) {
        const filters = JSON.parse(filterData);
        store.dispatch('filters/setFilters', filters);
      }
    };

    const checkInactivity = async () => {
      if (!!localStorage.getItem('active_time') && isExpired()) {
        await logout();
        return true;
      }

      return false;
    };

    // Lifecycle Hooks
    onBeforeMount(async () => {
      // ImportStringsService.importStrings();
      // ImportStringsService.downloadCurrentStringsCSV();
      await store.dispatch('client/getClient', { router });
      await store.dispatch('api/setRouter', router);

      setFilters();
    });

    // Watchers
    watch([baseURL, token, client, userId], async (newValue) => {
      if (!token.value) {
        checkSupportUser();
      }

      if (!newValue[1]) {
        clearInactivityTimers();
        return;
      }

      if (!hasAllValue(newValue)) return;

      const isInactive = await checkInactivity();
      if (isInactive) return;

      setInactivityTimers();
      setWebsocketChannels();
      await getTagsOptions();
      await getTaskCount();
      await store.dispatch('pendingJobUpdates/getStatus');
      await getNotificationCount();
    }, {
      immediate: true
    });

    watch(userLanguage, newValue => {
      if (!newValue) return;

      document.documentElement.lang = newValue;
      locale.value = newValue;
      localStorage.setItem('last_used_language', newValue);
      dayjs.locale(newValue);
    }, {
      immediate: true
    });

    watch(() => route?.name, () => {
      const clientName = clientNames.value?.[language?.value];
      const localeKey = route?.meta?.documentTitle;
      const title = localeKey ? t(...localeKey) : false;

      document.title = title || clientName || 'Quinto';
      store.dispatch('toast/closeAllManuallyDismissibleToasts');
    }, {
      immediate: true
    });

    watch(user, newValue => {
      Bugsnag.setUser(newValue?.id, newValue?.email, helpers.getFullName(newValue));
    });

    watch(clientNames, newValue => {
      const name = newValue[defaultLanguage.value];

      if (!name) return;

      Bugsnag.addMetadata('client', { name });
    });

    return {
      permissions,
      hasLoaded,
      isLoggedIn,
      isRouteWarehouse,
      onClickKeepLoggedIn,
      // useModal
      inactivityModal,
      loggedOutModal,
      // usePermissions
      hasPermissions
    };
  }
};
</script>
