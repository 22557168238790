import router from '@/router/index.js';
export default {
  // called when the bound element's parent component
  // and all its children are mounted.
  mounted (el, binding, vnode, prevVnode) {
    el.addEventListener('click', function () {
      if (binding.value) {
        if (router.hasRoute(binding.value) || router.hasRoute(binding.value.name)) {
          const gotoRoute = (typeof binding.value === 'string') ? { name: binding.value } : binding.value;
          router.push(gotoRoute);
        }
      }
    });
  }
};
