import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
const timezone = require('dayjs/plugin/timezone');
const advancedFormat = require('dayjs/plugin/advancedFormat');
const minMax = require('dayjs/plugin/minMax');
require('dayjs/locale/fr');

const install = app => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(advancedFormat);
  dayjs.extend(minMax);
  app.config.globalProperties.$dayjs = dayjs;
  app.provide('dayjs', dayjs);
};

export { install as default, dayjs };
