<template>
  <div class="border-b py-4 pl-1 pr-3 group/item hover:bg-light-grey-2 flex">
    <slot name="avatar">
      <VAvatar
        v-if="fromUser"
        :user="fromUser"
        size="medium"
        class="flex-none mr-3 mt-0.75"
      />
      <div
        v-else
        class="w-8 mr-3 shrink-0"
      />
    </slot>

    <div class="grid grid-cols-12 basis-full">
      <div class="col-span-9 justify-start text-sm text-grey-1 font-semibold">
        <slot name="header" />
      </div>

      <div class="col-span-3 flex justify-end items-center">
        <span class="mr-1 !text-xs !text-grey-1">
          {{ item?.date }}
        </span>

        <VTooltip
          :tooltip="isRead ? $t('mark_as_unread') :$t('mark_as_read')"
          fixed
          responsive
          direction="ese"
          @click="$emit('toggle-read',item)"
        >
          <div class="rounded-full cursor-pointer w-[18px] h-[18px] p-1 border border-transparent group-hover/item:border-grey-5">
            <VStatus
              v-if="!isRead"
              class="!mr-0 !mt-0 !w-2 !h-2"
              status="Suspended"
            />

            <VStatus
              v-else
              class="!mr-0 !mt-0 !w-2 !h-2 invisible group-hover/item:visible"
              status="Archived"
            />
          </div>
        </VTooltip>
      </div>

      <div class="col-span-11 max-w-[22rem]">
        <template v-if="$slots.type">
          <span
            class="text-link text-sm"
            :class="[
              { 'cursor-pointer': isJobTitleClickable },
              typeClass,
            ]"
            @click="onClickJob"
          >
            <slot name="type" />
          </span>
        </template>

        <template v-if="$slots.content">
          <div class="flex flex-nowrap gap-3 pt-3">
            <slot name="content" />
          </div>
        </template>

        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
// Components
import VStatus from '@/components/VStatus';
import VTooltip from '@/components/VTooltip';
// Constants
import ALL_OPTIONS from '@/constants/options';
import VAvatar from '@/components/VAvatar.vue';

export default {
  components: {
    VAvatar,
    VStatus,
    VTooltip
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    name: {
      type: String,
      default: 'content-jobs-id-description'
    },
    typeClass: {
      type: [String, Array, Object],
      default: ''
    }
  },
  emits: ['close', 'toggle-read'],
  setup (props, context) {
    // Misc
    const router = useRouter();

    // Computed
    const isRead = computed(() => {
      return props?.item?.read_at !== null;
    });

    const isJobTitleClickable = computed(() => {
      if (props?.item?.job_status === ALL_OPTIONS.DEPRECATED.value) {
        return false;
      }

      return true;
    });

    const fromUser = computed(() => {
      const fields = ['publisherName', 'commentByName', 'actionTakenBy', 'contributorName', 'deletedByName', 'updatedByName', 'archivedByName'];
      let setAvatar = null;

      for (const field of fields) {
        const fieldData = props.item?.data?.[field];
        if (fieldData) {
          const avatar = props.item?.data?.[field.replace('Name', 'Avatar')];
          if (avatar) {
            setAvatar = { avatar: { view_path: avatar } };
            break;
          }

          const user = props.item?.data?.[field.replace('Name', 'User')];
          if (user) {
            setAvatar = user;
            break;
          }

          const names = fieldData.split(' ');
          setAvatar = { firstname: names[0], lastname: names[1] };
          break;
        }
      }

      return setAvatar;
    });

    // Methods
    const onClickJob = () => {
      if (!isJobTitleClickable.value) return;

      const jobId = props.item?.data?.jobId;

      if (jobId) {
        router.push({
          name: props.name,
          params: {
            id: props.item?.data?.jobId
          }
        });
        context.emit('close');
      }
    };

    return {
      isRead,
      isJobTitleClickable,
      fromUser,
      onClickJob
    };
  }
};
</script>
