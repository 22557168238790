export default function error ({ next, store, nextVue }) {
  const isLoggedIn = store.getters['user/isLoggedIn'];

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const from = params.get('from');
  const pathname = from ?? window.location.pathname;
  const path = ['/login', '/reset-password'].includes(pathname) ? undefined : pathname + search;

  if (!isLoggedIn) {
    return nextVue({ name: 'login', query: { from: path } });
  }

  return next();
}
