<template>
  <div>
    <VNotification
      :item="notification"
      @toggle-read="$emit('toggle-read',notification)"
      @close="$emit('close')"
    >
      <template #header>
        <span>
          {{ $t('job_descriptions_import_complete') }}
        </span>
      </template>
      <template #type>
        <i18n-t
          keypath="job_descriptions_import_notification_msg"
          tag="p"
          class="!text-grey-1"
          scope="global"
        >
          <template #link>
            <span
              class="text-link cursor-pointer"
              @click="onClick"
            >
              {{ $t('click_here') }}
            </span>
          </template>
        </i18n-t>
      </template>
    </VNotification>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
// Components
import VNotification from '@/components/templates/notifications/VNotification';

export default {
  components: {
    VNotification
  },
  props: {
    notification: {
      type: Object,
      default: () => ({})
    }
  },
  emits: ['toggle-read', 'close'],
  setup (props) {
    // Misc
    const router = useRouter();

    const onClick = () => {
      router.push({ name: 'content-jobs', query: { 'filter-job_group_id': props.notification.data?.job_group_id } });
    };

    return {
      onClick
    };
  }

};
</script>
